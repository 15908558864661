.question-action {
    position: absolute;
    bottom: -12px;
    left: 0;
    width: 100%;
    height: 12px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #ebeff6;
    display: none;
    justify-content: center;
    .action-icon {
        position: absolute;
        top: -10px;
        cursor: pointer;
        .add-ques-icon {
            &:hover {
                svg {
                    g {
                        path {
                            fill: #606061;
                        }
                    }
                }
            }
        }
    }
}
