.orientation-section {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid var(--gray-200, #E5E7EB);
    gap: 4px;
    /* Drop Shadow/sm */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    background: var(--gray-50, #F9FAFB);


    .title {
        display: flex;
        padding: 8px 8px 8px 12px;
        align-items: center;
        justify-content: space-between;
        gap: 8px;

        background: var(--white-white, #FFF);
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        font-size: 14px;
        font-weight: 500;
        color: #1F2937;
    }

    .img-group {
        display: flex;
        justify-content: center;
        margin-top: 12px;
        margin-bottom: 12px;

        .group {
            display: flex;
            align-items: center;
            flex-direction: column;
            cursor: pointer;

            .img-btn {
                display: flex;
                margin-left: 15px;
                margin-right: 15px;
                transition: border 0.3s, box-shadow 0.3s;
                border-radius: 8px;
                border: 1px solid rgba(33, 33, 33, 0.32);

                overflow: hidden;
            }

            .title-item {
                margin-top: 8px;
                // opacity: 0.3;
                // transition: opacity 0.3s;
                color: #1F2937;
                font-family: Nunito;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            &:hover {
                .img-btn {
                    border: 1px solid rgba(var(--color), 1);
                    box-shadow: rgba(59, 130, 246, 0.12) 0px 0px 0px 3px;
                }

                // .title {
                //     opacity: 0.5;
                // }
            }

            &.active {
                .img-btn {
                    border: 1px solid rgba(var(--color), 1);
                }

                // .title {
                //     opacity: 1;
                // }
            }
        }
    }
}