.equation {
    display: flex;
    background: #f9fafb;
    gap: 8px;
    border-radius: 6px;

    .entry-feature {
        position: relative;

        .entry-header {
            padding: 4px 8px;
            background: var(--gray-50, #f9fafb);
            border-radius: 6px;
            border: 1px solid #e5e7eb;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
            display: flex;
            gap: 4px;

            .current-choice {
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                background: #ffffff;
                border-radius: 4px;
                padding: 4px 6px;

                .shape {
                    display: flex;
                    align-items: center;
                    padding: 3px;
                }

                &.show-all {
                    border: 1px solid rgba(var(--color), 1);
                    box-shadow: rgba(59, 130, 246, 0.12) 0px 0px 0px 3px;
                }

                // &:hover {
                //     border: 1px solid rgba(var(--color), 1);
                //     // box-shadow: rgba(59, 130, 246, 0.12) 0px 0px 0px 3px;
                // }
            }
        }

        .drop-down {
            // background: linear-gradient(0deg, #ffffff, #ffffff),
            //     rgba(33, 33, 33, 0.04);
            // box-shadow: 0px 2px 4px rgba(33, 33, 33, 0.2);
            width: 100%;
            max-height: 315px;
            // padding-top: 8px;
            // padding: 8px 16px 8px 8px;

            .show-list {
                padding: 8px;
                display: flex;
                flex-wrap: wrap;

                .option {
                    cursor: pointer;
                    display: flex;
                    align-items: center;

                    &:hover {
                        background: rgba(33, 33, 33, 0.04);
                    }

                    .ncw_use_equation_greek_letters {
                        height: 24px;
                        width: 24px;
                    }

                    .ncw_use_equation_relations,
                    .ncw_use_equation_miscellaneous_operations,
                    .ncw_use_equation_arrows {
                        height: 16px;
                        width: 16px;
                    }

                    .ncw_use_equation_math_operations {
                        height: 48px;
                        width: 16px;
                    }
                }
            }

            &::-webkit-scrollbar-thumb {
                border-right: 2px;
                border-left: 2px;
                border-radius: 10px;
                background: rgba(33, 33, 33, 0.24);
            }

            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: rgba(33, 33, 33, 0.5);
            }
        }
    }
}
