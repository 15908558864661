.font-size-selector {
    display: flex;
    align-items: center;
    margin-left: 12px;
    margin-right: 10px;

    button {
        padding: 0 5px;
    }
}

.container-mui-auto-select {
    height: 24px;
    width: 30px;

    .font-picker {
        height: 100%;
        display: flex;
        align-items: center;
        background-color: #fff;
        border-radius: 4px;

        & > div {
            text-align: center;
            justify-content: center;
        }
    }
}

.font-size-picker-container {
    display: flex;
    align-items: center;
    border-radius: 4px 0px 0px 4px;
    height: 24px;
    margin-left: 10px;
    background: #ffffff;
    position: relative;
    .input-font-size {
        width: 40px;
        letter-spacing: 0.1px;
        background-color: transparent;
        font-size: 12px;
        line-height: 16px;
        border-color: transparent !important;
        &:focus-visible {
            outline: -webkit-focus-ring-color auto 0px;
        }
    }

    .font-size-item {
        cursor: pointer;
        &:hover {
            background: rgba(255, 255, 255, 0.24);
        }
    }
}

.button-change-font-size {
    cursor: pointer;
    display: flex;
    // padding: 8px 6px;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    background: rgba(255, 255, 255, 0.24);
    border-radius: 0px 4px 4px 0px;
    border: 1px solid transparent;
    margin-left: 1px;
    margin-top: 0.5px;
    margin-right: 4px;

    &:hover {
        background: rgba(255, 255, 255, 0.12);
        border: 1px solid rgba(255, 255, 255, 0.12);
        border-radius: 0px 2px 2px 0px;
    }
}
// .font-size-mui-auto-select {}
// .MuiSvgIcon-root {
//     background: rgba(255, 255, 255, 0.24);
//     &:hover {
//         background: rgba(255, 255, 255, 0.12);
//         border: 1px solid rgba(255, 255, 255, 0.12);
//         border-radius: 0px 2px 2px 0px;
//     }
// }
.list-item-font-size {
    background-color: rgba(33, 33, 33, 0.8);
    color: #fff;
}
