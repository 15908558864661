.more-action-tool {
    position: absolute;
    top: 4px;
    right: 4px;
    .three-dots {
        width: 24px;
        height: 24px;
        // img {
        //     width: 100%;
        //     height: 100%;
        // }
    }
}
