.loading-component-circular-progress {
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    .circular-progress {
        animation: animationCircleProgress 1.4s linear infinite;

        .circular-progress-circle {
            stroke-dasharray: 80px, 200px;
            stroke-dashoffset: 0;
            -webkit-animation: circularProgressCircle 1.4s ease-in-out infinite;
            animation: circularProgressCircle 1.4s ease-in-out infinite;
        }
    }

    @keyframes animationCircleProgress {
        0% {
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes circularProgressCircle {
        0% {
            stroke-dasharray: 1px, 200px;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 100px, 200px;
            stroke-dashoffset: -15px;
        }
        100% {
            stroke-dasharray: 100px, 200px;
            stroke-dashoffset: -125px;
        }
    }
}
