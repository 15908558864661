.cluster-checkbox-button {
    padding-top: 8px;

    .group-checkbox {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .item-checkbox {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: var(--white-white, #FFF);
            padding: 8px 8px 8px 12px;
            border: 1px solid #E5E7EB;

            border-radius: 6px;



            .MuiButtonBase-root,
            .MuiCheckbox-root {
                padding: 4px;
            }

            .title {
                color: var(--gray-800, #1F2937);
                font-family: Nunito;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }

    .button-scramble-crossword {
        margin-top: 24px;
        padding: 10px 12px;
        border-radius: 6px;
        background: rgba(var(--color), 1);
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
        font-family: Nunito;
        font-size: 15px;
        font-weight: 600;
        text-transform: none;
        cursor: pointer;
    }
}