.zones-popup {
    .modal-dialog {
        width: 800px;
        max-width: calc(100% - 40px) !important;
        .zones-popup-add-btn {
            margin-left: 20px;
        }
        .zones-popup-action {
            display: flex;
            align-items: center;
            button:first-child {
                margin-right: 8px;
            }
        }
    }
    &.hide {
        .modal-dialog {
            display: none;
        }
    }
}