.notification-save-section {
    position: absolute;
    color: #212121;
    font-weight: 400;

    &.hidden {
        display: none;
    }
    a {
        color: rgba(var(--color), 1);
        font-weight: 400;
        text-decoration-line: underline;
    }
}
