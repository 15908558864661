.breadcrumbs-collection-name {
    padding: 6px 10px;
    &.active {
        // border: 1px solid #e5e7eb;
        // border-left: 0px;
        // border-right: 0px;
    }
    &.resource {
        // margin-left: 32px;
        @media screen and (max-width: 768px) {
            margin: 0;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            text-align: center;
        }
        .collection-name {
            p {
                font-weight: 700;
                font-size: 30px;
                line-height: 48px;
                letter-spacing: 0.02em;
                color: #212121;
                margin: 0;
                cursor: pointer;

                @media screen and (max-width: 768px) {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 27px;
                }
            }
        }
    }
    &.detail-collection {
        margin-left: 32px;
        display: flex;
        align-items: center;
        gap: 8px;

        @media screen and (max-width: 768px) {
            gap: 8px;
        }
        .collection-name {
            max-width: 200px;
            p {
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* number of lines to show */
                line-clamp: 1;
                -webkit-box-orient: vertical;
                word-break: break-all;
                cursor: pointer;
                color: #4b5563;
            }
            &.active {
                p {
                    font-weight: 700;
                    font-size: 16px;
                }
            }
        }
    }
}
