.paste-input {
    height: 260px;
    .image-example {
        padding: 8px 8px 4px 8px;

        img {
            width: 100%;
        }
    }

    .input-excel {
        padding: 0px 8px 8px 8px;

        .multi-text {
            width: 100%;
            height: 100px;
            

            .MuiInputBase-root {
                padding: 12px 4px 12px 12px;
            }

            .MuiInputBase-input, .MuiInputBase-input::placeholder {
                color: rgba(33, 33, 33, 0.64);
                font-family: Nunito;
                font-size: 12px;
                font-weight: 400;
                line-height: normal;
            }
            
            .MuiInputBase-input::placeholder{
                text-transform: capitalize;
            }

            .MuiInputBase-input {
                overflow: hidden;
                padding-right: 6px;

                /* width */
                &::-webkit-scrollbar {
                    width: 4px;
                }
                /* Track */
                &::-webkit-scrollbar-track {
                    background: #FAFAFA;
                }
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    border-radius: 16px;
                    background: #D9D9D9;
                }
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #D9D9D9;
                }

                &:hover {
                    overflow-y: scroll;
                }
            }
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border: 1px solid rgba(33, 33, 33, 0.12);
        }

        .multi-text {
            &:hover fieldset {
                border: 1px solid rgba(33, 33, 33, 0.12);
            }
        }
    }
}