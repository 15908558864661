.validator-modal-choose-validate-type-wrapper {
    display: flex;
    align-items: flex-end;

    & > div {
        flex: 1;
    }

    & > button {
        width: 50px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
    }
}

.validator-modal-list-validator-title {
    font-weight: bold;
    font-size: 18px;
    margin: 20px 0 10px;
}

.validator-modal-validator-col-title {
    font-weight: bold;
}