.resource-word-scramble {
    display: grid;
    align-items: center;
    gap: 6px;
    height: 100%;

    .prefix {
        padding-right: 10px;
    }

    .input-container {
        height: calc(100% - 8px);
        position: relative;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        
        &::after {
            z-index: 0;
            content: "";
            display: var(--after-display) !important;
            position: absolute;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            border: 1px dashed #b3b3b3;
        }
        .input {
            position: relative;
            z-index: 1;
            width: 100%;
            font-size: inherit;
            outline: none;
            border: none;
            background-color: transparent;
        }
    }

    .shape-input {
        height: calc(100% - 8px);
        display: flex;
        .input {
            text-align: center;
            vertical-align: middle;
            width: calc(var(--height-item) - 10px);
            height: calc(var(--height-item) - 10px);
            font-size: inherit;
            outline: none;
            border: 2px solid gray;
            caret-color: transparent;
            cursor: pointer;

            &:not(&.last) {
                border-right: none;
            }

            &:hover {
                background-color: rgb(255, 213, 77, 0.8);
            }
            
            &:focus {
                background-color: #ffd54d;
            }
        }
    }
}