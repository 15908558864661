.data-grid-collection {
    max-width: 100%;
    margin-bottom: 24px;
    cursor: pointer;
    position: relative;
    &:hover {
    }
    .data-worksheet {
        .practiced-score {
            color: #ff2442;
            position: absolute;
            font-style: italic;
            top: 0;
            right: 0;
            padding: 6px;
            background: white;
            border-bottom-left-radius: 16px;
            box-shadow: 0 0 14px 10px white;
        }

        .practiced-score.played-ws {
            display: block;
        }
        .slider-ws {
            display: flex;
            transition: transform 0.5s ease-in-out;
            > span {
                min-width: 100%;
            }
        }
        .number-of-image {
            padding: 2px 6px;
            position: absolute;
            right: 8px;
            bottom: 10px;
            z-index: 3;
            border-radius: 40px;
            background: rgba(33, 33, 33, 0.52);
            backdrop-filter: blur(3px);

            visibility: hidden;
            justify-content: center;
            align-items: center;
            color: #fff;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            @media screen and (max-width: 1024px) {
                visibility: visible;
            }
        }
        &:hover {
            .number-of-image {
                visibility: visible;
            }
        }
    }
    .data-grid-item {
        box-sizing: border-box;
        // border-radius: 12px;
        overflow: hidden !important;
        &.data-worksheet {
            position: relative;
            // box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.182);
            overflow: hidden;
            // border-radius: 12px;
            overflow: visible;
            transition: all 0.2s ease-in-out;
            .image {
                // width: 100% !important;
                height: 100% !important;
                position: relative !important;
                object-fit: contain !important;
                // border-radius: 12px;
            }
        }
        .tick-icon {
            display: none;
            position: absolute;
            right: 8px;
            top: 8px;
            width: 60px;
            height: 60px;
            @media screen and (max-width: 1024px) {
                display: block;
                width: 60px;
                height: 60px;
                display: flex !important;
                justify-content: flex-end;
            }
        }
    }
    .layer {
        position: absolute;
        border-radius: 12px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: gray;
        opacity: 0.35;
        z-index: 1;
    }
    .ticked {
        background-color: #c4c4c4;
        a {
            div {
                // width: 100% !important;
                // height: 100% !important;
                .image {
                    transition: all 0.1s ease;
                    scale: 0.9;
                }
            }
        }
        .tick-icon {
            display: flex !important;
            justify-content: flex-end;
        }
    }
    .data-grid-item:hover {
        .tick-icon {
            display: flex !important;
            justify-content: flex-end;
            position: absolute;
            right: 8px;
            top: 8px;
            cursor: pointer;
            z-index: 2;
        }
    }
    .title {
        text-align: start;
        padding-top: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.04em;
        color: #212121;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &.draft {
        .point-event-item {
            position: unset !important;
            > div {
                padding: 8px;
                text-align: start;
                text-decoration: none !important;
                position: unset !important;
            }
        }
    }
    .point-event-item {
        position: absolute !important;
        top: 8px;
        right: 8px;
        > div {
            padding: 8px;
            text-align: start;
            text-decoration: none !important;
            position: unset !important;
        }
        .three-dots {
            height: 24px;
            position: absolute;
            bottom: 4px;
            right: 4px;
            filter: drop-shadow(0px 4px 4px rgba(33, 33, 33, 0.16));
        }
    }
    .point-event-item-worksheet {
        position: absolute !important;
        bottom: 6px;
        right: 6px;
        z-index: 2;
    }
    .popup-action-collection {
        background-color: #fff;
        position: absolute;
        padding: 12px 16px;
        display: grid;
        gap: 12px;
        width: fit-content;
        z-index: 9;
        border: 1px solid #d4d7ea;
        border-radius: 20px;
        text-align: start;
        p {
            margin: 0;
        }
        .item-action {
            cursor: pointer;
            display: flex;
            align-items: center;
            span {
                font-size: 14px;
                line-height: 19px;
                color: #212121;
                padding-left: 8px;
            }
        }
        .split {
            background: #cdd2eb;
            border-radius: 1px;
            width: 160px;
            height: 1px;
        }
    }
}
