.right-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-top: 24px;
    // margin: 0 auto;
    .loading-container {
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
        position: absolute;
        z-index: 4;
        width: 100%;
        top: 0px;
        left: 0px;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}
