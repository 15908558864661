.reset-puzzle {
    cursor: pointer;
    width: 32px;
    height: 32px;
    .reset-puzzle-light {
        width: 100%;
        height: 100%;
        transition: all ease-in-out 0.3s;
        background-position: center;
        background-repeat: no-repeat;
    }
    @media screen and (max-width: 1024px) {
        border: 1px solid rgba(33, 33, 33, 0.12);
        border-radius: 4px;
    }
}
.popup-confirm-reset-puzzle {
    .MuiPaper-root {
        border-radius: 20px;
        text-align: center;
        padding: 16px;

        @media screen and (max-width: 1024px) {
            border-radius: 20px 20px 0 0;
            padding: 0;
        }

        .MuiDialogContent-root {
            color: rgba(33, 33, 33, 0.52);
            text-align: center;
            font-size: 14px;
            line-height: 18px;
            margin: auto;
            padding: 24px 40px 0 40px;
        }
        .MuiDialogActions-root {
            justify-content: center;
            padding: 0;
            padding-top: 24px;
            gap: 8px;
        }
    }
    &__title {
        margin: 0 !important;
        padding: 0 !important;
        padding-top: 16px !important;
        font-size: 24px !important;
        line-height: 28px !important;
    }
    p {
        margin: 0 !important;
        width: 100%;
    }
    .btn-action {
        display: flex;
        justify-content: center;
        padding: 12px 0;
        border-radius: 12px;
        border: 1px solid var(--color-palette-neutral-10, #e7e7e8);
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.32px;
        cursor: pointer;
        &.reset {
            border-radius: 12px;
            color: #fff;
            background: #ff2442;
        }
        &.oke {
            background: rgba(59, 130, 246, 1);
            color: #ffffff;
        }
    }
}
