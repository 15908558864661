.edit-spacing-container {
    .icon {
        display: flex;
        align-items: center;
        border-radius: 4px;
        &.active{
            background-color: rgba($color: #212121, $alpha: 0.1);
        }
        &:hover {
            background-color: rgba($color: #212121, $alpha: 0.1);
        }
    }
}