.back-popup-container {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1000;
    .custom-popup-container {
        position: absolute;
        width: fit-content;
        height: fit-content;
        border-radius: 6px;
        border: 1px solid rgba(33, 33, 33, 0.12);
        box-shadow: 0px 2px 1px 1px rgba(33, 33, 33, 0.08);
        overflow: hidden;
        z-index: 101;
        transition: all 0.2s ease-in;
        pointer-events: all;
    }
}