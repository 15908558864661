.range-input {
    color: #212121;
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 8px;
    border-radius: 4px;
    background: rgba(33, 33, 33, 0.04);

    border: none;
    outline: none;
    cursor: pointer;
}