.word-search-size {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid #E5E7EB;
    /* Drop Shadow/sm */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);


    &>* {
        font-family: "Poppins";
    }

    .title {

        color: #1F2937;
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 8px;
        background: #ffffff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .rectangle {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .resize {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;

            .input {
                display: flex;
                background: #ffffff;
                border: 0.5px solid rgba(33, 33, 33, 0.4);
                border-radius: 6px;

                input {
                    border-right: 0.5px solid rgba(33, 33, 33, 0.4);
                    border-left: 0.5px solid rgba(33, 33, 33, 0.4);
                    border-top: 0;
                    border-bottom: 0;
                    text-align: center;
                    max-width: 38px;
                }

                input:focus {
                    border: 1px solid rgba(var(--color), 1);
                }

                .icon {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    padding: 10px 15px 10px 15px;
                }
            }
        }
    }

    .other-shape {
        display: flex;
        justify-content: center;
        gap: 12px;
        padding: 6px 18px 6px 8px;
        background: #fafafa;
        border-radius: 8px;

        .checkbox-size {
            display: flex;
            align-items: center;

            .name {
                color: #1F2937;
                font-family: Nunito;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;

            }
        }
    }
}