.popup-action-project {
    position: absolute;
    top: 24px;
    right: 8px;
    box-shadow: 0px 4px 10px 0px #2121211f;
    background: #ffffff;
    padding: 8px;
    border-radius: 8px;
    z-index: 1;
    .popup-action-project-item {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 4px 8px;
        &:hover {
            background: #2121210a;
        }
        .name {
            font-size: 12px;
            font-weight: 400;
            line-height: 26px;
            width: 44px;
            color: #212121;
        }
    }
}
