.default-text-container {
    .title-functional {
        margin-top: 8px;
        margin-bottom: 8px;
        text-align: start;
        margin-left: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: #212121;
    }
}
