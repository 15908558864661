.dialog-form-login {
    .header {
        text-align: center;
        .logo {
            .logo-image {
                width: 56px;
                height: 40px;

                @media screen and (max-width: 768px) {
                    width: 37px;
                    height: 27px;
                }
            }
            .icon-close {
                position: absolute;
                top: 24px;
                right: 24px;
                cursor: pointer;
            }
        }
    }

    .MuiDialogContent-root {
        overflow-y: unset;
        max-height: 600px;

        //        @media screen and (max-width: 768px) {
        @media screen and (max-width: 1024px) {
            padding: 0;
        }
    }
    .MuiPaper-root {
        padding: 24px 80px;
        max-width: 1200px;
        width: 100%;
        border-radius: 0;
        @media screen and (min-width: 1025px) {
            height: calc(100vh - 48px);
        }
        margin: 0;
        display: flex;
        justify-content: center;
    }
    .title-dialog {
        text-align: center;
        font-weight: 600;
        font-size: 18px;
        color: #212121;
        padding: 0;
        margin: 0;
        padding-top: 40px;
        //        @media screen and (max-width: 768px) {
        @media screen and (max-width: 1024px) {
            padding-top: 20px;
            font-size: 16px;
            position: relative;

            &:after {
                position: absolute;
                content: "";
                height: 2px;
                /* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
                bottom: -12px;

                /****** 
          optional values below 
          ******/

                /* center - (optional) use with adjusting width   */
                margin: 0 auto;
                left: 0;
                right: 0;
                width: 100%;
                background: rgba(33, 33, 33, 0.52);

                /* optional animation */
                -o-transition: 0.5s;
                -ms-transition: 0.5s;
                -moz-transition: 0.5s;
                -webkit-transition: 0.5s;
                transition: 0.5s;
            }
        }
    }

    .form-login-content {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        padding-top: 40px;
        gap: 40px;
        // .title-bold {
        //     font-size: 20px;
        // }

        //        @media screen and (max-width: 768px) {
        @media screen and (max-width: 1024px) {
            flex-direction: column;
            padding-top: 32px;
            gap: 0;
        }
        .content-step {
            padding: 40px 56px;
            background: #ffffff;
            box-shadow: 8px 8px 24px rgba(2, 2, 70, 0.05);
            border-radius: 31.2294px;
            min-width: 450px;
            width: calc(100% - 35% - 80px);
            max-height: 444px;

            //        @media screen and (max-width: 768px) {
            @media screen and (max-width: 1024px) {
                padding: 0;
                width: calc(100%);
                min-width: unset;
                box-shadow: none;
            }
            .step-title {
                justify-content: center;
                font-weight: 400;
                font-size: 18px;
                color: #101828;
                display: flex;
                gap: 4px;
                //        @media screen and (max-width: 768px) {
                @media screen and (max-width: 1024px) {
                    font-size: 14px;
                    justify-content: flex-start;
                }
                &.next-step-title {
                    justify-content: flex-start;
                }
            }

            .auth {
                // padding-top: 16px;
                width: 400px;
                margin: auto;
                //        @media screen and (max-width: 768px) {
                @media screen and (max-width: 1024px) {
                    padding: 0;
                    width: 100%;
                }
                .auth-fragment {
                    padding: 16px 40px;
                    //        @media screen and (max-width: 768px) {
                    @media screen and (max-width: 1024px) {
                        padding: 12px 0 12px 0;
                    }
                    .title {
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 140%;
                        color: #515151;
                        text-align: center;
                    }

                    #loginWithGoogle {
                        width: 100%;
                        margin-left: auto;
                        margin-right: auto;
                        display: block;
                        > div {
                            padding-top: 24px;
                            width: 100%;
                            margin: auto;
                            iframe {
                                //        @media screen and (max-width: 768px) {
                                @media screen and (max-width: 1024px) {
                                    padding: 0;
                                    width: fit-content !important;
                                    margin: auto !important;
                                }
                                // width: 100% !important;
                                // margin: auto !important;
                            }
                        }
                    }

                    .text-field {
                        width: 100%;
                        display: flex;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 72px;
                    }
                    .or-letter {
                        margin-top: 40px;
                        margin-left: auto;
                        margin-right: auto;
                        text-align: center;
                        position: relative;
                        span {
                            padding: 10px;
                            background: #fff;
                            position: absolute;
                            // top: -50%;
                            z-index: 2;
                            transform: translate3d(-50%, -50%, 0);
                        }
                        &::after {
                            position: absolute;
                            content: "";
                            height: 1px;
                            bottom: 50%;
                            margin: 0 auto;
                            left: 0;
                            right: 0;
                            width: 100%;
                            background: #d0d0d0;
                            -o-transition: 0.5s;
                            -ms-transition: 0.5s;
                            -moz-transition: 0.5s;
                            -webkit-transition: 0.5s;
                            transition: 0.5s;
                        }
                    }

                    .submit-email {
                        text-transform: none;
                        margin-left: auto;
                        margin-right: auto;
                        display: flex;
                        margin-top: 24px;
                        background: #d0d0d0;
                        border-radius: 8px;
                        width: 100%;
                        box-shadow: none !important;
                        padding: 12px;

                        &.active {
                            background: #00aff4;
                        }
                    }
                }
            }

            .list-choice {
                display: grid;
                // flex-direction: column;
                flex-wrap: wrap;
                padding-top: 24px;
                gap: 16px;
                //        @media screen and (max-width: 768px) {
                @media screen and (max-width: 1024px) {
                    padding-top: 8px;
                    gap: 12px;
                    grid-template-columns: 1fr !important;
                    overflow-y: auto;
                    max-height: 290px;
                }

                &.role {
                    // flex-direction: column;
                }
                &:not(.role) {
                    grid-template-columns: 1fr 1fr;
                }

                .active-choice {
                    background: #f9fafb;
                    border: 1px solid #f2f4f7;
                }
                .MuiFormControlLabel-root {
                    // background: #f9fafb;
                    border: 1px solid #f2f4f7;
                    border-radius: 8px;
                    padding-left: 16px;
                    .MuiTypography-root {
                        padding: 16px;
                    }
                    .MuiTouchRipple-root {
                        border: 1px solid #d0d5dd;
                        border-radius: 6px;
                    }
                    .Mui-checked {
                        .MuiTouchRipple-root {
                            border: 1px solid #e4e7ec;
                            border-radius: 6px;
                        }
                    }
                }
            }
        }

        .stepper-content {
            background: #ffffff;
            box-shadow: 8px 8px 24px rgba(2, 2, 70, 0.05);
            border-radius: 31.2294px;
            width: calc(35% - 80px);
            padding: 40px;
            // width: 100   %;
            //        @media screen and (max-width: 768px) {
            @media screen and (max-width: 1024px) {
                padding: 0 24px 24px 24px;
                width: calc(100% - 48px);
            }
            .MuiStepper-root {
                display: flex;
                flex-direction: column;
                //        @media screen and (max-width: 768px) {
                @media screen and (max-width: 1024px) {
                    flex-direction: row;
                }
                .MuiStep-root {
                    display: flex;
                    flex-direction: column;

                    .MuiStepLabel-root {
                        @media screen and (min-width: 1025px) {
                            flex-direction: row;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 12px;
                            .MuiStepLabel-labelContainer {
                                .MuiStepLabel-label {
                                    margin-top: 0;
                                    text-align: start;
                                }
                            }

                            .MuiStepLabel-iconContainer {
                                > div {
                                    background-color: #fff;
                                    box-shadow: none;
                                }
                            }
                        }
                    }

                    .MuiSvgIcon-root {
                        //        @media screen and (max-width: 768px) {
                        @media screen and (max-width: 1024px) {
                            &.Mui-completed,
                            &.Mui-active {
                                color: #212121;
                            }
                        }
                    }

                    .MuiStepConnector-root {
                        // position: absolute !important;
                    }
                }
            }
            .MuiStepConnector-root {
                .MuiStepConnector-lineHorizontal {
                    border-radius: 1px;
                    border-top-width: 4px;
                    border-top-style: solid;
                    color: #020246;
                }
            }

            .MuiStepIcon-root.MuiStepIcon-completed {
                color: #004892;
            }
        }

        .thank-you {
            width: 796px;
            height: 468px;
            margin: auto;
            display: flex;
            flex-direction: column;
            gap: 30px;
            align-items: center;
            justify-content: center;
            background: #ffffff;
            box-shadow: 8px 8px 24px rgba(2, 2, 70, 0.05);
            border-radius: 31.2294px;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            text-align: center;
            color: #101828;
            //        @media screen and (max-width: 768px) {
            @media screen and (max-width: 1024px) {
                width: 100%;
                padding: 16px 0;
                height: fit-content;
            }

            .content {
                .note {
                    font-size: 12px;
                }
            }
        }
    }
    .download-button {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
    }

    .MuiDialogActions-root {
        justify-content: space-between;
        padding: 0;
        display: flex;
        padding-top: 24px;
        //        @media screen and (max-width: 768px) {
        @media screen and (max-width: 1024px) {
            justify-content: space-between;
            width: 100%;
            background-color: #fff;
            padding: 16px 0;
        }
        .ctn {
            border-radius: 8px;
            color: #212121;
            text-transform: capitalize;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            padding: 12px 20px;
            //        @media screen and (max-width: 768px) {
            @media screen and (max-width: 1024px) {
                padding: 12px 32px;
                border-radius: 12px;
                border: 1px solid #e7e7e8;
            }
        }
        .btn-right {
            margin-left: auto;
        }

        .back-continue {
            display: flex;
            gap: 8px;
            margin-left: auto;
            //        @media screen and (max-width: 768px) {
            @media screen and (max-width: 1024px) {
                background-color: #fff;
                width: 100%;
            }
            .ctn-back {
                background: #ffffff;
            }

            .ctn-continue {
                background: #212121;
                color: #ffffff;
                padding: 12px 14px 12px 20px;

                box-shadow: 8px 8px 24px rgba(2, 2, 70, 0.15);
                display: flex;
                gap: 8px;
                //        @media screen and (max-width: 768px) {
                @media screen and (max-width: 1024px) {
                    width: 100%;
                }
                &.Mui-disabled {
                    background: rgba(33, 33, 33, 0.24);
                }
            }
        }

        .ctn-close-popup {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .ctn {
                a {
                    text-decoration: none;
                }
                width: fit-content;
                padding: 12px 48px;
                background: #212121;
                color: #ffffff;
                //        @media screen and (max-width: 768px) {
                @media screen and (max-width: 1024px) {
                    width: 100%;
                }
            }
        }
    }
    .MuiDialog-container {
        height: unset;
        //        @media screen and (max-width: 768px) {
        @media screen and (max-width: 1024px) {
            width: 100%;
            .MuiPaper-root {
                max-width: 100% !important;
                max-height: 70vh;
                margin: unset;
                width: 95%;
            }
        }
    }
}
.action-component {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    @media screen and (min-width: 1025px) {
        &:hover {
            .action {
                display: block;
            }
        }
    }
    @media screen and (max-width: 768px) {
        display: grid;
        justify-content: center;
        padding-top: 24px;
    }
    .MuiButton-root {
        text-transform: none;
        //        @media screen and (max-width: 768px) {
        @media screen and (max-width: 1024px) {
            margin-top: 16px;
        }
    }
    .child {
        padding-top: 12px;
    }
    .download {
        // background: rgba(var(--color), 1);
        cursor: pointer;
        border-radius: 57px;
        color: #fff;
        // padding: 9px 60px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        transition: all 0.15s ease;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-shadow: 0px 4px 10px rgba(34, 17, 17, 0.08);

        .download-icon {
            padding-right: 20px;
        }
    }
    .action {
        position: absolute;
        top: 44px;
        right: -50%;
        min-width: 160px;
        padding: 12px 0px;
        gap: 4px;
        background: #ffffff;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.06),
            0px 1px 10px rgba(0, 0, 0, 0.04), 0px 2px 4px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        @media screen and (min-width: 1025px) {
            display: none;
        }
        p {
            margin: 0;
        }
        .edit,
        .clone {
            cursor: pointer;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            font-feature-settings: "liga" off;
            color: #212121;
            box-sizing: border-box;
            background-color: #fff;
            transition: all 0.15s ease;
            display: flex;
            align-items: center;
            gap: 4px;
            padding: 8px 12px;

            &:hover {
                background: rgba(33, 33, 33, 0.04);
            }

            p {
                display: flex;
                gap: 6px;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .css-1ujsas3:hover {
        background-color: rgba(var(--color), 1);
    }
}
//      @media screen and (max-width: 780px) {
@media screen and (max-width: 1024px) {
    .dialog-form-login {
        z-index: 2000 !important;
        .MuiPaper-root {
            // min-width: unset;

            width: 100%;
            padding: 0;
            background-color: transparent;
            background-image: none;
        }
        .list-choice {
            grid-template-columns: 1fr;
        }
        .text-field {
            width: 90%;
        }

        .content {
            padding: 36px 24px 0 24px;
            // max-height: 80%;
        }
    }
}
.menu-collection {
    .MuiList-root {
        display: grid;
        padding: 8px;
        li {
            padding: 8px;
            display: flex;
            gap: 8px;
            justify-content: flex-start;
            background: #fff;
            svg {
                width: 24px;
            }
            &:hover {
                background: rgba(var(--color), 1);
                border-radius: 4px;
                color: #fff;
                .edit-icon {
                    path {
                        fill: #fff;
                        fill-opacity: 1;
                    }
                }
                &.download {
                    circle {
                        display: none;
                    }
                    path {
                        fill: #fff;
                    }
                }
            }
        }
    }
}
.popup-download-collection {
    .content {
        .action {
            display: flex;
            p {
                display: flex;
                gap: 4px;
                align-items: center;
            }
        }
    }
}

.drawer-more-action-ws-panel {
    z-index: 1400 !important;
    .MuiPaper-root {
        background-color: unset;
        text-align: center;
        box-shadow: none;
        ::-webkit-scrollbar {
            display: none;
        }

        .drawer-wrapper-content {
            background-color: #fff;
            border-radius: 32px 32px 0px 0px;
            padding: 32px 0;

            .content {
                color: rgba(var(--color), 1);
                .list-button {
                    li {
                        display: flex;
                        gap: 8px;
                        padding-left: 24px;

                        p {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        color: #212121;
        margin: 0;
        position: relative;
        padding-top: 16px;

        // &::after {
        //     position: absolute;
        //     content: "";
        //     height: 1px;
        //     top: -0px;
        //     margin: 0 auto;
        //     left: 0;
        //     right: 0;
        //     width: 100%;
        //     background: #e7e7e8;
        //     -o-transition: 0.5s;
        //     -ms-transition: 0.5s;
        //     -moz-transition: 0.5s;
        //     -webkit-transition: 0.5s;
        //     transition: 0.5s;
        // }
    }

    .content {
        background-color: #fff;
        border-radius: 12px 12px 0px 0px;
        overflow: hidden;

        @media screen and (max-width: 768px) {
            .action.action-del {
                display: flex;
                width: 100%;
                justify-content: space-around;
                gap: 8px;

                div {
                    padding: 12px;
                    border: 1px solid #e7e7e8;
                    border-radius: 12px;
                    width: 100%;

                    &.delete {
                        background-color: #ff2442;
                        color: #fff;
                    }
                }
            }

            .MuiDialogContent-root {
                padding: 16px 24px 20px;
            }
        }
    }
}

.login-container {
    * {
        box-sizing: initial;
    }
}
