.text-style-section {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
        font-size: 14px;
        font-weight: 500;
        color: #1F2937;
        font-family: Nunito;
        letter-spacing: 0.08px;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .select-font {
        margin: 4px;
        background: #FFFFFF;

        .MuiIconButton-root {
            padding: 0px;
        }

        .MuiInputBase-root,
        .MuiOutlinedInput-root,
        .MuiSelect-root {
            padding-right: 4px;
        }

        .select {

            border-radius: 4px;

            .MuiOutlinedInput-notchedOutline {
                border-color: #E5E7EB;
            }

            &.Mui-focused .MuiOutlinedInput-notchedOutline {
                border-color: #E5E7EB;
            }

            &:hover .MuiOutlinedInput-notchedOutline {
                border-color: #E5E7EB;
            }

            .MuiSelect-select {
                padding: 8px 6px 8px 12px;
                font-size: 12px;
            }

            .MuiSvgIcon-root {
                fill: #212121,
            }
        }
    }

    .cluster {
        display: flex;
        flex-direction: column;
        border-radius: 6px;
        border: 1px solid var(--gray-200, #E5E7EB);
        gap: 4px;
        /* Drop Shadow/sm */
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

        background: var(--gray-50, #F9FAFB);

    }

    .title-and-color {
        display: flex;
        padding: 8px 8px 8px 12px;
        align-items: center;
        justify-content: space-between;
        gap: 8px;

        background: var(--white-white, #FFF);
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        .title {
            margin-top: 0;
            margin-bottom: 0;
        }

        .color {
            display: flex;
            align-items: center;

            .choose-color {
                width: 16px;
                height: 16px;
                margin-right: 5px;
                cursor: pointer;
                border-radius: 5px;
                border: 1px solid #DFDFDF;
                margin-left: 4px;
                position: relative;

                .picker-wrapper {
                    position: absolute;
                    bottom: 20px;
                    right: 0;
                    z-index: 10;
                }
            }
        }
    }

    .slider-section {
        margin-top: 20px;
        position: relative;
        padding-right: 14px;
        padding-left: 14px;

        .label {
            position: absolute;
            top: -18px;
            right: 10px;
            color: #212121;
            font-family: Nunito;
            font-size: 14px;
            font-weight: 400;
        }

        .slider-element {
            color: rgba(var(--color), 1);

            & .MuiSlider-thumb {
                width: 16px;
                height: 16px;

                &:focus,
                &:hover,
                &.Mui-active,
                &.Mui-focusVisible {
                    box-shadow: 0px 0px 0px 4px rgba(25, 118, 210, 0.16),
                }

                ;
            }
        }

    }
}