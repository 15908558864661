.template-ws {
    overflow: hidden;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        .number-of-image {
            visibility: visible;
        }
        transform: translateY(-5px);
        filter: drop-shadow(0 4px 10px rgba(33, 33, 33, 0.08));
    }

    .slider-ws {
        display: flex;
        position: relative;
        transition: transform 0.5s ease-in-out;
    }

    .number-of-image {
        padding: 2px 6px;
        position: absolute;
        right: 8px;
        bottom: 10px;
        z-index: 3;
        border-radius: 40px;
        background: rgba(33, 33, 33, 0.52);
        backdrop-filter: blur(3px);

        visibility: hidden;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-align: center;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
    }
}
