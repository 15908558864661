.container-shapes {
    position: relative;
    .label-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 600;
        margin-top: 16px;

        .label {
            color: #212121;
        }

        .see-all {
            color: #3B82F6;
            cursor: pointer;
        }
    }

    .scroll-horizontal {
        overflow: auto;
        display: grid;
        grid-auto-flow: column dense;
        grid-template-rows: 64px 64px;
        grid-auto-columns: 64px;
        gap: 24px;
        padding-top: 16px;

        .resource-item {
            z-index: 1;

            .img-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 95%;
                height: 95%;

                img {
                    height: 64px;
                    width: auto;
                    max-width: 100%;
                    object-fit: contain;
                }

                div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 64px;
                    height: 64px;
                }
            }
        }
    }

    .scroll-horizontal-line {
        overflow: auto;
        display: grid;
        grid-auto-flow: column dense;
        grid-template-rows: 24px 24px 24px;
        grid-auto-columns: 64px;
        gap: 24px;
        padding-top: 16px;

        .resource-item {
            .img-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 95%;
                height: 95%;

                img {
                    height: 24px;
                    width: 100%;
                }
            }
        }
    }

    .scroll-horizontal-table {
        overflow: auto;
        display: grid;
        grid-auto-flow: column dense;
        grid-template-rows: 64px;
        grid-auto-columns: 64px;
        gap: 12px;
        padding-top: 16px;

        .resource-item {
            .img-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 95%;
                height: 95%;

                img {
                    height: 53px;
                    width: 58px;
                }
            }
        }
    }

    .hide-bar::-webkit-scrollbar {
        background: transparent;
        width: 0px;
        height: 0px;
        scroll-behavior: smooth;
        transition: transform 1s;
    }

    .box-arrow-right,
    .box-arrow-left {
        position: absolute;
        right: 0;
        width: 24px;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 10.33%, #FFF 100%);
    }

    .box-arrow-left {
        left: 0;
        background: linear-gradient(90deg, #FFF 10.33%, rgba(255, 255, 255, 0.00) 100%);
    }

    .arrow-right,
    .arrow-left {
        border-radius: 40px;
        background: rgba(255, 255, 255, 0.80);
        box-shadow: 0px 0px 6px 0px rgba(33, 33, 33, 0.24);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
    }

    .arrow-left {
        transform: rotate(180deg);
    }
}

.list-resource-item-shape {
    grid-gap: 12px;
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 12px;
    // padding: 0 24px 0 24px;

    .resource-item {
        .img-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 95%;
            height: 95%;

            img {
                height: 64px;
                width: 64px;
                // max-width: 100%;
                // object-fit: contain;
            }

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 64px;
                height: 64px;
            }
        }
    }

    .list-item {
        .img-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            padding: 8px;
            border-radius: 8px;

            img {
                height: 50px;
                width: 100%;
                max-width: 100%;
            }
        }

    }
}

.back-tab-elment {
    display: flex;
    align-items: center;
    padding-left: 4px;
    padding-top: 8px;
    padding-bottom: 8px;

    .icon-back {
        display: flex;
        width: 26px;
        height: 26px;
        border-radius: 30px;
        justify-content: center;
        align-items: center;
        background: rgba(33, 33, 33, 0.12);
        box-shadow: 0px 0px 8px 0px rgba(33, 33, 33, 0.08);
    }

    span {
        padding-left: 8px;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        color: #212121;
    }
}

.list-resource-item-line {
    grid-gap: 16px;
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    // padding: 0 24px 0 24px;
    z-index: 1;

    .resource-item {
        z-index: 2;

        .img-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 95%;
            height: 95%;

            img {
                height: 24px;
                width: 100%;
            }
        }
    }
}

.list-resource-item-table {
    grid-gap: 12px;
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 12px;
    // padding: 0 0px 0 0px;

    .resource-item {
        .img-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 95%;
            height: 95%;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 64px;
                height: 64px;
            }
        }
    }

    .list-item {
        .img-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            padding: 8px;
            border-radius: 8px;

            img {
                height: 50px;
                width: 100%;
                max-width: 100%;
            }
        }

    }
}