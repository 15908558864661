.required-section {
    border-radius: 0px 0px 6px 6px;
    background: #F9FAFB;
    padding: 16px 8px 8px;

    .list-style-container {
        padding: 8px 12px;
        display: flex;
        margin-bottom: 12px;
        align-items: center;

        .list-style {
            display: flex;
            gap: 8px;

            .item {
                border-radius: 4px;
                cursor: pointer;
                border: 1px solid transparent;
                background: rgba(33, 33, 33, 0.02);
                transition: all 0.15s;

                &:hover {
                    border: 1px solid rgba(61, 132, 245, 0.6);
                    background: rgba(33, 33, 33, 0.03);
                }

                &.active {
                    border: 1px solid #3B82F6;
                    background: rgba(33, 33, 33, 0.05);
                }
            }
        }
    }

    .slider-container {
        margin-bottom: 12px;
        .header {
            padding: 8px;
            display: flex;
            background: #FFF;
        }

        .question-slider-container {
            padding: 10px 12px;

            .question-slider {
               padding: 4px 0px;

               & .MuiSlider-track {
                    color: #3B82F6;
               }

               & .MuiSlider-thumb {
                    width: 16px;
                    height: 16px;
                    border: 1px solid #fff;
                    background-color: #3B82F6;

                    &:focus,
                    &:hover,
                    &.Mui-active,
                    &.Mui-focusVisible {
                        box-shadow: 0px 0px 0px 3px rgba(25, 118, 210, 0.16),
                    }
                }
            }
        }
    }

    .range-factor-container {
        position: relative;
        margin-bottom: 12px;
        .divide {
            position: absolute;
            width: 96%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #E4E4E4;
            height: 1px;
        }
        .range-container {
            display: flex;
            padding: 8px 8px 8px 12px;

            .group-input {
                display: flex;

                .title {
                    margin: 0 8px;
                    color: rgba(33, 33, 33, 0.52);
                }
            }
        }
    }

    .checkbox-container {
        padding: 8px 12px;
        display: flex;
        margin-bottom: 12px;

        .checkbox {
            padding: 0px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.disable {
            opacity: 0.4;
            .checkbox {
                opacity: 0.4;
            }
        }
    }
}