.input-section-hand-writing {
    border: 1px solid rgba(33, 33, 33, 0.20);
    background: #FFFFFF;

    .input-wrapper {
        // height: 114px;
        overflow: hidden;

        .bar-questions {
            .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
                background-color: rgb(153, 153, 153);
                width: 3px;
                margin-left: 4px;
            }
        }


        .input-container {

            position: relative;
            border-radius: 4px;
            border: 1px dashed rgba(33, 33, 33, 0.12);
            padding: 8px;
            margin: 8px;
            display: flex;
            transition: border 0.3s, box-shadow 0.3s;

            .index {
                margin-right: 4px;
            }

            .input {
                border: none;
                outline: none;
                background-color: #FFFFFF;
                flex: 1;

                &::placeholder {
                    color: rgba(33, 33, 33, 0.40);
                    font-size: 12px;
                    font-style: italic;
                    overflow: auto;
                    font-weight: 400;
                }
            }


            .icon-delete {
                position: absolute;
                padding: 1px;
                top: 0px;
                right: 0px;
                z-index: 2;
                opacity: 0;
            }

            &:hover .icon-delete {
                animation: showBtn 0.5s forwards;
            }

            &:last-child {
                margin-bottom: 12px;
            }

            &.focus {
                border: 1px solid rgba(var(--color), 1);
                box-shadow: rgba(59, 130, 246, 0.12) 0px 0px 0px 3px;
            }

            &.un-focus.full {
                border: 1px solid rgba(33, 33, 33, 0.12);

            }

            &.un-focus.empty {
                border: 1px dashed rgba(33, 33, 33, 0.12);

            }

            &.empty {
                color: rgba(33, 33, 33, 0.40);
            }

            &.add-input {
                border: none;
                cursor: text;
            }

            &:hover.un-focus.full {
                border: 1px solid rgba(33, 33, 33, 0.52);

            }

            &.first {
                .input {
                    &::placeholder {
                        color: #D1D5DB;
                        font-size: 14px;
                        font-weight: 500;
                        letter-spacing: 0.07px;
                        font-style: normal;
                    }
                }
            }
        }
    }

    .auto-fill {

        display: flex;
        justify-content: flex-end;
        background: #F9FAFB;

        .btn {
            text-align: center;
            font-family: Nunito;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.06px;
            text-transform: none;
            margin: 6px;
            padding: 4px 8px;
            border-radius: 6px;
            border: 1px solid rgba(33, 33, 33, 0.12);
            color: #212121;
            background: rgb(255, 255, 255);
            // transition: all 0.3s;

            &.show_hw {
                background: rgba(var(--color), 1);
                color: #FFF;

            }

            &:hover.show_hw {
                background: #1F68DE;
            }
        }
    }

    input[disabled] {
        pointer-events: none
    }
}