.resource-name-tracing {
    position: relative;
    padding-left: 5px;
    padding-right: 5px;
    line-height: 1em;
    white-space: nowrap;
    display: flex;
    align-items: flex-end;
    justify-content: center;


    .border {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        border-top: 1px solid;
        border-bottom: 1px solid;

        .line {
            white-space: nowrap;
            display: flex;
            align-items: center;
            width: 100%;
            height: 50%;
            opacity: 0.15;
            border-bottom: 3px dashed;
        }
    }
    .main-text {
        white-space: pre;
        text-align: center;
    }
}