.claim-coins-content {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .claim-coins-content-text {
        cursor: pointer;
        font-size: 13px;
        font-weight: 600;
        color: rgba(var(--color), 1);
        text-decoration: underline;
        display: flex;
        align-items: center;
        justify-content: center;
        .rotate {
            transition: all ease-in-out 0.2s;
            &.rotate-2 {
                rotate: 180deg;
            }
        }
    }
    .list-method {
        display: grid;
        gap: 12px;
        padding-top: 8px;
        .item-method {
            .c-totals-coins-number {
                font-size: 18px;
                font-weight: 400;
                text-align: end;
            }
            display: grid;
            gap: 8px;
            padding: 12px;
            // background: linear-gradient(0deg, #e5e7eb, #e5e7eb),
            //     linear-gradient(0deg, #ffffff, #ffffff);
            border: 1px solid #e5e7eb;
            box-shadow: 0px 1px 2px 0px #0000000d;
            border-radius: 6px;
            .item-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .item-label {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    min-width: 163px;
                    padding: 8px 8px;
                    cursor: pointer;
                    border-radius: 59px;
                    box-shadow: 0px 4px 4px 0px #2121211f;
                    border: 2px solid #ffffff;
                    &.able {
                        background: linear-gradient(0deg, #ffd87a, #ffd87a),
                            linear-gradient(0deg, #ffffff, #ffffff);
                    }
                    &.disable {
                        background: linear-gradient(0deg, #e0e0e0, #e0e0e0),
                            linear-gradient(0deg, #ffffff, #ffffff);
                    }
                    p {
                        font-size: 15px;
                        font-weight: 700;
                    }
                }
            }
            .box-item {
                border-radius: 4px;
                padding: 12px;
                background: #f4f4f4;
            }
            .item-section,
            .item-description {
                display: flex;
                align-items: center;
            }
            .item-section {
                justify-content: space-between;
                .item-description {
                    font-size: 12px;
                    font-weight: 500;
                    color: #6b7280;
                    gap: 4px;
                    .icon {
                        cursor: pointer;
                        // &:hover {
                        //     path {
                        //         fill: #2e3b4b !important;
                        //         opacity: 1 !important;
                        //     }
                        // }
                    }
                    .explain {
                        position: relative;
                        .tool-tip {
                            position: absolute;
                            max-width: 270px;
                            width: max-content;
                            background: #2e3b4b;
                            border-radius: 4px;
                            top: 22px;
                            left: -12px;

                            .tooltip-arrow {
                                position: absolute;
                                left: 19px;
                                transform: translateX(-50%);
                                bottom: 100%;
                                border-left: 5px solid transparent;
                                border-right: 5px solid transparent;
                                border-bottom: 5px solid #2e3b4b;
                            }
                            &.top {
                                top: -50px;
                                .tooltip-arrow {
                                    top: 100%;
                                    border-top: 5px solid #2e3b4b;
                                    border-bottom: unset;
                                }
                            }
                            p {
                                font-size: 10px;
                                font-weight: 500;
                                padding: 4px 8px;
                                color: #ffffff;
                            }
                        }
                    }
                }

                &.invite-friend,
                &.share-pinterest {
                    .item-description {
                        span {
                            font-weight: 700;
                            color: #212121;
                        }
                    }
                }
            }
            .claim-action {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .qty,
                .qty-number {
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 22.5px;
                    gap: 4px;
                }
                .qty-number {
                    font-size: 16px;
                    line-height: 16.48px;
                    color: #bf8516;
                }
            }
            .btn-claim-download {
                padding: 4px 12px;
                border-radius: 6px;
                border: 1px solid #fff;
                background: #ffcf5c;
                box-shadow: 0px 1px 4px 0px rgba(33, 33, 33, 0.25);
                cursor: pointer;
                min-width: 38px;
                color: #212121;
                font-size: 14px;
                font-weight: 700;
                .circular-progress {
                    width: 20px !important;
                    height: 20px !important;
                }
            }
        }
    }
}
