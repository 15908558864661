.wrapper {
    display: flex;
    flex-direction: column;
    
    .box-list-action {
        display: flex;
        padding: 24px 8px 12px 8px;
        flex-direction: column;
        gap: 12px;
        background: #F9FAFB;
    }
}