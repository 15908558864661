.claim-download {
    padding-top: 12px;
    .btn-save-to-device {
        // background: linear-gradient(
        //         0deg,
        //         rgba(var(--color), 1),
        //         rgba(var(--color), 1)
        //     ),
        //     linear-gradient(0deg, #ffffff, #ffffff);
        border: 1px solid rgba(var(--color), 1);
        border-radius: 6px;
        cursor: pointer;
        transition: all ease-in-out 0.3s;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        overflow: hidden;
        .circular-progress {
            width: 20px !important;
            height: 20px !important;
        }
        &:hover {
            transform: translateY(-2px);
        }
        .btn-save-to-device-text {
            padding: 8px 0;
            text-align: center;
            color: rgba(var(--color), 1);
            position: relative;
            .loading-component {
                position: absolute;
                right: calc(100% + 4px);
            }
        }
    }
    .notification-out-of-dl {
        display: flex;
        align-items: end;
        font-size: 10px;
        font-weight: 500;
        color: #755118;
        gap: 4px;
        padding: 8px 0;
        justify-content: center;
        border-radius: 6px;
        background: #fef7d1;
        margin-top: 4px;
        // &.none {
        //     opacity: 0;
        // }

        &.active {
            opacity: 0;
            animation: outOfDlShow 0.3s ease forwards;
            visibility: visible;
        }
        &.hidden {
            animation: outOfDlHidden 0.3s ease forwards;
            opacity: 1;
        }
        @keyframes outOfDlShow {
            0% {
                // height: 0;
                margin-top: -32px;
            }
            50% {
                opacity: 0;
            }
            100% {
                opacity: 1;
                // height: 100%;
                margin-top: 4px;
            }
        }

        @keyframes outOfDlHidden {
            0% {
                opacity: 1;
                margin-top: 4px;
            }
            10% {
                opacity: 0;
            }
            100% {
                opacity: 0;
                margin-top: -32px;
                display: none;
            }
        }
    }
}
