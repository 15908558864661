.name-tracing-container {
    & .hide_nt {
        visibility: visible;
        animation: hideContent 0.4s forwards;
    }

    & .show_nt {
        animation: showAni 1s forwards;
    }

    @keyframes hideContent {
        from {
            opacity: 1;
            visibility: visible;
        }
    
        to {
            opacity: 0;
            visibility: hidden;
        }
    }
    
    @keyframes showAni {
        from {
            opacity: 0;
        }
    
        to {
            opacity: 1;
        }
    }
}
