.header-bar-container {
    display: grid;
    justify-content: space-between;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(57, 57, 57, 0.4);

    .left-header {
        display: flex;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;

        h1 {
            font-size: 15px;
            margin: 0;
        }

        & > div:first-child {
            text-align: center;
            margin-right: 20px;

        }

        .logo {
            width: 30px;
            height: 30px;
            text-align: center;
        }

        .version {
            font-size: 10px;
        }
    }

    .right-header {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding-right: 20px;

        .functions {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .divide {
            content: "";
            position: absolute;
            left: 0;
            width: 2px;
            height: 34%;
            background: rgba(33, 33, 33, 0.24);
            top: 50%;
            transform: translateY(-50%);
        }

        .left-function {
            display: flex;
            // justify-content: center;
            align-items: center;
            gap: 16px;
            flex: 1;
        }

        .other-function {
            display: flex;
            align-items: center;
            gap: 12px;

        }

        button {
            font-size: 12px;
        }
    }

    .noselect {
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Old versions of Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
    }

    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .jump {
        transition: transform 0.2s ease-in-out;

        &:hover {
            transform: translateY(-2px);
        }
    }

    @keyframes hoverBorder {
        from {
            border-color: #D1D5DB;
        }

        to {
            border-color: #89898A;
        }
    }
}