.dimension-infor-tooltip {
    position: absolute;
    bottom: -30px;
    right: -60px;
    font-size: 10px;
    font-weight: bold;
    background: black;
    color: white;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
}