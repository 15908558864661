.popup-edit-table-container {
    display: flex;
    width: 165px;
    flex-direction: column;
    align-items: center;
    background: #fff;

    .row-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        cursor: pointer;

        .lable{
            color: #212121;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            font-family: Nunito;
        }

        .icon {
            display: flex;
            width: 28px;
            height: 32px;
            padding: 4px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
        }

        &:hover {
            background: rgba(33, 33, 33, 0.08);
        }
    }
    .divider {
        width: 100%;
        height: 1px;
        background: rgba(33, 33, 33, 0.08);
    }
}
