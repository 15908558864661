.suggest-ans {
    cursor: pointer;
    border-radius: 50%;
    @media screen and (max-width: 1024px) {
        margin-right: 8px;
    }

    &.active {
        box-shadow: 0px 0px 10px 2px rgba(254, 184, 62, 0.36);
        // animation: myfirst 1s linear 0.2s infinite;
        animation: myfirst 60s linear 0.2s infinite;
    }

    .suggest-ans-light {
        background-color: rgba(162, 162, 162, 1);
        &.active {
            background-color: #fff;
        }
        padding: 5px;
        border-radius: 50%;

        width: 20px;
        height: 20px;
        transition: all ease-in-out 0.2s;
        @media screen and (max-width: 1024px) {
            border-radius: 4px;
            border: 1px solid rgba(33, 33, 33, 0.12);
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
}
@keyframes myfirst {
    0% {
        transform: scale(1);
        box-shadow: 0px 0px 10px 2px rgba(254, 184, 62, 0.36);
        // img {
        //     width: 100%;
        //     height: 100%;
        // }
    }

    1% {
        transform: scale(1.2);
        box-shadow: 0px 0px 15px 5px rgba(254, 184, 62, 0.36);
        // img {
        //     width: 200%;
        //     height: 200%;
        // }
    }

    2% {
        transform: scale(1);
        box-shadow: 0px 0px 10px 2px rgba(254, 184, 62, 0.36);
        // img {
        //     width: 100%;
        //     height: 100%;
        // }
    }
    3% {
        transform: scale(1.2);
        box-shadow: 0px 0px 15px 5px rgba(254, 184, 62, 0.36);
        // img {
        //     width: 200%;
        //     height: 200%;
        // }
    }
    4% {
        transform: scale(1);
        box-shadow: 0px 0px 10px 2px rgba(254, 184, 62, 0.36);
        // img {
        //     width: 100%;
        //     height: 100%;
        // }
    }
    5% {
        transform: scale(1.2);
        box-shadow: 0px 0px 15px 5px rgba(254, 184, 62, 0.36);
        // img {
        //     width: 200%;
        //     height: 200%;
        // }
    }
    6% {
        transform: scale(1);
        box-shadow: 0px 0px 10px 2px rgba(254, 184, 62, 0.36);
        // img {
        //     width: 100%;
        //     height: 100%;
        // }
    }
}

//     .suggest-ans-light {
//         background-color: rgba(162, 162, 162, 1);
//         &.active {
//             background-color: #fff;
//         }
//         padding: 5px;
//         border-radius: 50%;
//         width: 20px;
//         height: 20px;
//         transition: all ease-in-out 0.2s;
//         img {
//             width: 100%;
//             height: 100%;
//         }
//     }
// }
// @keyframes myfirst {
//     0% {
//         transform: scale(1);
//         box-shadow: 0px 0px 10px 2px rgba(254, 184, 62, 0.36)
//         img {
//             width: 100%;
//             height: 100%;
//         }
//     }

//     1% {
//         transform: scale(1.2);
//         box-shadow: 0px 0px 15px 5px rgba(254, 184, 62, 0.36)
//         img {
//             width: 200%;
//             height: 200%;
//         }
//     }

//     2% {
//         transform: scale(1);
//         box-shadow: 0px 0px 10px 2px rgba(254, 184, 62, 0.36);
//         img {
//             width: 100%;
//             height: 100%;
//         }
//     }
//     3% {
//         transform: scale(1.2);
//         box-shadow: 0px 0px 15px 5px rgba(254, 184, 62, 0.36);
//         img {
//             width: 200%;
//             height: 200%;
//         }
//     }
//     4% {
//         transform: scale(1);
//         box-shadow: 0px 0px 10px 2px rgba(254, 184, 62, 0.36);
//         img {
//             width: 100%;
//             height: 100%;
//         }
//     }
//     5% {
//         transform: scale(1.2);
//         box-shadow: 0px 0px 15px 5px rgba(254, 184, 62, 0.36);
//         img {
//             width: 200%;
//             height: 200%;
//         }
//     }
//     6% {
//         transform: scale(1);
//         box-shadow: 0px 0px 10px 2px rgba(254, 184, 62, 0.36)
//         img {
//             width: 100%;
//             height: 100%;
//         }
//     }
// }

// // @keyframes myfirst {
// //     0% {
// //         transform: scale(1);
// //         box-shadow: 0px 0px 10px 2px rgba(254, 184, 62, 0.36);
// //         img {
// //             width: 100%;
// //             height: 100%;
// //         }
// //     }

// //     50% {
// //         transform: scale(1.2);
// //         box-shadow: 0px 0px 15px 5px rgba(254, 184, 62, 0.36);
// //         img {
// //             width: 200%;
// //             height: 200%;
// //         }
// //     }

// //     100% {
// //         transform: scale(1);
// //         box-shadow: 0px 0px 10px 2px rgba(254, 184, 62, 0.36);
// //         img {
// //             width: 100%;
// //             height: 100%;
// //         }
// //     }
// // }
