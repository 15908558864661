.answer-fields-table {
    th {
        font-size: 12px;
        list-style: 12px;
    }

    td {
        font-size: 14px;
    }

    .answer-description {
        font-size: 12px;
    }

    .btns {
        display: flex;
        align-items: center;
    }

    .template-answer-sheet {
        cursor: pointer;

        &:hover {
            border: 1px solid red;
        }
    }
}