.popup-confirm {
  .MuiPaper-root {
    background: #ffffff;
    border-radius: 20px !important;
    text-align: center;
    padding: 8px;
    // width: 600px;
    h2 {
      padding: 0;
      padding-top: 32px;
    }
    .close-icon-delete-ws {
      position: absolute;
      top: 18px;
      right: 14px;
      
      &:hover {
        cursor: pointer;
      }
    }
    
    .MuiDialogContent-root {
      padding: 24px 120px;
      
      @media screen and (max-width: 555px) {
        padding: 24px 60px;
      }
    }
    .MuiDialogActions-root {
      padding: 8px;
      .action {
        display: flex;
        gap: 8px;
        justify-content: space-around;
        width: 100%;
        > div {
          width: 50%;
          padding: 12px;
          border: 1px solid #e7e7e8;
          border-radius: 12px;
          cursor: pointer;
        }
        .delete {
          background: #ff2442;
          border-radius: 12px;
          color: #fff;
        }
      }
    }
  }
}
