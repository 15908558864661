.textInput {
    margin: 0;
    position: absolute;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    overflow: hidden;
}
.textInput::placeholder{
    color: #212121;
    opacity: 0.32;
}

.textInput:focus {
    outline: none;
    border: none;
}

.path{
    clip-path: path("M 9.95556 0 L 146.188 0 C 151.686 0 156.143 4.45725 156.143 9.95556 L 156.143 69.5961 C 156.143 75.0944 151.686 79.5516 146.188 79.5516 L 9.95556 79.5516 C 4.45725 79.5516 0 75.0944 0 69.5961 L 0 9.95556 C 0 4.45725 4.45725 0 9.95556 0");
    background: #FF0000;
    position: absolute;
}

.custom-zone-name {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    font-style: italic;
    font-size: 14px;

    & > div {
        text-align: right;

        &:first-child {
            font-weight: bold;
        }
    }
}