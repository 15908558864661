.word-direction-resource { 
    display: flex;
    align-items: center;
    justify-content: center;
    .text-effect { 
        font-size: 24px;
        font-family: "Nunito";
        margin-right: 8px;
    }

    svg {
        path{
            fill: var(--svg-color);
        }
    }
}