.word-scramble-other-section-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    background: #F9FAFB;
    padding: 0px 8px 8px;
    .check-box-container {
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    }

    .scramble-button {
        padding: 10px 12px;
        border-radius: 6px;
        background: #3B82F6;

        color: #FFF;
        text-align: center;
        font-family: Nunito;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.075px;
        text-transform: none;
    }
}