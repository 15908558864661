.nav-project {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    .nav-item {
        text-align: center;
        cursor: pointer;
        border-bottom: 2px solid #e5e7eb;
        padding: 12px 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 19px;
        color: #1f2937;

        &.active {
            border-bottom: 2px solid rgba(var(--color), 1);
            color: rgba(var(--color), 1);
        }
        // background-image: linear-gradient(180deg, #81bae3 0%, #7888dd 100%);
    }
}
