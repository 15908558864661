.left-content-container {
    max-height: calc(100vh - 52px);
    height: calc(100vh - 52px);
    display: flex;
    justify-content: center;
    flex: 0 0 auto;
    position: relative;

    /* Allows the div to shrink but not grow */
    .line-center {
        border-right: 1px solid #ddd;
    }

    .export-for-bot-container {
        position: absolute;
        bottom: 30px;
        margin: 10px;
        font-size: 12px;
        padding: 5px;
        width: 100%;

        #export-for-bot-content {
            width: 100%;
        }
    }
}