.button-edit-line {
    width: 24px;
    background: #e2e2e2;
    height: 20px;
    display: flex;
    align-items: center;
    padding: 2px 1px 2px 5px;
    border-radius: 4px;
}

.end {
    margin: 0 10px 0 10px;
    // padding: 2px 1px 2px 6px;
}

.popup-line {
    position: absolute;
    width: 56px;
    height: fit-content;
    background: white;
    top: 30px;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 1px 1px #e2e2e2;
    flex-direction: column;
    border-radius: 6px;
}

.popup-line-start {}

.popup-line-end {
    left: 24px;
}

.button-change-path {
    height: 32px;
    display: flex;
    align-items: center;

    &:hover {
        background: #e2e2e2;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.choosed-line {
    background: #EDF4FB;
    display: flex;
    justify-content: center;
    width: 100%;
}