.set-field-name-action {
    padding: 2px;
    margin: auto;
    margin-left: 4px;
    color: #212121;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;

    &:hover{
        background-color: rgba(33, 33, 33, 0.12);
        border-radius: 4px;
    }
}