.font-family-picker-container {
    display: flex;
    align-items: center;
    border-radius: 4px 0px 0px 4px;
    height: 24px;
    background: #ffffff;
    position: relative;
    cursor: pointer;

    .input-font-family {
        width: 100px;
        letter-spacing: 0.1px;
        background-color: rgba(33, 33, 33, 0.12);
        padding-left: 10px;
        line-height: 25px;
        border-color: transparent !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        border-radius: 4px 0px 0px 4px;
        &:focus-visible {
            outline: -webkit-focus-ring-color auto 0px;
        }
    }
    .font-family-item {
        background-color: white;
        color: #000;
        width: 100%;
        padding: 4px;
        box-sizing: border-box;
        font-size: 16px;
        cursor: pointer;

        &:hover {
            background-color: lightgray;
        }

        img {
            max-width: 90%;
            height: 16px;
            padding: 4px 2px;
        }
    }
}
.button-change-font-family {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    background: #212121;
    border-radius: 0px 4px 4px 0px;
    margin-left: 1px;
    margin-top: 0.5px;
    // padding: 8px 6px;
    border: 1px solid transparent;
    // &:hover {
    //     border: 1px solid rgba(255, 255, 255, 0.12);
    // }
}
