.form-refer-container {
    z-index: 2;
    @media screen and (max-width: 1024px) {
        padding: 8px 16px 0;
    }
    .title-form-refer {
        color: #475569;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.075px;
        @media screen and (max-width: 1024px) {
            color: var(--gray-800, #1f2937);
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.07px;
            p {
                margin-top: 0;
                margin-bottom: 7px;
            }
        }
    }
    .title-table-item {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 8px 12px;
        border-radius: 8px 8px 0px 0px;
        background: var(--gray-200, #e5e7eb);
        .text-item-title {
            p {
                margin: 0;
                color: #000;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
    .user-table-item {
        background: white;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 10px 12px;
        border: 1px solid rgba(33, 33, 33, 0.08);
        border-top: none;
        @media screen and (max-width: 1024px) {
            background: none;
        }
        .text-item-user {
            display: flex;
            gap: 4px;
            p {
                margin: 0;
                color: #000;
                font-size: 12px;
                font-weight: 400;
                text-transform: capitalize;
            }
            .text-done {
                color: #14b8a6;
                font-size: 12px;
                font-weight: 400;
            }
            .img-refer-done {
                display: flex;
                justify-content: center;
            }
        }
    }
    .refer-content-table {
        overflow-y: auto;
        max-height: 150px;
    }

    .form-refer-table {
        @media screen and (max-width: 1024px) {
            border-radius: 8px;
            border: 1px solid var(--gray-200, #e5e7eb);
            background: var(--white-white, #fff);

            box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06),
                0px 1px 2px 0px rgba(0, 0, 0, 0.1);
        }
    }
}
