.list-orientation {
    width: fit-content;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 16px);
    right: 0px;
    z-index: 8;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(33, 33, 33, 0.12),
        0px 1px 1px 0px rgba(33, 33, 33, 0.06);
    .orientation-item {
        display: flex;
        width: 116px;
        height: 24px;
        padding: 4px;
        justify-content: flex-start;
        &:hover {
            background-color: rgba(64, 87, 109, 0.07);
        }

        .text {
            color: #1f2937;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .icon {
            position: absolute;
            right: 8px;
        }

        .icon-tick {
            padding: 0 8px 0 4px;
        }
    }
}
