.crossword_word_bank {
    text-align: center;

    .title {
        font-weight: 800;
    }

    .word {
        text-transform: uppercase;
        position: relative;

        &.line-through-element {
            text-decoration: line-through;
        }
    }

}