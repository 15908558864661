.claim-coins-detail {
    .claim-coins-price {
        .c-price-item {
            display: flex;
            gap: 4px;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #2121211f;
            .c-number-ws {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 600;
                color: #bf8516;
            }
        }
    }
    .c-totals {
        display: flex;
        align-items: center;
        gap: 5px;
        .c-totals-coins {
            .c-totals-coins-number {
                font-size: 14px;
                font-weight: 700;
            }
        }
    }
    .c-price-item-text {
        font-size: 14px;
        font-weight: 500;
    }
}
