.clue_crossword {
    pointer-events: unset !important;
    .title {
        margin-bottom: 2px;
        margin-left: 2px;
        font-weight: 800;
        display: flex;
        cursor: default;

        svg {
            margin-left: 6px;
            path {
                stroke: var(--color-ar);
            }
        }
    }

    .clues {
        .clue {
            cursor: pointer;
            position: relative;
            background: transparent;
            border: 2px solid transparent;
            transition: all 0.1s linear;

            &.selected, &:hover {
                border-radius: 5px;
                border: 2px solid #FDD550;
            }

            &.selected_with_direction {
                background: #FDD550;
            }

            span.line-through-element {
                text-decoration: line-through;
            }
        }
    }
}