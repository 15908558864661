.invite-friend-container {
    @media screen and (max-width: 1024px) {
        // margin-top: 184px;
        z-index: 2;
        padding: 0 16px;
    }
    .text-header-invite {
        display: flex;
        justify-content: center;
        p {
            margin: 0;
            color: #6b7280;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            @media screen and (max-width: 1024px) {
                color: #6b7280;
                text-align: center;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
    .invite-content {
        display: grid;
        grid-template-columns: 1.2fr 4fr;
        padding: 32px 0;
        border-bottom: 1px solid #e5e7eb;
        @media screen and (max-width: 1024px) {
            display: flex;
            flex-direction: column;
            border: none;
            gap: 7px;
            padding: 24px 0 0 0;
        }
        .text-content-left {
            p {
                margin: 0;
                color: var(--gray-800, #1f2937);
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 0.07px;
            }
        }
        .invite-content-container {
            display: grid;
            grid-template-columns: 4fr 2fr;
            grid-gap: 12px;
            @media screen and (max-width: 1024px) {
                display: flex;
                gap: 0;
            }
            .new {
                display: flex;
                background: white;
                border-radius: 6px;
                border: 2px solid #e5e7eb;
                gap: 5px;
                padding-left: 8px;
                flex-wrap: wrap;
                @media screen and (max-width: 1024px) {
                    width: calc(100% - 100px);
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-right: none;
                    border: 1px solid #e5e7eb;
                }

                input {
                    padding: 14px;
                    color: var(--gray-500, #6b7280);
                    font-size: 15px;
                    font-weight: 500;
                    letter-spacing: 0.075px;
                    border: none;
                    outline: none;
                    border: none;
                    width: fit-content;
                    @media screen and (max-width: 1024px) {
                        padding: 0;
                        padding-left: 8px;
                    }
                }
                input::placeholder {
                    color: rgba(33, 33, 33, 0.24);
                    font-size: 15px;
                    font-weight: 500;
                    letter-spacing: 0.075px;
                }
                input:focus {
                    width: 300px;
                }
            }
            .email-friend-container {
                padding: 8px 0;
                background: white;
                display: flex;
                align-items: center;
                .email-content {
                    border-radius: 999px;
                    border: 1px solid #2dd4bf;
                    padding: 6px;
                    display: flex;
                    gap: 5px;
                    .img-close-email {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }
                    .img-user-email {
                        background: #8a979f;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        p {
                            color: #fff;
                            font-size: 12px;
                            font-weight: 600;
                            letter-spacing: 0.06px;
                            text-transform: capitalize;
                        }
                    }
                }
                p {
                    margin: 0;
                    color: #6b7280;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                    letter-spacing: 0.06px;
                }
                p::first-letter {
                    text-transform: uppercase;
                }
            }

            .link-refer {
                display: flex;
                border-radius: 6px;
                border: 2px solid #e5e7eb;
                background: white;
                overflow: hidden;
                @media screen and (max-width: 1024px) {
                    width: calc(100% - 92px);
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-right: none;
                    border: 1px solid #e5e7eb;
                }
                .text-url {
                    padding: 14px 16px;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    @media screen and (max-width: 1024px) {
                        // white-space: nowrap;
                        overflow: hidden;
                        width: 100%;
                    }
                    p {
                        margin: 0;
                        color: var(--gray-500, #6b7280);
                        text-align: start;
                        font-size: 15px;
                        font-weight: 500;
                        letter-spacing: 0.075px;
                        @media screen and (max-width: 1024px) {
                            text-align: start;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 1; /* number of lines to show */
                            line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                    }
                    &.http {
                        background: var(--gray-50, #f9fafb);
                        border-right: 2px solid #e5e7eb;
                    }
                }
            }
        }
        .message {
            grid-column: 2;
            p {
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 0.06px;
            }
            .text-ok {
                color: #115e59;
            }
            .text-err {
                color: #991b1b;
            }
            p::first-letter {
                text-transform: uppercase;
            }
        }
        .button-send-refer {
            cursor: pointer;
            display: flex;
            gap: 10px;
            padding: 14px 16px;
            border-radius: 6px;
            border: 2px solid #e5e7eb;
            background: #f3f4f6;
            width: fit-content;
            height: fit-content;
            @media screen and (max-width: 1024px) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border: 1px solid #e5e7eb;
                height: 19px;
            }
            .img-icon {
                display: flex;
                align-items: center;
            }
            p {
                margin: 0;
                color: #212121;
                text-align: center;
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 0.07px;
            }
        }
        .button-copy-refer {
            cursor: pointer;
            display: flex;
            gap: 10px;
            padding: 14px 16px;
            border-radius: 6px;
            border: 2px solid #e5e7eb;
            background: rgba(var(--color), 1);
            width: fit-content;
            @media screen and (max-width: 1024px) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border: 1px solid #e5e7eb;
            }
            .img-icon {
                display: flex;
                align-items: center;
            }
            p {
                margin: 0;
                color: #fff;
                text-align: center;
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 0.07px;
            }
        }
    }
    .message-copy {
        color: #115e59;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.06px;
    }
}
