.loading-component {
    circle {
        stroke: url(#linearColors) !important;
    }
    // .MuiCircularProgress-root {
    //     width: 100% !important;
    //     height: 100% !important;
    // }
}

.new-loading-component {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(167, 167, 167, 0.8);
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
}
.popup-content {
    .resource {
        .loading-component {
            .MuiCircularProgress-root {
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
}
