.layout-crossword {
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 6px;
    border: 1px solid #E5E7EB;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

    .title-layout {
        display: flex;
        padding: 8px 8px 8px 12px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        background: #FFF;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        color: #1F2937;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .dropdown-layout-container {
        border-radius: 6px;
        padding: 4px;
        background-color: #F9FAFB;

        .dropdown-layout {
            background: #fff;

            .MuiSelect-select {
                .name-option {
                    color: #1F2937;
                    font-family: Nunito;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: capitalize;
                    margin-left: 8px;
                }
            }

            .MuiOutlinedInput-notchedOutline {
                border: 0;
                border-radius: 6px;
            }

            .MuiButtonBase-root {
                // center the icon dropdown
                top: 31%;
                right: -2px;
            }
        }
    }
}

.dropdown-item-crossword-layout {
    .name-option {
        color: #212121;
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        margin-left: 8px;
    }

    &.Mui-selected {
        display: none;
    }
}