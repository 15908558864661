.word-search-shape {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid #E5E7EB;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

    .title {
        color: #1F2937;
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 8px 8px 8px 12px;
        background: #ffffff;
        border-radius: 6px 6px 0 0;
    }

    .entry-feature {
        position: relative;

        .entry-header {
            padding: 4px;
            background: var(--gray-50, #F9FAFB);
            border-radius: 6px;

            .current-choice {
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                background: #ffffff;
                border-radius: 4px;
                padding: 3px;

                font-family: Nunito;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                border: 1px solid rgba(33, 33, 33, 0.04);


                .shape {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .shape-svg {
                        height: 24px;
                    }

                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }

                .drop-down-icon {
                    display: flex;
                    align-items: center;
                    height: fit-content;

                    .MuiIconButton-root {
                        padding: 0px;
                    }
                }

                &.show-all {
                    border: 1px solid rgba(var(--color), 1);
                    box-shadow: rgba(59, 130, 246, 0.12) 0px 0px 0px 3px;
                }

                &:hover {
                    border: 1px solid rgba(var(--color), 1);
                    box-shadow: rgba(59, 130, 246, 0.12) 0px 0px 0px 3px;
                }
            }
        }

        p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 24px;
        }

        .drop-down {

            position: absolute;
            top: 100%;
            z-index: 100;
            background: linear-gradient(0deg, #ffffff, #ffffff),
                rgba(33, 33, 33, 0.04);
            display: flex;
            flex-direction: column;
            border-radius: 6px;
            box-shadow: 0px 2px 4px rgba(33, 33, 33, 0.2);
            width: 100%;
            max-height: 315px;
            padding-top: 8px;
            // padding: 8px 16px 8px 8px;


            .search-wse {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: #F3F3F3;
                border-radius: 6px;
                padding: 0px 6px 0px 8px;
                margin: 0 6px 6px 6px;
                // // width: unset;

                input,
                select,
                textarea {

                    font-family: Nunito;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                svg {
                    cursor: pointer;
                    color: #868687;

                }
            }

            .group-option {
                overflow: auto;

                .show-item-selected {
                    display: flex;
                    flex-direction: column;

                    .shape-svg {
                        background:
                            #3b82f614;
                        height: 24px;

                        svg {
                            width: 24px;
                            height: 24px;
                        }
                    }

                    .option-after-search {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 4px;
                        background:
                            #3b82f614;
                        color: #3B82F6;
                        margin: 0 12px 0 8px;
                        border-radius: 4px;

                        .cluster-svg-text {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                        }

                        .icon-apply-item {
                            align-items: center;

                            img {
                                width: 14px;
                            }
                        }
                    }

                    .under-line {
                        padding: 4px 0;
                        margin: 0 20px;
                        border-bottom: 1px solid rgba(33, 33, 33, 0.12);
                    }

                }

                .show-list {
                    margin: 0 12px 0 8px;
                    padding-top: 8px;

                    .option {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        padding: 4px;
                        border-radius: 4px;

                        &:hover {
                            background: rgba(33, 33, 33, 0.04);
                        }

                        .shape-svg {
                            height: 24px;

                            svg {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }

                &::-webkit-scrollbar-thumb {
                    border-right: 2px;
                    border-left: 2px;
                    border-radius: 10px;
                    background: rgba(33, 33, 33, 0.24);
                }

                &::-webkit-scrollbar {
                    width: 4px;
                }

                &::-webkit-scrollbar-thumb:hover {
                    background: rgba(33, 33, 33, 0.5);
                }
            }

        }
    }
}