.sentence-input-container {
    .input-wrapper {
        overflow: hidden;

        .bar-questions {
            .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
                background-color: rgb(153, 153, 153);
                width: 3px;
                margin-left: 4px;
            }
        }


        .input-container {
            position: relative;
            border-radius: 4px;
            border: 1px dashed rgba(33, 33, 33, 0.12);
            padding: 8px;
            margin: 8px;
            display: flex;
            transition: border 0.15s, box-shadow 0.15s;

            .index {
                margin-right: 4px;
            }

            .input {
                border: none;
                outline: none;
                background-color: #FFFFFF;
                flex: 1;

                &::placeholder {
                    color: rgba(33, 33, 33, 0.40);
                    font-size: 12px;
                    font-style: italic;
                    overflow: auto;
                    font-weight: 400;
                }
            }

            .textfield {
                & .MuiOutlinedInput-root {
                    padding: 4px;
                    color: #475569;
                    font-family: Nunito;
                    font-size: 12px;
                    font-weight: 500;
                    font-style: normal;
                    line-height: normal;
                    
                    fieldset {
                        font-size: 12px;
                        border: none;
                    }

                    &.Mui-focused fieldset {
                        border: none;
                    }
                }
            }


            .icon-delete {
                position: absolute;
                padding: 1px;
                top: 0px;
                right: 0px;
                z-index: 2;
                opacity: 0;
            }

            &:hover .icon-delete {
                animation: showBtn 0.5s forwards;
            }

            &:last-child {
                margin-bottom: 12px;
            }

            &.focus {
                border: 1px solid rgba(var(--color), 1);
                box-shadow: rgba(59, 130, 246, 0.12) 0px 0px 0px 3px;
            }

            &.un-focus.full {
                border: 1px solid rgba(33, 33, 33, 0.12);

            }

            &.un-focus.empty {
                border: 1px dashed rgba(33, 33, 33, 0.12);

            }

            &.empty {
                color: rgba(33, 33, 33, 0.40);
            }

            &.add-input {
                border: 1px solid transparent;
                cursor: text;

                &:hover {
                    border-radius: 4px;
                    border: 1px solid #3B82F6;
                    box-shadow: 0px 0px 0px 3px rgba(59, 130, 246, 0.12);
                }
            }

            &:hover.un-focus.full {
                border: 1px solid rgba(33, 33, 33, 0.52);

            }

            &.first {
                .input {
                    &::placeholder {
                        color: #D1D5DB;
                        font-size: 14px;
                        font-weight: 500;
                        letter-spacing: 0.07px;
                        font-style: normal;
                    }
                }
            }

            &.error {
                border: 1px solid rgb(244, 67, 54) !important;
            }
        }
    }

    input[disabled] {
        pointer-events: none
    }
}