.list-tab-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: fit-content;
    padding: 10px;
    overflow-y: auto;

    .tab-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 12px 10px;
        width: 100%;
        flex-direction: column;
        box-sizing: border-box;
        border-radius: 8px;

        img {
            width: 25px;
        }

        .tab-name {
            font-family: "Manrope";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: rgba(33, 33, 33, 0.52);
            margin-top: 8px;
            text-align: center;
        }

        .disable {
            color: rgba(33, 33, 33, 0.3);
        }

        &:hover {
            background-color: rgba(33, 33, 33, 0.04);
        }
    }

    .active {
        background-color: rgba(59, 130, 246, 0.08) !important;

        .tab-name {
            color: rgba(59, 130, 246, 1);
        }
    }
}