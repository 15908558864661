.shape-bar-container {
    background: linear-gradient(0deg, #fff 0%, #fff 100%),
        lightgray 50% / cover no-repeat;
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    padding: 0 10px;
    left: 0;
    color: rgba(33, 33, 33, 1);

    .icon-line{
        height: 2px;
        width: 16px;
        background-color: #000000;
    }

    .button {
        height: 24px;
        // width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        // margin-right: 8px;
        box-sizing: border-box;
        position: relative;
        color: rgba(33, 33, 33, 1);
        padding: 2px 4px 2px 4px;
        background: #e2e2e2;
        cursor: pointer;
    }
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    background: #d3d3d3;
    outline: none;
    border: none;
    // opacity: 0.7;
    // -webkit-transition: .2s;
    // transition: opacity .2s;
}

.slider::-moz-range-track {
    height: 15px;
    background: #ccc;
    border-radius: 16px;
}

.popupBorder {
    position: absolute;
    height: fit-content;
    width: 200px;
    top: 30px;
    background: white;
    border-radius: 10px;
    padding: 8px 12px 8px 12px;
    box-shadow: 5px 5px 5px 5px #e2e2e2;
}

.divider {
    width: 1px;
    height: 18px;
    background: #e2e2e2;
}

.box-border {
    width: 30px;
    height: 30px;
    border: 1px solid #a4a4a4;
    border-radius: 4px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}