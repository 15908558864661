.sentence-list-container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &>* {
        font-family: "Poppins";
    }

    .content-box {
        border-radius: 6px;
        padding: 12px;
        gap: 8px;
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        // min-height: 196x;

        .instruction {
            position: absolute;
            font-family: "Nunito";
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 180%;
            color: rgba(33, 33, 33, 0.52);
            text-transform: capitalize;
            text-align: left;
            padding-right: 12px;
        }
    }

    .box-fitb {
        border: 1px solid rgba(33, 33, 33, 0.24);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px
    }

    .note-fitb {
        display: flex;
        padding: 4px;
        align-items: center;
        gap: 10px;
        background: #FEF3DF;
        color: rgba(33, 33, 33, 0.52);
        font-family: Nunito;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .title {
        align-self: flex-start;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #212121;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title-add-question {
            color: #3B82F6;
            font-family: Nunito;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-decoration-line: underline;
            cursor: pointer;
        }
    }
}

.bar-questions {
    .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
        background-color: rgba(217, 217, 217, 1);
        width: 3px;
        margin-left: 2px;
    }
}

.box-input-fitb {
    // align-items: center;
    display: flex;
    border-radius: 4px;
    border: 1px dashed rgba(33, 33, 33, 0.12);


    &:hover {
        border: 1px solid #3B82F6;
        box-shadow: 0px 0px 0px 3px rgba(59, 130, 246, 0.12);
    }

    .text-index {
        color: #212121;
        font-family: Nunito;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: 10px;
        margin-top: 10px;
    }

    .input-field {
        width: 100%;

        .MuiOutlinedInput-notchedOutline {
            border: none
        }

        .MuiInputBase-input {
            font-family: "Nunito";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #212121;

            &::placeholder {
                font-family: "Nunito";
                font-style: italic;
                font-weight: 300;
                font-size: 12px;
                // text-transform: capitalize;
                color: rgba(33, 33, 33, 0.52);
            }
        }

        .MuiOutlinedInput-root {
            padding: 10px 10px;
        }
    }
}

.box-input-fitb-focus {
    border: 1px solid #3B82F6;
    box-shadow: 0px 0px 0px 3px rgba(59, 130, 246, 0.12);
}