.entry-check-box {
    display: flex;
    padding: 8px;
    gap: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #f9fafb;
    .check-box-container {
        height: 36px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        width: 100%;
        background-color: white;
        border-radius: 8px;
        border: 1.5px solid lightgray;
        .title {
            color: var(--gray-800, #1f2937);
            font-family: Nunito;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
        }
        .check-box {
            height: 20px;
            width: fit-content;
        }
    }

    .MuiButtonBase-root,
    .MuiCheckbox-root {
        padding: 4px;
    }
}
