.tab-tinytap-page {
    .slide-body {
        iframe {
            width: 100%;
        }

        .audio {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        img {
            width: 100%;
        }
    }
}