.group-input-crossword {
    border: 1px solid rgba(33, 33, 33, 0.20);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    border-radius: 6px;

    .input-container {
        background: rgba(33, 33, 33, 0);
        height: fit-content;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        box-shadow: none;
    }
}

.tab-group-crossword {
    border-radius: 6px 6px 0 0;
    background: rgba(33, 33, 33, 0.08);
    display: flex;
    width: 100%;

    .MuiButtonBase-root {

        &:hover {
            background-color: transparent;
        }
    }

    .tab {
        border-radius: 6px;
        margin: 4px;
        background: transparent;
        color: #1F2937;
        font-family: Nunito;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.07px;
        text-transform: none;
        border: none;
        background-size: 200% 100%;

        &:first-child {
            background-image: linear-gradient(to right, transparent 50%, #FFF 50%);
            margin-right: 0px;

            &.active {
                animation: leftToRight 0.2s ease-in-out;
                background-position: right;
            }

            &.deactive {
                animation: rightToLeft 0.2s ease-in-out;
                background-position: left;
            }

        }

        &:nth-child(2) {
            background-image: linear-gradient(to right, #FFF 50%, transparent 50%);
            margin-left: 0px;

            &.active {
                animation: rightToLeft 0.2s ease-in-out;
                background-position: left;
            }

            &.deactive {
                animation: leftToRight 0.2s ease-in-out;
                background-position: right;
            }
        }

        &.active {
            color: #3B82F6;
        }

        &:hover {
            color: #3B82F6;
        }
    }

    @keyframes leftToRight {
        from {
            border-radius: 0;
            background-position: left;
        }

        to {
            background-position: right;
        }
    }

    @keyframes rightToLeft {
        from {
            border-radius: 0;
            background-position: right;
        }

        to {
            background-position: left;
        }
    }
}