.table-element-view {
    .cell-element {
        position: absolute;
        display: flex;
        align-items: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .background-table{
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
    }
}
