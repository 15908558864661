.manual-input-container {
    height: 260px;
    border: none;
    overflow: hidden;

    .input-word-container {
        border: 1px solid transparent;
        border-top-color: #F6F6F6;
        border-bottom-color: #F6F6F6;

        .input-word-inner-container {
            padding: 4px 10px 4px 4px;
            margin: 8px;
            border-radius: 4px;
            border: 1px solid transparent;
            transition: border-color 0.2s;
            position: relative;

            .icon-delete {
                opacity: 0;
                transition: opacity 0.2s;
                position: absolute;
                cursor: pointer;
                z-index: 1;
                top: 4px;
                right: 4px;
                padding: 0;
            }

            .input {
                border-radius: 4px;

                & .MuiOutlinedInput-root {
                    padding: 6px;
                    color: #475569;
                    font-family: Nunito;
                    font-size: 12px;
                    font-weight: 500;
                    font-style: normal;
                    line-height: normal;

                    fieldset {
                        border: 1px solid white;
                        transition: border-color 0.2s;
                    }

                    // &:hover fieldset {
                    //     border-color: rgba(71, 85, 105, 0.24),
                    // }

                    &.Mui-focused fieldset {
                        border-color: rgba(71, 85, 105, 0.24);

                    }
                }

                &.clue {
                    background-color: #F9FAFB;
                }
            }

            .clue-input {
                display: flex;
                align-items: center;
                margin-top: 4px;

                svg {
                    margin-right: 2px;
                }
            }
        }

        &:hover, &.click-from-right {
            .input-word-inner-container {
                border-color: rgba(33, 33, 33, 0.12);

                .icon-delete {
                    opacity: 1;
                }
            }
        }

        &:first-child {
            border-top-color: transparent;
        }

        &:last-child {
            border-bottom-color: transparent;
        }

        &.add-input {
            cursor: pointer;

            .input {
                & .MuiOutlinedInput-root {
                    cursor: pointer;

                    & .Mui-disabled {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .simplebar-track {
        width: 8px;
        background-color: #ffffff;
    }

    .simplebar-scrollbar {
        &::before {
            border-radius: 16px;
            background: #D9D9D9;
            max-width: 4px;
        }
    }
}