.tooltip-custom {
    position: relative;

    width: fit-content;
    // &:hover {
    //     .tooltip-customize-root {
    //         opacity: 1;
    //         visibility: visible;
    //     }
    // }

    .tooltip-customize-root {
        &.tooltip-left,
        &.tooltip-right {
            top: 50%;
            transform: translateY(-50%);
            &.tooltip-left {
                right: calc(100% + var(--distanceTooltip));
            }
            &.tooltip-right {
                left: calc(100% + var(--distanceTooltip));
            }
        }
        &.tooltip-top,
        &.tooltip-bottom {
            left: 50%;
            transform: translateX(-50%);
            &.tooltip-top {
                bottom: calc(100% + var(--distanceTooltip));
            }
            &.tooltip-bottom {
                top: calc(100% + var(--distanceTooltip));
            }
        }
    }
}

.tooltip-customize-root {
    --distanceTooltip: 10px;
    z-index: 9999;
    position: absolute;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    // transition: all ease-in-out 0.2s;
    width: max-content;
    font-weight: 500;
    font-size: 0.6875rem;

    .tooltip-content {
        padding: 4px 8px;
        width: fit-content;
        background-color: var(--backgroundColorToolTip);
        max-width: 300px;
        margin: 0 auto;
        word-wrap: break-word;
        border-radius: 4px;
        position: relative;
        transform-origin: center top;
        color: var(--colorToolTip);
        .tooltip-arrow {
            position: absolute;
            width: 0px;
            height: 0px;
            box-sizing: border-box;

            &.arrow-bottom {
                right: calc(50% - 10px);
                transform: translateX(-50%);
                bottom: 100%;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid var(--backgroundColorToolTip);
            }
            &.arrow-top {
                right: calc(50% - 10px);
                transform: translateX(-50%);
                top: 100%;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid var(--backgroundColorToolTip);
            }
            &.arrow-right {
                bottom: calc(50% - 10px);
                transform: translateY(-50%);
                right: 100%;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-right: 5px solid var(--backgroundColorToolTip);
            }
            &.arrow-left {
                bottom: calc(50% - 10px);
                transform: translateY(-50%);
                left: 100%;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-left: 5px solid var(--backgroundColorToolTip);
            }
        }
    }
}
