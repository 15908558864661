.btn-unlimited {
    height: 42px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #f4f8fb;
    background: #ffcf5c;
    box-shadow: 0px 1px 4px 0px rgba(33, 33, 33, 0.25);
    cursor: pointer;
    user-select: none;

    color: #212121;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.07px;
    text-transform: capitalize;

    .icon {
        width: 24px;
        height: 24px;
        margin-right: 12px;
    }
}

.comming-soon {
    padding: 8px;
    margin-top: 4px;
    border-radius: 8px;
    // margin: 16px -12px -16px;
    position: relative;
    // position: absolute;
    // top: 0;
    display: flex;
    align-items: center;
    gap: 20px;
    width: -webkit-fill-available;
    border-radius: 0px 0px 8px 8px;
    background: #fef7d1;
    // visibility: hidden;

    &.active {
        opacity: 0;
        animation: comingSoonAppear 0.3s ease forwards;
        visibility: visible;
    }
    &.hidden {
        animation: comingSoonHidden 0.3s ease forwards;
        opacity: 1;
    }

    @keyframes comingSoonAppear {
        0% {
            // height: 0;
            margin-top: -58px;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
            // height: 100%;
            margin-top: 4px;
        }
    }

    @keyframes comingSoonHidden {
        0% {
            opacity: 1;
            margin-top: 4px;
        }
        10% {
            opacity: 0;
        }
        100% {
            opacity: 0;
            margin-top: -58px;
            display: none;
        }
    }

    .content-cmsoon {
        .title {
            color: #755118;
            font-size: 14px;
            font-weight: 600;
        }

        .desc {
            color: #6b7280;
            font-size: 12px;
            font-weight: 400;

            .react {
                margin-left: 6px;
                width: 16px;
                height: 16px;
            }
        }
    }

    .close-btn {
        padding: 4px;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(-90deg);
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
    }
}
