.list-action-word-search {
    place-self: flex-start;
    position: relative;
    padding-top: 38px;
    .list-action-word-search-fixed {
        position: fixed;
        .list-action-content {
            position: absolute;
            left: 24px;
            gap: 12px;
            display: grid;
            align-items: center;
            justify-content: center;
        }
    }
}
.outside-container {
    &.word-search {
        display: flex;
        width: fit-content;
        margin: auto;
    }
}
// .custom-tooltip {
//     margin-bottom: 8px !important;
// }
