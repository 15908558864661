.image-grid {
    position: relative;
    width: 100%;
    height: 700px;

    .cell {
        position: absolute;
        cursor: pointer;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 0.5;
            border: 1px solid red;
          }
    }

    &__pick-color {
        position: absolute;
        top: 40px;
        left: -300px;
        z-index: 2;
        padding-bottom: 20px;
        background-color: white;
    }
}