.main-activities {
    padding: 16px 12px 12px 12px;
    height: fit-content;
    max-height: calc(100% - 52px - 48px); // height header: 72px, padding top + bootom: 24+24px
    display: grid;
    gap: 16px;

    @keyframes showView {
        0% {
            opacity: 1;
            height: 0;
        }

        100% {
            opacity: 1;
            height: var(--heightContentView);
        }
    }

    @keyframes hideView {
        0% {
            opacity: 1;
            height: var(--heightContentView);
        }

        50% {
            opacity: 1;
        }

        99% {}

        100% {
            height: 0;
            opacity: 0;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    // .back-header {
    //     ccolor: #212121;
    //     font-family: Nunito;
    //     font-size: 16px;
    //     font-style: normal;
    //     font-weight: 600;
    //     line-height: normal;
    // }
}