.contact-container {
    position: fixed;
    bottom: 36px;
    right: 36px;

    .FAQ-container {
        position: relative;
        margin-top: 7px;
    }

    .rp-bug-icon-container {
        width: 40px;
        height: 40px;
        position: relative;
        margin-bottom: 12px;

        .rp-bug-icon {
            width: 40px;
            height: 40px;
        }
    }
}

.MuiBox-root {
    border-radius: 8px;
    z-index: 10 !important;

    *::-webkit-scrollbar {
        width: 10px;
        background-color: rgba(33, 33, 33, 0.8);
    }

    *::-webkit-scrollbar-thumb {
        background: #d7d7d7;
        border-radius: 83px;
        height: 20px;
    }

    .faq-row-wrapper {
        position: relative;
        box-sizing: border-box;
        padding: 0 16px 16px 16px;
        width: 330px;
        background: #ffffff;
        border: 1px solid #000000;
        border-radius: 8px;
        min-height: 452px;
        overflow-y: auto;
        max-height: 600px;

        .faq-title {
            position: fixed;
            width: 296px;
            text-align: center;
            display: flex;
            justify-content: center;
            z-index: 10;
            height: fit-content;

            h2 {
                position: absolute;
                padding-top: 21px;
                padding-bottom: 8px;
                width: 100%;
                top: 0;
                background-color: white;
                border-bottom: 1px rgb(204, 204, 204) solid;
                font-size: 20px;
            }
        }

        .faq-body {
            margin-top: 60px;

            .faq-row {
                .row-title {
                    color: #009aff;
                    font-size: 14px;
                }

                .row-content {
                    margin-bottom: 8px;

                    .row-content-text {
                        font-family: "Inter", sans-serif !important;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 19px;
                        display: flex;
                        align-items: center;
                        color: #212121;
                    }
                }

                .icon-wrapper svg {
                    fill: #009aff !important;
                }
            }
        }
    }

    .popup-container-report-bug {
        width: 100%;
        min-height: 355px;
        border: 1px solid #000000;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        background-color: white;

        .popup-header {
            position: absolute;
            gap: 10px;
            top: 0;
            width: 330px;
            padding: 16px;
            box-sizing: border-box;

            background-color: #212121;
            border-radius: 8px 8px 0px 0px;
        }

        .popup-header span {
            font-family: "Nunito";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            display: flex;
            align-items: center;
            text-transform: capitalize;

            color: #ffffff;
        }

        .popup-body {
            margin-top: 59px;
            padding: 16px;
            flex: 1;

            .bug-description {
                position: relative;
                box-sizing: border-box;
                width: 100%;
                resize: none;
                height: 120px;
                margin-top: 4px;
                padding: 8px;
                max-height: 120px;
                margin-bottom: 16px;
                background: rgba(33, 33, 33, 0.04);
                border: 0.5px solid #212121;
                border-radius: 2px;
            }

            .image-input-container {
                display: inline-flex;
                align-items: center;
                margin: 4px 0 8px 0;
                width: 100%;
                height: 28px;

                .image-name {
                    color: black;
                    max-width: 198px;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    -webkit-line-clamp: 1;
                    line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                .label-screenshot-btn {
                    color: black;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 91px;
                    height: 28px;
                    margin-right: 8px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #ffffff !important;
                    background: rgba(90, 89, 89, 0.6);
                    box-shadow: 0px 4px 4px rgba(33, 33, 33, 0.04);
                    border-radius: 2px;
                    cursor: pointer;
                }
            }

            .image-container {
                box-sizing: border-box;
                position: relative;
                width: 91px;
                height: 28px;
                display: flex;
                align-items: center;
                background-color: rgba(33, 33, 33, 0.06);
                border-radius: 4px;
                padding: 0 16px 0 8px;

                .image-name {
                    font-family: "Nunito";
                    color: #221111;
                    flex: 1;
                    font-size: 12px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }

            #get-screenshot-btn {
                display: none;
            }

            .preview-img {
                width: 100%;
                position: relative;
                margin-bottom: 48px;
            }

            .remove-btn {
                position: absolute;
                bottom: 16.5px;
                left: 16px;
                width: 91px;
                height: 32px;
                border-radius: 3px;
                border: none;
                font-size: 15px;
                line-height: 24px;
                font-weight: 500;
                cursor: pointer;
            }

            .submit-bug-btn {
                position: absolute;
                bottom: 16px;
                right: 16px;
                border: none;
                width: 90px;
                height: 32px;
                color: #ffffff;
                background: rgba(var(--color), 1);
                box-shadow: 0px 4px 4px rgba(33, 33, 33, 0.04);
                border-radius: 3px;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                cursor: pointer;
            }

            .submit-bug-btn:hover {
                opacity: 0.8;
            }
        }

        .popup-body span {
            font-family: "Nunito";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            position: relative;
            display: block;
            align-items: center;
            color: #212121;
        }
    }

    .faq-popup-icon {
        width: 40px;
        height: 40px;
        position: fixed;
        right: 36px;
        bottom: 36px;
    }

    .rp-bug-popup-icon {
        width: 40px;
        height: 40px;
        position: fixed;
        right: 36px;
        bottom: 88px;
    }
}

.faq-wrapper {
    position: fixed;
    bottom: 32px;
    right: 108px;
}
.icon {
    cursor: pointer;

    .icon-text {
        width: 40px;
        height: 40px;
        background: rgba(var(--color), 1);
        border-radius: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: white;
        font-family: Nunito;
        font-style: normal;
    }
}
