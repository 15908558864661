.resource-math-container {
    position: relative;

    & > #math-element-id * {
        font-family: var(--font-family-math) !important;
        text-align: var(--align-math) !important;
    }

    .operator {
        position: absolute;
        left: 0px;
    }
}

.long-divide-operator {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    svg {
        overflow: hidden;
        transform: scale(1.2);
    }
}

.align-right {
    text-align: right;
}

.align-center {
    text-align: center;
}

.flex {
    display: flex;
}

.flex-1 {
    flex: 1;
}

.flex-end {
    align-items: flex-end;
}

.bb-black {
    border-bottom: 2px solid black;
}

.p-relative {
    position: relative;
}

.pr-14 {
    padding-right: 14px;
}

.pb-4 {
    padding-bottom: 4px;
}

.pt-4 {
    padding-top: 4px;
}

.text-transparent {
    visibility: hidden;
}