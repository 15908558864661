.claim-coins-user-info {
    display: flex;
    gap: 6px;
    align-items: center;
    .c-avt {
        width: 32px;
        height: 32px;
        img {
            border-radius: 50%;
        }
    }
    .c-name {
        font-size: 12px;
        font-weight: 700;
    }
}
