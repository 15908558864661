.font-type-name-tracing {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-top: 1px solid rgba(33, 33, 33, 0.20);
    border-left: 1px solid rgba(33, 33, 33, 0.20);
    border-right: 1px solid rgba(33, 33, 33, 0.20);
    border-bottom: 0.1px solid #FFFFFF;

    background: #FFFFFF;

    .btn-group {
        border-radius: 4px;
        background: #F3F4F6;
        display: flex;
        width: 100%;

        .btn {
            margin: 4px;
            background: transparent;
            color: #1F2937;
            font-family: Nunito;
            font-size: 14px;
            border-radius: 6px;
            font-weight: 500;
            line-height: 20px;

            letter-spacing: 0.07px;
            text-transform: none;
            border: none;
            background-size: 200% 100%;

            &:first-child {
                background-image: linear-gradient(to right, transparent 50%, #FFF 50%);
                margin-right: 0px;
                border-radius: 6px;

                &.active {
                    animation: leftToRight 0.2s ease-in-out;
                    background-position: right;
                }

                &.deactive {
                    animation: rightToLeft 0.2s ease-in-out;
                    background-position: left;
                }

            }

            &:nth-child(2) {
                background-image: linear-gradient(to right, #FFF 50%, transparent 50%);
                margin-left: 0px;
                border-radius: 6px;

                &.active {
                    animation: rightToLeft 0.2s ease-in-out;
                    background-position: left;
                }

                &.deactive {
                    animation: leftToRight 0.2s ease-in-out;
                    background-position: right;
                }
            }

            &:hover {
                color: rgba(var(--color), 1);

            }

            &.active {
                color: rgba(var(--color), 1);
            }
        }
    }

    .img-group {
        display: flex;
        justify-content: center;
        margin-top: 12px;
        margin-bottom: 12px;

        .img-btn {
            cursor: pointer;
            display: flex;
            margin-left: 10px;
            margin-right: 10px;
            width: 40px;
            height: 40px;

            border-radius: 4px;
            border: 1px solid rgba(33, 33, 33, 0.24);

            transition: border 0.3s, box-shadow 0.3s, border-radius 0.3s;


            &:hover {
                border: 1px solid rgba(var(--color), 1);
                box-shadow: rgba(59, 130, 246, 0.12) 0px 0px 0px 3px;
            }

            &.active {
                border-radius: 6px;
                border: 1px solid rgba(var(--color), 1);
                box-shadow: rgba(59, 130, 246, 0.12) 0px 0px 0px 3px;

                div {
                    border-radius: 6px 6px 6px 6px;
                    overflow: hidden;

                    svg {
                        width: 40px;
                        height: 40px;
                    }
                }
            }

        }
    }

    @keyframes leftToRight {
        from {
            background-position: left;
        }

        to {
            background-position: right;
        }
    }

    @keyframes rightToLeft {
        from {
            background-position: right;
        }

        to {
            background-position: left;
        }
    }
}