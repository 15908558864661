.header-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    .header-title {
        color: #475569;
        font-family: "Nunito";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .give-an-example {
        color: #3b82f6;
        font-family: Nunito;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
        cursor: pointer;
    }
}
.list-question-container {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: fit-content;
    max-height: 480px;
    padding-top: 8px;

    background: linear-gradient(0deg, #ffffff, #ffffff), rgba(33, 33, 33, 0.04);
    border: 1px solid rgba(33, 33, 33, 0.2);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 4px;
        background-color: #fafafa;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 16px;
        background: #d9d9d9;
        height: 20px;
    }
    .MuiInputBase-multiline {
        padding: 0;
        font-size: 14px;
    }
    .questions-wrapper {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        padding: 0 8px;
        .question-container {
            width: 100%;
            box-sizing: border-box;
            position: relative;
            cursor: url("../../../../assets/cursors/move.png"), auto;
            .layer {
                position: absolute;
                width: 100%;
                height: calc(100% + 12px);
                top: 0px;
                left: 0px;
                display: none;
                z-index: 20;
                background-color: white;
                border-radius: 6px;
            }
            .question-header {
                position: relative;
                width: 100%;
                height: fit-content;
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                min-height: 52px;
                padding: 8px;
                overflow-y: overlay;
                overflow-x: hidden;
                .question-number {
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    left: 8px;
                    top: 14px;
                    z-index: 2;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    user-select: none;
                    font-family: "Nunito";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    color: black;
                }
                .question-content {
                    position: sticky;
                    top: 2px;
                    left: 20px;
                    box-sizing: border-box;
                    resize: none;
                    background: #ffffff;
                    border: 1px solid rgba(33, 33, 33, 0.24);
                    z-index: 1;
                    border: none;
                    width: calc(100% - 24px);
                    border-radius: 4px;
                    padding: 4px 8px;

                    font-family: "Nunito";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 12px;
                    color: #212121;
                }
                .question-content:focus-visible {
                    outline: none;
                }
                .question-content::placeholder {
                    height: 12px;
                    font-family: "Nunito", sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 12px;
                    color: rgba(33, 33, 33, 0.4);
                }
            }
            .remove-icon {
                display: none;
                position: absolute;
                top: 0px;
                border: none;
                background-color: transparent;
                position: absolute;
                right: 0px;
                width: 20px;
                height: 20px;
                cursor: pointer;
                z-index: 1;
                &:hover {
                    svg {
                        path {
                            &:first-child {
                                fill: #f9586b;
                            }
                        }
                    }
                }
            }
            .question-header:hover .remove-icon {
                display: block;
            }
            .options-header {
                margin: 12px 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 4px;
                .option-title {
                    color: rgba(33, 33, 33, 0.52);
                    font-family: "Nunito";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    line-height: 16px;
                }
                .add-new-option {
                    position: relative;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    height: 32px;
                    border-radius: 6px;
                    cursor: pointer;
                    color: #3b82f6;
                    text-align: center;
                    font-family: "Nunito";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-decoration-line: underline;
                    text-transform: capitalize;
                }
            }
            &:hover {
                background-color: #f8f9fb;
            }
        }
        .question-container:hover .question-action {
            display: flex;
        }
        .question-detail {
            padding: 0 8px;
            .answer-option {
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                position: relative;
                .answer-box {
                    box-sizing: border-box;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    width: 35px;
                    height: 35px;
                    background: #ffffff;
                    margin-right: 8px;

                    font-family: "Nunito";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;

                    color: #212121;
                    user-select: none;
                    cursor: url("../../../../assets/cursors/pointer.png"), auto;
                }
                .answer-content {
                    position: relative;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    // padding: 4px 28px 4px 12px;
                    padding: 8px;
                    gap: 10px;
                    width: calc(100% - 40px);
                    min-height: 32px;
                    height: fit-content;
                    resize: none;

                    background: linear-gradient(0deg, #ffffff, #ffffff),
                        rgba(33, 33, 33, 0.04);
                    border: 1px solid rgba(33, 33, 33, 0.24);
                    border-radius: 6px;

                    font-family: "Nunito";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    color: #212121;
                    cursor: text;
                    .MuiInputBase-multiline {
                        font-size: 14px;
                    }
                }
                .answer-content::placeholder {
                    font-family: "Nunito";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;

                    color: rgba(33, 33, 33, 0.5);
                }
                .answer-content:focus-visible {
                    outline: none;
                }
                .answer-remove-icon {
                    display: none;
                    position: absolute;
                    top: -8px;
                    right: -8px;
                    width: 20px;
                    height: 20px;
                    cursor: pointer;

                    &:hover {
                        svg {
                            path {
                                &:first-child {
                                    fill: #f9586b;
                                }
                            }
                        }
                    }
                }
                &:hover {
                    .answer-remove-icon {
                        display: block;
                    }
                }
                .MuiInputBase-multiline {
                    padding: 0;
                    width: 100%;
                }
            }
            .tutorial {
                position: relative;
                left: -8px;
                width: calc(100% + 16px);
                background: #fef3df;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: start;
                .text {
                    color: rgba(33, 33, 33, 0.52);
                    margin-left: 12px;
                    font-family: "Nunito";
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 500;
                }
            }
            .number-col-container {
                margin-top: 16px;
                padding: 0 4px;
                .number-col-title {
                    height: 16px;
                    width: 100%;
                    font-family: "Nunito";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    color: #212121;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .number-of-col {
                        font-family: "Nunito" !important;
                        font-size: 12px;
                        color: #1f2937;
                    }
                }
                .number-col-wrapper {
                    position: relative;
                    width: 100%;
                    height: 22px;
                    font-family: "Nunito";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                    text-align: center;
                    color: #212121;
                }
                .number-col-slider {
                    .MuiSlider-thumb {
                        height: 16px;
                        width: 16px;
                        &:focus,
                        &:hover,
                        &.Mui-active,
                        &.Mui-focusVisible {
                            box-shadow: 0 0 0 4px rgba(58, 133, 137, 0.16);
                        }
                    }
                    .MuiSlider-thumb {
                        color: #3b82f6;
                    }
                    .MuiSlider-rail {
                        color: rgba(33, 33, 33, 0.12);
                    }
                    .MuiSlider-track {
                        color: #3b82f6;
                    }
                    .MuiSlider-active {
                        color: #3b82f6;
                    }
                }
            }
        }
    }
    .add-question-container {
        display: flex;
        height: 40px;
        padding: 6px;

        box-sizing: border-box;
        display: flex;
        justify-content: end;
        align-items: center;
        padding: 8px 12px;
        gap: 10px;

        position: sticky;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #f9fafb;
        z-index: 5;
        .add-new-question {
            display: flex;
            height: 28px;
            padding: 0px 10px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 6px;
            border: 1px solid rgba(33, 33, 33, 0.12);
            background: #fff;
            cursor: pointer;
            span {
                height: 16px;
                color: #212121;
                text-align: center;
                font-family: "Nunito";
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
        .add-new-question:hover {
            border: 1px solid rgb(179, 179, 179);
        }
    }
}
