.tab-template-page {
    h3 {
        font-size: 15px;
        font-weight: bold;
        text-align: left;
        margin-top: 20px;
    }

    img {
        width: 100%;
    }

    form > div {
        margin-bottom: 20px;

        label {
            display: block;
            margin-bottom: 0;
        }

        .template-original-image {
            margin-top: 20px;
        }

        .use-default {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                text-decoration: underline;
                font-size: 12px;
                cursor: pointer;
            }
        }
    }

    .btns {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 20px;
        margin-top: 20px;
    }
}