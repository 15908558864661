.end-check-box {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .entry-check-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        border: 1px solid var(--gray-200, #E5E7EB);
        padding: 6px 2px 6px 12px;
        /* Drop Shadow/sm */
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
        background: #ffffff;

        .MuiButtonBase-root,
        .MuiCheckbox-root {
            padding: 4px;
        }

        .title {

            color: #1F2937;
            font-family: Nunito;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

        }
    }
}