.align-vertical {
    position: absolute;
    height: 100%;
    border-left: 1px dashed blueviolet;
}

.align-horizontal {
    position: absolute;
    width: 100%;
    border-top: 1px dashed blueviolet;
}

.align-center-vertical {
    position: absolute;
    height: 100%;
    left: 50%;
    border-left: 1px solid blueviolet;
}

.align-center-horizontal {
    position: absolute;
    width: 100%;
    top: 50%;
    border-top: 1px solid blueviolet;
}

.divider-vertical {
    position: absolute;
    border-left: 1px solid blueviolet;
}

.divider-horizontal {
    position: absolute;
    border-top: 1px solid blueviolet;
}
.text-padding{
    position: absolute;
    background-color: blueviolet;
    padding: 2px 4px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 10px;
}