.handwritting-container {
    & .hide_hw {
        visibility: visible;
        animation: hideContent 0.4s forwards;
    }

    & .show_hw {
        animation: showBtn 1s forwards;
    }
}

@keyframes hideContent {
    from {
        opacity: 1;
        visibility: visible;
    }

    to {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes showBtn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}