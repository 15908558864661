.word-directions {
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-radius: 6px;
    border: 1px solid var(--gray-200, #E5E7EB);

    /* Drop Shadow/sm */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

    .title {
        color: #1F2937;
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        padding: 8px 8px 8px 12px;
        background: #ffffff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: 1px solid rgba(var(--color), 1);
        box-shadow: rgba(59, 130, 246, 0.12) 0px 0px 0px 3px;


    }

    .MuiInputBase-root,
    .MuiOutlinedInput-root,
    .MuiSelect-root {
        background: #ffffff;
        border-radius: 4px;
        border: rgba(33, 33, 33, 0.04);
        padding-right: 4px;

    }

    .MuiOutlinedInput-input {
        padding: 0px 0px 0px 12px;
    }


    .MuiIconButton-root {
        padding: 0px;
    }

    .MuiInputBase-root {
        height: 32px;
    }

    .MuiOutlinedInput-input {
        display: flex;
        align-items: center;

    }

    .MuiInputBase-colorPrimary .MuiOutlinedInput-notchedOutline {
        border-color: rgba(33, 33, 33, 0.04);
    }

    .form-input {

        &:hover fieldset {
            border: 1px solid rgba(var(--color), 1);
            box-shadow: rgba(59, 130, 246, 0.12) 0px 0px 0px 3px;
        }

        padding: 4px;
    }
}