.tab-content-container {
    position: relative;
    height: 100%;
    box-sizing: border-box;
    width: 100%;

    .cluster-back-activity {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        padding: 16px 0 0 16px;

        .back-header {
            color: #212121;
            font-family: Nunito;
            font-size: 16px;
            font-style: normal;
            font-weight: 650;
            line-height: normal;
        }
    }

    .left-word-search {
        border: none !important;
    }

    .bar-questions {
        .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
            background-color: rgb(153, 153, 153);
            width: 6px;
            margin-left: 0px;
        }
    }

    .main-left-content {
        .button-upload {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 20px;

            .button-mui {
                width: 80%;
                max-width: 400px;
                text-transform: none;
                font-weight: 700;
            }
        }

        .loading-container {
            margin-top: 40px;
            text-align: center;
        }

        .list-sub-content {
            margin-top: 16px;

            .header-sub-content {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .title-sub-content {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: normal;
                    color: #212121;
                }

                .view-all-button {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: normal;
                    color: #3b82f6;
                    cursor: pointer;
                }
            }
        }
    }

    .simplebar-track.simplebar-horizontal {
        display: none;
    }

    .scroll-bar {
        z-index: 2;
    }

    .list-resource-item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 16px;
        position: relative;
        align-items: center;

        .resource-item {
            cursor: pointer;
            width: 30%;
            margin-bottom: 20px;

            &.resource-frame {
                width: 45%;
            }

            .img-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                position: relative;

                img {
                    // height: 100px;
                    width: auto;
                    max-width: 100%;

                    &.frame {
                        width: 300px;
                    }
                }

                svg {
                    height: 100px;
                    width: auto;
                    max-width: 100%;
                }

                .icon-delete {
                    position: absolute;
                    width: 40px;
                    right: -10px;
                    top: -50px;
                }
            }

            .resource-name {
                font-size: 12px;
                text-align: center;
            }

            &.resource-component {
                position: relative;

                .img-container {
                    img {
                        max-height: 100px;
                    }
                }

                .remove-resource-icon {
                    display: none;
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    right: 0;
                }

                &:hover .remove-resource-icon {
                    display: inline-block;
                }
            }
        }

        .template-resource-item {
            img {
                width: auto;
                max-width: 100%;
            }

            svg {
                width: auto;
                max-width: 100%;
            }
        }

        .arrow-container {
            position: absolute;

            z-index: 2;

            // display: none;
            .circle-arrow {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                top: 50%;
                font-size: 0;
                line-height: 0;
                transform: translate(0, -50%);
                cursor: pointer;
                background: rgba(255, 255, 255, 0.8);
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
                display: flex !important;
                align-items: center;
                justify-self: center;

                img {
                    margin: auto;
                    z-index: 2;
                }
            }
        }

        .next-arrow {
            right: 0px;
        }

        .prev-arrow {
            left: 0px;
        }
    }

    .text-no-result {
        margin-top: 32px;
        color: #272728;
        display: flex;
        font-size: 16px;
        letter-spacing: 0.02em;
        line-height: 26px;
        padding: 8px;
        text-align: center;
    }

    .no-data {
        text-align: center;
        color: #212121;
        font-size: 24px;
        margin-top: 40px;
    }

    .close-button {
        cursor: pointer;
        position: absolute;
        right: -10px;
        top: 40%;
        z-index: 1;
    }

    .simplebar-content-wrapper {
        outline-color: transparent !important;
    }

    .top-back-button {
        display: flex;
        align-items: center;
        cursor: pointer;

        .back-title {
            font-weight: 700;
            font-size: 18px;
            line-height: 30px;
            color: #272728;
        }
    }

    h3 {
        font-size: 20px;
        font-weight: bold;
        margin-top: 10px;
    }

    .upload-btn {
        h4 {
            font-size: 14px;
            font-weight: bold;
        }
    }
}