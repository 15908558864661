.checkbox-option {
    display: flex;
    align-items: center;
    justify-content: start;
    user-select: none;
    cursor: pointer;
    gap: 8px;
    text-decoration: underline;
    .checkbox {
        width: 24px;
        height: 24px;
    }

    .text {
        color: #21212185;
        padding: 0;
        font-size: 12px;
        font-weight: 600;
        white-space: nowrap;
        position: relative;
    }
}
