.stroke-color {
    height: 40px;
    width: 40px;
    position: relative;

    .color-span {
        height: 100%;
        display: block;
        margin-bottom: 10px;
    }
}