.content-element {
    object-fit: fill;
    display: block;
    height: 100%;
    pointer-events: none;
    position: absolute;
    width: 100%;
}

#new-hover-element:hover {
    outline: 1px solid #8b3dff;
    box-shadow: 0 0 0 1px #8b3dff, inset 0 0 0 1px #8b3dff; 
}