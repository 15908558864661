.stroke-entry {
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    border: 1px solid rgba(33, 33, 33, 0.08);
    padding: 8px 8px 8px 12px;
    align-items: center;
    background: #FFF;

    .title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #1F2937
    }


    .stroke-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        padding: 2px 4px 2px 8px;
        border-radius: 4px;
        background: rgba(33, 33, 33, 0.04);
        width: 118px;
        cursor: pointer;

        .select-text {
            color: #000;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Nunito;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            text-transform: capitalize;
            // width: 38px;
        }

        icon-down-stroke-popup {
            // display: flex;
            // align-items: center;
            // justify-content: center;
            width: 16px;
            height: 16px;
        }
    }
}

.dopdown-container-stroke-style {
    .popup {
        display: flex;
        flex-direction: column;
        padding: 8px;
        gap: 8px;

        .menu-stroke-style-item {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 2px 8px;
            border-radius: 4px;
            justify-content: space-between;

            &:hover {
                background: rgba(33, 33, 33, 0.08)
            }

            cursor: pointer;

            .left-item {
                display: flex;
                align-items: center;
                gap: 4px;

                .done-icon {
                    width: 24px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                }
            }
            .text{
                color: #000;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Nunito;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                text-transform: capitalize;
            }

        }



        .selected {
            background: rgba(59, 130, 246, 0.08);
        }
    }

}

.icon-down-stroke-popup {
    font-size: 14px;
}

.MuiPaper-root {
    .menu-stroke-style-item {
        display: flex;
        gap: 10px;

        span {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
        }
    }
}