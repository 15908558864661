.table-font-family-list {
    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    h5 {
        font-size: 18px;
    }
}