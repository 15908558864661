.blank-collection {
    background: #fff;
    border: 1.5px dashed rgba(var(--color), 1);
    border-radius: 12px;
    width: 100%;
    height: auto;
    aspect-ratio: 0.82;
    margin-bottom: 16px;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    a {
        text-decoration: none;
    }
    p {
        margin: 0;
    }
    .blank-collection-content {
        text-align: center;
    }
    svg {
        path {
            fill: rgba(var(--color), 1);
        }
    }
    .text {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: rgba(var(--color), 1);
        padding-bottom: 46px;
        padding-top: 28px;
    }
}
