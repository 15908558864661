:root{
  --color-border-resize: #8b3dff
}
.resize-container {
  align-items: center;
  height: 32px;
  justify-content: center;
  position: absolute;
  width: 32px;
  display: flex;
  pointer-events: none;
  &:hover {
    .button-resize {
      background-color: var(--color-border-resize);
      color: #fff;
    }
  }
  .circle-cursor {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    pointer-events: auto;
    position: absolute;
  }
  .border-cursor {
    pointer-events: auto;
    position: absolute;
  }
  .circle {
    border-radius: 50%;
    color: #0d1216;
    height: 12px;
    width: 12px;
  }
  .border {
    border-radius: 3px;
  }
  .button-resize {
    align-items: center;
    background-color: #fff;
    box-shadow: 0 0 4px 1px rgba(57, 76, 96, 0.15),
      0 0 0 1px rgba(43, 59, 74, 0.3);
    display: flex;
    justify-content: center;
    position: absolute;
    pointer-events: none;
    transition: color 0.1s linear, background-color 0.1s linear;
  }
}
.corner-top-left,
.corner-bottom-right {
  &:hover {
    .circle-cursor {
      cursor: -webkit-image-set(
            url(/assets/cursors/7ea01757f820a9fb828312dcf38cb746.webp) 1x,
            url(/assets/cursors/2c4ec45151de402865dffaaa087ded3c.webp) 2x
          )
          12 12,
        auto;
    }
  }
}
.corner-bottom-left,
.corner-top-right {
  &:hover {
    .circle-cursor {
      cursor: -webkit-image-set(
            url(/assets/cursors/d78cdce65d153748ffd0fb1a5573ac75.webp) 1x,
            url(/assets/cursors/ce13b386dbba73815423332724d3030a.webp) 2x
          )
          12 12,
        auto;
    }
  }
}
.border-bottom,
.border-top {
  width: 100%;
  &:hover {
    .border-cursor {
      cursor: -webkit-image-set(
            url(/assets/cursors/5e315937d3456710f9684f89c7860ea8.webp) 1x,
            url(/assets/cursors/a3609c7d7315d7301c3832d7e76e7974.webp) 2x
          )
          12 12,
        auto;
    }
  }
  .border-cursor {
    clip-path: inset(0px 8px);
    height: 16px;
    width: 100%;
  }
  .border {
    height: 6px;
    width: 18px;
  }
}
.border-left,
.border-right {
  height: 100%;
  flex-direction: column;
  &:hover {
    .border-cursor {
      cursor: -webkit-image-set(
            url(/assets/cursors/159a13980e4a0d0a470a49f8d35eb5a6.webp) 1x,
            url(/assets/cursors/4ecfddb1ae830056cfa9144f81c83295.webp) 2x
          )
          12 12,
        auto;
    }
  }
  .border-cursor {
    clip-path: inset(8px 0px);
    height: 100%;
    width: 16px;
  }
  .border {
    height: 18px;
    width: 6px;
  }
}
