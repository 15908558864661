.item-container {
    position: relative;
    padding-left: 6px;
    .show-keys,
    .show-important {
        position: absolute;
        top: 2px;
        left: 0;
        height: 26px;
        width: 26px;
        border-radius: 50%;
        border: 2px solid red;
    }
}
