.word-scramble-btn-group {
    border-radius: 8px 8px 0 0;
    background: #F3F4F6;
    display: flex;
    border: 1px solid rgba(33, 33, 33, 0.12);
    border-bottom: none;

    .btn {
        margin: 4px;
        background: transparent;
        color: #1F2937;
        font-family: Nunito;
        font-size: 14px;
        letter-spacing: 0.07px;
        text-transform: none;
        border: none;
        background-size: 200% 100%;
        border-radius: 6px;
        font-weight: 500;
        line-height: 20px;

        &:first-child {
            background-image: linear-gradient(to right, transparent 50%, #FFF 50%);
            margin-right: 0px;
            border-radius: 6px;

            &.active {
                animation: leftToRight 0.2s ease-in-out;
                background-position: right;
            }

            &.disable {
                animation: rightToLeft 0.2s ease-in-out;
                background-position: left;
            }

        }

        &:nth-child(2) {
            background-image: linear-gradient(to right, #FFF 50%, transparent 50%);
            margin-left: 0px;
            border-radius: 6px;

            &.active {
                animation: rightToLeft 0.2s ease-in-out;
                background-position: left;
            }

            &.disable {
                animation: leftToRight 0.2s ease-in-out;
                background-position: right;
            }
        }

        &:hover {
            color: rgba(var(--color), 1);

        }

        &.active {
            color: rgba(var(--color), 1);
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
        }
    }

    @keyframes leftToRight {
        from {
            background-position: left;
        }

        to {
            background-position: right;
        }
    }

    @keyframes rightToLeft {
        from {
            background-position: right;
        }

        to {
            background-position: left;
        }
    }
}