.search-bar {
    background: #f3f3f3;
    border: 1px solid #f3f3f3;
    border-radius: 6px;
    font-family: Nunito !important;
    padding: 5px 10px;
    // margin: 16px;

    input,
    input::placeholder {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }
    &.project {
        margin-right: 2px;
    }
    &:focus-within {
        border: 1px solid #3b82f6;
    }
    .MuiFormControl-root {
        width: 100%;
    }
    .icon-filter {
        .MuiIconButton-root {
            border-radius: 4px;
        }
        &:hover {
            border-radius: 4px;
            background: rgba(33, 33, 33, 0.08);
        }
    }

    .active-icon-filter {
        border-radius: 4px;
        background: rgba(59, 130, 246, 0.08);
    }
}
