.input-crossword {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .cluster-title-give {
        display: flex;
        justify-content: space-between;

        .title {

            color: #1F2937;
            font-family: Nunito;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .give-an-example {
            cursor: pointer !important;
            color: #3B82F6;
            font-family: Nunito;
            font-size: 12px;
            font-weight: 500;
            text-decoration-line: underline;
            bottom: 0;
            user-select: none;
        }
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: 1px solid rgba(var(--color), 1);
        box-shadow: rgba(59, 130, 246, 0.12) 0px 0px 0px 3px;
    }

    .multi-text {
        &:hover fieldset {
            border: 1px solid rgba(var(--color), 1);
        }
    }

    .auto-complete-input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        position: relative;

        .icon-search {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 6px;
            top: 3px;
            background: #f3f3f3;
            padding: 7px;
            border-radius: 6px;
            pointer-events: none;
            z-index: 3;
        }

        &:hover fieldset {
            // border: 1px solid rgba(var(--color), 1);
            box-shadow: rgba(59, 130, 246, 0.12) 0px 0px 0px 3px;
        }

        .MuiOutlinedInput-root {
            padding: unset;
        }

        input {

            padding: 0px 12px 0px 12px !important;



            font-family: Nunito;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;

        }

        .MuiInputBase-root {
            border-radius: 6px;
            // padding: 12px;
            height: 38px;
            background: #F3F3F3;
            // overflow: hidden;
        }

        .text-field {
            &:hover fieldset {
                border: 1px solid rgba(var(--color), 1);
            }

            fieldset {
                border: none;
            }
        }

        .text-field-warning {
            fieldset {
                border: 1px solid #FF2341;
            }
        }
    }
}