.math-container {
    border-radius: 6px;
    background: #F9FAFB;
    user-select: none;

    .title {
        color: #1F2937;
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        flex: 1;
    }

    .border {
        border-radius: 6px;
        border: 1px solid  #E5E7EB;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
        overflow: hidden;
    }
}