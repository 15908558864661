.word-bank {
    border-radius: 6px;
    border: 1px solid #000;
    .word-bank-content {
        display: flex;
        padding: 12px 18px;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        font-size: 24px;
    }
    // width: fit-content;
    // span {
    //     text-align: center;
    //     font-size: 16px;
    //     font-family: Nunito;
    //     text-transform: capitalize;
    // }
}
