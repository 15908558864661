.zoom-btn-container {
    box-sizing: unset;
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    width: 88px;
    height: 32px;
    border-radius: 6px;
    background: rgba(33, 33, 33, 0.06);
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid #e7e7e7;
    transition: 0.3s border;

    &:hover {
        border: 1px solid #89898A;
    }
    .value {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-left: 16px;
        margin-right: 1px;
        font-weight: bold;
        padding: 5px 0px;
    }
    input {
        background: transparent;
        border: transparent;
        outline: transparent;
        font-family: "Poppins";
        font-style: normal;
        font-weight: inherit;
        line-height: 24px;
        margin-left: 16px;
        margin-right: 5px;
        font-weight: bold;
        padding: 5px 0px;
        width: 100%;
    }
    span {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
    .expand-btn-container {
        svg {
            width: 9px;
            height: 9px;
        }
        padding: 0 10px;
    }

    .zoom-dropdown {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0px;
        z-index: 8;
        border-radius: 4px;
        box-shadow: 0 0 20px rgba(33, 33, 33, 0.08);
        background-color: #ffffff;
        .zoom-value-item {
            width: 100%;
            display: flex;
            font-size: 14px;
            justify-content: center;
            padding: 8px 0px;
            &:hover {
                background-color: rgba(64, 87, 109, 0.07);
            }
        }
    }
}
