.default-text-item {
    text-wrap: nowrap;
    background-color: rgba(33, 33, 33, 0.04);
    margin-bottom: 8px;
    cursor: pointer;
    .text-content {
        padding: 16px;
        width: fit-content;
    }
}
