.sentence {
    // position: relative;

    .sentence-text {
        border-bottom: 1px var(--strokeType) var(--color-style-anwser);
        &.isSafari {
            border: 1px var(--strokeType) var(--color-style-anwser);
            border-top-width: 0px;
            border-right-width: 0px;
            border-left-width: 0px;
        }

        &.hide {
            color: transparent;
        }
    }

}