#save-button {
    padding: 8px 15px !important;
    margin-bottom: 0px !important;
    position: relative;

    .img-thumbnail {
        position: absolute;
        top: 50px;
        right: 0;
        width: 200px;
        z-index: 1000;
        cursor: pointer;
    }
}

#save-button-then-map-ids {
    display: none;
}

.final-result-modal {
    .modal-body {
        padding: 0;

        img {
            padding: 0;
        }
    }
}