.grid-worksheets-project {
    // min-height: calc(100vh - 52px);
    min-height: 80vh;

    .grid-worksheets {
        .home-grid-column {
            gap: 12px;
            padding-top: 12px !important;
            justify-content: space-between !important;
            .grid-column-collection {
                gap: 12px;
                .data-grid-collection {
                    margin-bottom: 0;
                    .data-grid-item {
                        .tick-icon,
                        .icon-interactive {
                            display: none;
                            opacity: 0;
                        }
                    }
                }
                .collection-name {
                    font-size: 14px;
                    font-weight: 800;
                    line-height: 19px;
                    text-shadow: 0px 4px 8px 0px #21212129;
                    color: #212121;
                }
                .blank-collection {
                    .blank-collection-content {
                        .text {
                            padding-bottom: 28px;
                        }
                    }
                }
            }
        }
    }
    .item-grid-worksheets-project {
        padding-top: 16px;
        .date-and-drop-down {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .date {
                font-size: 14px;
                font-weight: 500;
                color: #475569;
                line-height: 19px;
            }
        }
    }
    .breadcrumbs-collection-name {
        margin-left: 0;
        padding-left: 0;
        padding-top: 18px;
        p {
            margin: 0;
        }
        .collection-name {
            p {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
            }
            &.active {
                p {
                    font-size: 14px;
                    font-weight: 700;
                }
            }
        }
    }
}
