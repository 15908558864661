.word-scramble-style-container {
    padding: 16px 8px 12px;
    background: #F9FAFB;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .button-group-container {
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
        .button-group {
            display: flex;
            gap: 8px;

            .item {
                height: 24px;
                width: 24px;
                border-radius: 4px;
                cursor: pointer;
                border: 1px solid transparent;
                background: rgba(33, 33, 33, 0.02);
                transition: all 0.15s;

                &:hover {
                    border: 1px solid rgba(61, 132, 245, 0.6);
                    background: rgba(33, 33, 33, 0.03);
                }

                &.active {
                    border: 1px solid #3B82F6;
                    background: rgba(33, 33, 33, 0.05);
                }
            }
        }
    }

    .dropdown-container {
        border-radius: 6px;
        background: #F9FAFB;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
        padding: 6px 6px 6px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            color: #212121;
            font-size: 14px;
            font-weight: 500;
            text-transform: capitalize;
        }

        .select {
            background: #fff;
            .MuiSelect-select {
                padding: 2px 4px;
                width: fit-content;
                border-radius: 4px;
                background: rgba(33, 33, 33, 0.04);

                .left {
                    min-width: 16px;
                    display: flex;
                    margin: 0 8px;
                    margin-right: 30px;

                    .tick-icon {
                        display: none;
                    }

                    svg {
                        min-width: 43px;
                        max-width: 80px;
                    }

                    &.no-name {
                        svg {
                            height: 22px;
                            width: fit-content;
                        }
                    }
                }

                .right {
                    display: none;
                }

            }

            .MuiOutlinedInput-notchedOutline {
                border: 0;
                border-radius: 6px;
            }
        }
    }
}

.word-scramble-dropdown-item {
    padding: 2px 4px !important;
    margin-bottom: 4px !important;
    border-radius: 4px !important;
    &:last-child {
        margin-bottom: 0 !important;
    }

    .left {
        min-width: 50px;
        display: flex;
        align-items: center;
        margin-right: 8px;

        .tick-icon {
            margin-right: 6px;
            margin-left: 6px;
            visibility: hidden;
        }

        svg {
            min-width: 48px;
            max-width: 80px;
            min-height: 24px;
        }

        &.no-name {
            margin-right: 0px;
        }
    }

    .right {
        margin-left: 8px;

        text-transform: capitalize;
        width: 100%;
        color: #000;
        text-align: right;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 200% */
    }

    &:hover {
        background: #F9FAFB;
    }

    &.Mui-selected {
        background: rgba(59, 130, 246, 0.08);
        .left {
            .tick-icon {
                visibility: unset;
            }
        }
    }
}