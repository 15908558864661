.resize-table-element {
    .icon_add_cells {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.7s;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    &.active-rize-col {
        cursor: -webkit-image-set(url(/assets/cursors/159a13980e4a0d0a470a49f8d35eb5a6.webp) 1x,
                url(/assets/cursors/4ecfddb1ae830056cfa9144f81c83295.webp) 2x) 16 16,
            auto;
    }

    &.active-rize-row {
        cursor: -webkit-image-set(url(/assets/cursors/5e315937d3456710f9684f89c7860ea8.webp) 1x,
                url(/assets/cursors/a3609c7d7315d7301c3832d7e76e7974.webp) 2x) 16 16,
            auto;
    }
}

.divider-resize-table-row {
    position: absolute;
    display: flex;
    align-items: center;

    &:hover {
        cursor: -webkit-image-set(url(/assets/cursors/5e315937d3456710f9684f89c7860ea8.webp) 1x,
                url(/assets/cursors/a3609c7d7315d7301c3832d7e76e7974.webp) 2x) 16 16,
            auto;
    }

    .divider {
        width: 100%;
        height: 2px;
    }
}

.divider-resize-table-column {
    position: absolute;
    display: flex;
    justify-content: center;

    &:hover {
        cursor: -webkit-image-set(url(/assets/cursors/159a13980e4a0d0a470a49f8d35eb5a6.webp) 1x,
                url(/assets/cursors/4ecfddb1ae830056cfa9144f81c83295.webp) 2x) 16 16,
            auto;
    }

    .divider {
        width: 2px;
        height: 100%;
    }
}