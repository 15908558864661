.popup-delete-worksheets {
    .MuiPaper-root {
        @media screen and (min-width: 1025px) {
            background: rgba(255, 255, 255, 1);
            border-radius: 20px;
            padding: 0 16px;
        }

        .popup-delete-worksheets-title {
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
            text-align: center;
            color: rgba(39, 39, 40, 1);
            padding: 32px 0 16px;
            @media screen and (max-width: 1024px) {
                padding: 50px 0 16px;
            }
        }
        .MuiDialogContent-root {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            padding: 12px 160px 24px;
            color: rgba(33, 33, 33, 0.52);
            @media screen and (max-width: 1024px) {
                padding: 0px 0px 32px;
            }
        }
        .MuiDialogActions-root {
            padding: 0 0 16px 0;
            @media screen and (max-width: 1024px) {
                padding: 8px;
            }
            .popup-delete-worksheets-actions {
                display: flex;
                justify-content: space-around;
                width: 100%;
                gap: 8px;
                .actions-button {
                    padding: 12px 0;
                    width: 100%;
                    text-align: center;
                    border: 1px solid rgba(231, 231, 232, 1);
                    border-radius: 12px;
                    cursor: pointer;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 26px;
                    color: rgba(62, 62, 64, 1);
                    &.button-del {
                        background: rgba(255, 36, 66, 1);
                        color: rgba(255, 255, 255, 1);
                    }
                }
            }
        }
        .content {
            @media screen and (max-width: 1024px) {
                background-color: rgba(255, 255, 255, 1);
                border-radius: 20px 20px 0px 0px;
            }
        }
    }
}
