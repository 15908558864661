.resource-sentence-scramble {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .border-scramble {
        padding: 0px 8px;
        font-size: inherit;
        border: 2px solid gray;
        border-radius: 6px;
    }
    .text-container {
        display: flex;

        .prefix {
            margin-right: 4px;
        }
        .shuffle {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
        }
    }

    .type-container {
        .normal-input {
            .input {
                width: 100%;
                position: absolute;
                resize: none;
                font-size: inherit;
                line-height: var(--line-height);
                border: none;
                outline: none;
                padding: 0;
                z-index: 2;
                background: transparent;
                overflow: hidden;
                text-wrap: wrap;
            }
            
            .line-item {
                position: relative;
                border-top: 2px solid transparent;
                border-bottom: 2px solid transparent;

                &:last-child {
                    margin-bottom: 0!important;
                }
                
                &::after {
                    z-index: 0;
                    content: "";
                    display: var(--after-display) !important;
                    position: absolute;
                    width: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    border: 1px dashed #b3b3b3;
                }
            }
        }
    }

}