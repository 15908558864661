.color-button {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid rgba(232, 232, 232, 1)
}

.list-colors-button {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid rgba(232, 232, 232, 1);
    display: flex;
    flex-direction: row;
    overflow: hidden;

    .item-color {
        height: 100%;
    }

}

.chose-color-container {
    display: flex;
    margin-left: 4px;

    .chose-color-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .more-color-button {
            width: 25px;
            height: 25px;
            margin-left: -5px;
        }

        .more-color {
            font-size: 12px;
            font-weight: bold;
            color: white;
            position: absolute;
            top: 4px;
            right: 4px;
            user-select: none;
            cursor: pointer;
        }
    }
}

.color-panel {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 8px;
    position: absolute;
    left: 0px;
    padding: 4px;
    border-radius: 8px;
    box-shadow: 0 0 0 1px rgba(64, 87, 109, 0.07),
        0 2px 12px rgba(53, 71, 90, 0.2);
    padding-left: 8px;

    .color-button {
        width: 25px;
        height: 25px;
        margin-right: 5px;
        border-radius: 100%;
        cursor: pointer;
        position: relative;
    }
}

.box-change-color-shape {
    display: flex;
    // flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    padding-top: 16px;
    padding-bottom: 12px;
    justify-content: center;

    .button {
        display: inline-flex;
        height: 32px;
        padding: 0px 12px;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: 0.5px solid transparent;
        flex-shrink: 0;
    }

    .active {
        border-radius: 6px;
        border: 0.5px solid rgba(0, 0, 0, 0.04);
        background: #FFF;
        box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.04), 0px 3px 8px 0px rgba(0, 0, 0, 0.12);
    }

    .text {
        color: #000;
        text-align: center;
        font-family: Nunito;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.08px;
    }
}