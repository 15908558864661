.item-collection-thumbnail {
    cursor: pointer;
    position: relative;
    p {
        margin: 0;
    }
    &__content {
        width: 100%;
        position: relative;
        aspect-ratio: 236/300;
        background-image: linear-gradient(to right, transparent, #b2996785);
        border-radius: 8px;
        overflow: hidden;
        img {
            width: 100%;
            // background-color: #b2996785;
            height: 100%;
            object-fit: cover;
        }
        &__text {
            position: absolute;
            left: 0;
            top: 0;
            text-align: center;
            right: 0;
            padding: 48px 10px 0 32px;
            &.scale-item {
                padding: 48px 10px 0 20px;
            }
            .collection-name {
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.04em;
                color: #212121;
                text-shadow: 0px 4px 8px rgba(33, 33, 33, 0.16);
                word-break: break-all;
            }
            .nb-worksheets {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                letter-spacing: 0.04em;
                color: #212121;
            }
        }

        &__action {
            position: absolute;
            top: 4px;
            right: 4px;
            filter: drop-shadow(0px 4px 4px rgba(33, 33, 33, 0.16));
        }
    }
}
.popup-action-collection {
    background-color: #fff;
    position: absolute;
    padding: 12px 16px;
    display: grid;
    gap: 12px;
    width: fit-content;
    z-index: 9;
    border: 1px solid #d4d7ea;
    border-radius: 20px;
    text-align: start;
    p {
        margin: 0;
    }
    .item-action {
        cursor: pointer;
        display: flex;
        align-items: center;
        span {
            font-size: 14px;
            line-height: 19px;
            color: #212121;
            padding-left: 8px;
        }
    }
    .split {
        background: #cdd2eb;
        border-radius: 1px;
        width: 160px;
        height: 1px;
    }
}
