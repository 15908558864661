body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

:root {
    --main-color: rgba(59, 130, 246, 1);
    --color: 59, 130, 246;

    --backgroundColorToolTip: rgba(97, 97, 97, 0.92);
    --colorToolTip: #fff;
}

#root {
    height: 100vh;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.hide-important {
    color: transparent !important
}

.show-important {
    color: inherit !important
}

.hide-important-shape {
    opacity: 0 !important;
}

.show-important-shape {
    opacity: 1 !important
}

.full-size {
    width: 100%;
    height: 100%;
}

.can-click {
    pointer-events: auto;
}

.not-click {
    pointer-events: none;
}

.custom-marker::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    background-color: #ff0000;
}

.app_tool *:not(.canvas-text):not(.font-family-item):not(.input-font-family):not([id^="text-area-"]):not([id^="student_infos"]):not(.content-sentence):not(.MuiSelect-outlined) {
    font-family: "Nunito";
}

input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@font-face {
    font-family: "SVN_Bango";
    src: url("https://idea-assets.s3.amazonaws.com/fonts/SVN_Bango.otf") format("otf");
}

@font-face {
    font-family: "Valentine";
    src: url("https://idea-assets.s3.amazonaws.com/fonts/Valentine.otf") format("otf");
}

@font-face {
    font-family: "UTMDuepuntozero";
    src: url("https://idea-assets.s3.amazonaws.com/fonts/UTMDuepuntozero.ttf") format("otf");
}

@font-face {
    font-family: "newyear";
    src: url("https://idea-assets.s3.amazonaws.com/fonts/newyear.otf") format("otf");
}

@font-face {
    font-family: "MoonChildSans";
    src: url("https://idea-assets.s3.amazonaws.com/fonts/MoonChildSans.otf")
}

@font-face {
    font-family: "BerlinSansFBDemiBold";
    src: url("https://alpha-icons.s3.amazonaws.com/fonts/BerlinSansFBDemiBold.ttf") format('ttf')
}

@font-face {
    font-family: 'Berlin Sans FB Demi Bold';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/BerlinSansFBDemiBold.ttf')
}

@font-face {
    font-family: 'CHIPFN__';
    src: url('https://idea-assets.s3.amazonaws.com/fonts/CHIPFN__.TTF')
}

@font-face {
    font-family: 'COMIC';
    src: url('https://idea-assets.s3.amazonaws.com/fonts/COMIC.TTF')
}

@font-face {
    font-family: 'comicz';
    src: url('https://idea-assets.s3.amazonaws.com/fonts/comicz.ttf')
}

@font-face {
    font-family: 'ComicNeueSansID';
    src: url('https://idea-assets.s3.amazonaws.com/fonts/ComicNeueSansID.ttf')
}

@font-face {
    font-family: 'Cooper Black Regular';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/CooperBlackRegular.ttf')
}

@font-face {
    font-family: 'Cute Stitch';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/CuteStitch.ttf')
}

@font-face {
    font-family: 'Duepuntozero-Black-Trial';
    src: url('https://idea-assets.s3.amazonaws.com/fonts/Duepuntozero-Black-Trial.ttf')
}

@font-face {
    font-family: 'Duepuntozero-Bold-Trial';
    src: url('https://idea-assets.s3.amazonaws.com/fonts/Duepuntozero-Bold-Trial.ttf')
}

@font-face {
    font-family: 'Duepuntozero-ExtraBoldTrial';
    src: url('https://idea-assets.s3.amazonaws.com/fonts/Duepuntozero-ExtraBoldTrial.ttf')
}

@font-face {
    font-family: 'Duepuntozero-Regular-Trial';
    src: url('https://idea-assets.s3.amazonaws.com/fonts/Duepuntozero-Regular-Trial.ttf')
}

@font-face {
    font-family: 'Halloween Horrors';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/HalloweenHorrors.ttf')
}

@font-face {
    font-family: 'Halloween Horrors Italic';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/HalloweenHorrorsItalic.ttf')
}

@font-face {
    font-family: 'GroundHog Demo';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/GroundhogDemo.ttf')
}

@font-face {
    font-family: 'Kindness';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/Kindness.ttf')
}

@font-face {
    font-family: "KidKnowledge1";
    src: url("https://idea-assets.s3.us-west-2.amazonaws.com/fonts/KidKnowledge1.ttf")
}

@font-face {
    font-family: "KidKnowledge1outline";
    src: url("https://idea-assets.s3.amazonaws.com/fonts/KidKnowledge1.otf")
}

@font-face {
    font-family: "KidKnowledge2Clean";
    src: url("https://idea-assets.s3.amazonaws.com/fonts/KidKnowledge2Clean.otf")
}

@font-face {
    font-family: 'nu_century_gothic';
    src: url('https://idea-assets.s3.amazonaws.com/fonts/nu_century_gothic.ttf')
}

@font-face {
    font-family: 'Schoolkid';
    src: url('https://idea-assets.s3.amazonaws.com/fonts/Schoolkid.ttf')
}

@font-face {
    font-family: 'BurstMyBubble';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/BurstMyBubble.ttf')
}


@font-face {
    font-family: 'CoreSansG-Black';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/CoreSansG-Black.ttf');
}

@font-face {
    font-family: 'CoreSansG-Cube';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/CoreSansG-Cube.ttf');
}

@font-face {
    font-family: 'CoreSansG-Heavy';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/CoreSansG-Heavy.ttf');
}

@font-face {
    font-family: 'CoreSansG-Light';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/CoreSansG-Light.ttf');
}

@font-face {
    font-family: 'CoreSansG-Medium';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/CoreSansG-Medium.ttf');
}

@font-face {
    font-family: 'CoreSansG-Neon';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/CoreSansG-Neon.ttf');
}

@font-face {
    font-family: 'CoreSansG-Outline';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/CoreSansG-Outline.ttf');
}

@font-face {
    font-family: 'CoreSansG-Regular';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/CoreSansG-Regular.ttf');
}

@font-face {
    font-family: 'CoreSansG-Shadow';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/CoreSansG-Shadow.ttf');
}

@font-face {
    font-family: 'CoreSansG-Thin';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/CoreSansG-Thin.ttf');
}

@font-face {
    font-family: 'KG Like A Skyscraper';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/KGLikeASkyscraper.ttf');
}

@font-face {
    font-family: 'KG Neatly Printed';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/KGNeatlyPrinted.ttf');
}

@font-face {
    font-family: 'KG Neatly Printed Spaced';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/KGNeatlyPrintedSpaced.ttf');
}

@font-face {
    font-family: 'KG Primary Lined NoSpace';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/KGPrimaryLinedNOSPACE.ttf');
}

@font-face {
    font-family: 'KG Primary Penmanship';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/KGPrimaryPenmanship.ttf');
}

@font-face {
    font-family: 'KG Primary Penmanship 2';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/KGPrimaryPenmanship2.ttf');
}

@font-face {
    font-family: 'KG Primary Penmanship Alt';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/KGPrimaryPenmanshipAlt.ttf');
}

@font-face {
    font-family: 'KG Primary Penmanship Lined';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/KGPrimaryPenmanshipLined.ttf');
}

@font-face {
    font-family: 'KG Primary Whimsy';
    src: url('https://idea-assets.s3.us-west-2.amazonaws.com/fonts/KGPrimaryWhimsy.ttf');
}

@font-face {
    font-family: 'KGInimitableOriginal';
    src: 'https://idea-assets.s3.us-west-2.amazonaws.com/fonts/KGInimitableOriginal.ttf';
}

@font-face {
    font-family: 'KGSecondChancesSolid';
    src: 'https://idea-assets.s3.us-west-2.amazonaws.com/fonts/KGSecondChancesSolid.ttf';
}

@font-face {
    font-family: 'TakeoverShadow';
    src: 'https://idea-assets.s3.us-west-2.amazonaws.com/fonts/TakeoverShadow.ttf';
}

@font-face {
    font-family: 'TakeoverCollegeComic';
    src: 'https://idea-assets.s3.us-west-2.amazonaws.com/fonts/TakeoverCollegeComic.ttf';
}

@font-face {
    font-family: 'TakeoverCollege';
    src: 'https://idea-assets.s3.us-west-2.amazonaws.com/fonts/TakeoverCollege.ttf';
}

@font-face {
    font-family: 'Takeover3DFilled';
    src: 'https://idea-assets.s3.us-west-2.amazonaws.com/fonts/Takeover3DFilled.ttf';
}

@font-face {
    font-family: 'Takeover3D';
    src: 'https://idea-assets.s3.us-west-2.amazonaws.com/fonts/Takeover3D.ttf';
}

@font-face {
    font-family: 'Takeover';
    src: 'https://idea-assets.s3.us-west-2.amazonaws.com/fonts/Takeover.ttf';
}

@font-face {
    font-family: 'Takeover-Italic';
    src: 'https://idea-assets.s3.us-west-2.amazonaws.com/fonts/Takeover-Italic.ttf';
}

.ellipsis-container {
    width: 200px;
    /* Specify a fixed width for the container */
    overflow: hidden;
    /* Hide the overflowing content */
    position: relative;
    /* Create a positioning context for the ellipsis pseudo-element */
}

/* Preserve multiple spaces within the text */
.ellipsis-content {
    white-space: pre-wrap;
    /* Preserve multiple spaces */
    position: relative;
    /* Create a positioning context for the ellipsis pseudo-element */
}

/* Create an ellipsis pseudo-element */
.ellipsis-content::after {
    content: '\2026';
    /* Unicode ellipsis character */
    position: absolute;
    right: 0;
    /* Position it at the right edge */
    bottom: 0;
    /* Position it at the bottom */
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%);
    /* Fade out the ellipsis */
    padding-left: 8px;
    /* Add some space between the text and ellipsis */
}

.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loading-container svg {
    color: white;
}