.edit-bar-container {
    width: 100%;
    height: 32px;
    border: 1px solid rgba(33, 33, 33, 0.32);
    background: linear-gradient(0deg, #fff 0%, #fff 100%),
        lightgray 50% / cover no-repeat;
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    position: fixed;
    left: 0;
    z-index: 3;
    color: rgba(33, 33, 33, 1);

    .content {
        user-select: none;
        display: flex;
        position: relative;
        align-items: center;
        flex-direction: row;
    }
}

.button-bar-container {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    color: rgba(33, 33, 33, 1);
    padding: 2px 4px 2px 4px;
    background: #e2e2e2;
    cursor: pointer;
}

.popup-edit-bar-container {
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    background-color: white;
    width: 200px;
}