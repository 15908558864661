.loading-dialog {
    .MuiPaper-root {
        background-color: transparent;
        overflow: hidden;
        box-shadow: none;
        .MuiCircularProgress-root {
            color: white;
        }
    }
}
.entry-save-collection {
    background: #ff2442;
    padding: 4px 13px;
    align-items: center;
    border-radius: 6px;
    line-height: 22px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    gap: 6px;

    svg {
        width: 16px;
        height: 16px;
    }
    // &.saved {
    //     padding: 4px 8px;
    //     color: #fff;
    //     background: #212121;
    // }
}
.delete-btn {
    margin: 5px 5px 0 0;
    cursor: pointer;
    svg {
        filter: drop-shadow(0px 0px 20px rgba(33, 33, 33, 0.08));
    }
}
.popup-save-to-collection {
    .MuiPaper-root {
        width: 591px;
        border-radius: 28px;
        @media screen and (max-width: 1024px) {
            background-color: transparent;
            box-shadow: unset;
            width: unset;
            border-radius: 32px 32px 0px 0px;

            .close-button {
                color: unset;
                text-align: center;
            }
            .popup-content {
                background-color: #fff;
                border-radius: 32px 32px 0px 0px;
            }
        }
        p {
            margin: 0;
        }
        .title {
            padding: 32px 24px 16px;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            text-align: center;
            color: #272728;
            margin: 0;
            display: flex;
            justify-content: space-around;
            align-items: center;
            p {
                flex: 1;
            }

            .close-icon {
                cursor: pointer;
                width: 24px;
                height: 24px;
            }
        }

        .content {
            background-color: #f8f8f8;
            .header {
                display: flex;
                gap: 8px;
                padding: 16px 24px;
                align-items: center;

                .collection-breadcrumb {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    p {
                        margin: 0;
                        cursor: pointer;
                        max-width: 100px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1; /* number of lines to show */
                        line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                }
                .icon {
                    // transform: rotate(180deg);
                    cursor: pointer;
                }
            }
            .text {
                margin: 0;
                @media screen and (max-width: 1024px) {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            .collections {
                overflow-y: auto;
                height: 410px;
                @media screen and (max-width: 1024px) {
                    height: 310px;
                }
                .item {
                    padding: 4px 24px;
                    cursor: pointer;
                    &.selected {
                        background-color: #efefef;
                    }
                    &:hover {
                        background-color: #efefef;
                    }
                    .item-collection {
                        display: flex;
                        gap: 16px;
                        align-items: center;
                        padding: 8px 0px 8px 8px;
                        justify-content: space-between;

                        @media screen and (max-width: 1024px) {
                            justify-content: space-between;
                        }

                        .item-collection-name {
                            width: 414px;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 26px;
                            letter-spacing: 0.02em;
                            color: #6e6e70;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; /* number of lines to show */
                            line-clamp: 2;
                            -webkit-box-orient: vertical;
                            @media screen and (max-width: 1024px) {
                                width: fit-content;
                            }
                        }
                    }
                }
                .loading-more {
                    svg {
                        transform: rotate(90deg);
                        path {
                            fill: rgba(0, 0, 0, 0.54);
                        }
                    }
                    margin-top: 0;
                    transition: 0.2s all ease-in-out;
                    position: relative;

                    &.loading-active {
                        animation: loading 1s infinite;
                    }
                }
                .empty {
                    max-width: 80%;
                    margin: auto;
                    padding-top: 40px;
                    text-align: center;

                    p {
                        margin: 0;
                    }
                    .create-collection {
                        padding: 12px 36px;
                        background: rgba(var(--color), 1);
                        border-radius: 12px;
                        width: fit-content;
                        color: #fff;
                        margin: auto;
                        margin-top: 32px;
                        cursor: pointer;
                        transition: all 0.2s ease-in-out;
                        &:hover {
                            transform: translateY(-3px);
                        }
                    }
                }
            }

            .left {
            }
        }

        .btn-action-collection {
            display: flex;
            justify-content: space-between;
            gap: 8px;
            padding: 16px;

            .btn {
                cursor: pointer;
                padding: 12px;
                // flex: 1;
                // border: 1px solid rgba(33, 33, 33, 0.12);
                border-radius: 12px;
                text-align: center;
                &.disable {
                    cursor: not-allowed;
                    background-color: #c5c5c5;
                }
            }

            .save,
            .move {
                background: #ff2442;
                color: #fff;
                transition: all 0.2s ease-in-out;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 12px 36px;

                &:hover:not(.disable) {
                    background: #ff566d;
                }

                // &.saved {
                //   background: #212212;
                //   color: #fff;
                // }
            }

            .create {
                display: flex;
                align-items: center;
                gap: 8px;
                color: rgba(var(--color), 1);
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                &:hover {
                    text-decoration: underline;
                    // border: 1px solid rgba(33, 33, 33, 0.24);
                }
            }
        }

        .create-collection {
            padding: 16px 24px;

            .grid-suggest-create-collection {
                display: flex;
                flex-direction: column;
                gap: 24px;
                padding-top: 24px;

                .grid-item-suggest {
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    svg,
                    .btn-suggest {
                        flex: 1;
                    }
                    .grid-item-suggest-name {
                        flex: 10;

                        color: #272728;
                    }

                    .btn-suggest {
                        border: 1px solid rgba(33, 33, 33, 0.12);
                        padding: 4px 16px;
                        border-radius: 12px;
                        cursor: pointer;
                    }
                }
            }
        }

        .thumbnail-worksheets {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            @media screen and (max-width: 425px) {
                grid-template-columns: 1fr 1fr;
            }
            gap: 8px;
            justify-content: center;
            padding: 12px 24px;
            flex-wrap: wrap;
            .thumbnail-worksheet {
                width: 100%;
                overflow: hidden;
                border-radius: 12px;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 4px 10px 0px rgba(33, 33, 33, 0.182);
            }
        }
    }
}
@keyframes loading {
    0% {
        margin-top: 0;
    }
    50% {
        margin-top: 8px;
    }
    100% {
        margin-top: 0;
    }
}
.popup-confirm-delete {
    .MuiPaper-root {
        background: #ffffff;
        border-radius: 20px;
        text-align: center;
        padding: 8px;
        @media screen and (max-width: 1024px) {
            padding: 0;
            border-top-left-radius: 32px;
            border-top-right-radius: 32px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        // width: 600px;
        h2 {
            padding: 0;
            padding-top: 32px;
        }
        .close-icon-delete-ws {
            position: absolute;
            top: 18px;
            right: 14px;

            &:hover {
                cursor: pointer;
            }
        }

        .MuiDialogContent-root {
            padding: 24px 120px;

            @media screen and (max-width: 555px) {
                padding: 24px 60px;
            }
        }
        .MuiDialogActions-root {
            padding: 8px;
            .action {
                display: flex;
                gap: 8px;
                justify-content: space-around;
                width: 100%;
                > div {
                    width: 50%;
                    padding: 12px;
                    border: 1px solid #e7e7e8;
                    border-radius: 12px;
                    cursor: pointer;
                }
                .delete {
                    background: #ff2442;
                    border-radius: 12px;
                    color: #fff;
                }
            }
        }
    }
}
.save-assign {
    height: 710px;
    @media screen and (max-width: 1024px) {
        height: 625px;
    }
}
.save-to-collection {
    position: relative;
    .bread-crumb {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 24px;
        cursor: pointer;
        .btn {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            .expand-arrow {
                transition: all 0.2s ease-in-out;
            }
            .more {
                transform: rotate(180deg);
            }
        }
        .header {
            padding-left: 0 !important;
            @media screen and (max-width: 1024px) {
            }
        }
    }
    .content-class-container {
        margin-top: 8px;
        .content {
            height: 410px;
            overflow: auto;
            @media screen and (max-width: 1024px) {
                height: 310px;
            }
        }
    }
    .footer-save-assign {
        margin: 8px 16px 16px;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        .btn-save-assign {
            text-align: center;
            padding: 12px 0;
            background: rgba(255, 36, 66, 1);
            border-radius: 12px;
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;
            color: #fff;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            &.disable {
                cursor: not-allowed;
                background-color: #c5c5c5;
            }
        }
    }
    .add-new-collection,
    .add-new-class {
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 16px;
        font-weight: 700;
        padding: 16px 32px;
        line-height: 26px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        &:hover {
            background: #ececec;
            svg {
                g {
                    rect {
                        fill: #fff;
                    }
                }
            }
        }
        svg {
            width: 32px;
            height: 32px;
            g {
                rect {
                    fill: rgba(238, 238, 238, 1);
                }
            }
        }
    }
}
.v1,
.v2 {
    transition: all 0.3s ease-in-out;
}
.v1 {
    animation: fadeInv1 0.3s ease-in-out forwards;
}
.first-v1 {
    animation: fadeInv1 0s ease-in-out forwards;
}
.v2 {
    animation: fadeInv2 0.3s ease-in-out forwards;
}
.first-v2 {
    animation: fadeInv2 0s ease-in-out forwards;
}
@keyframes fadeInv1 {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    70% {
        transform: scale(0, 0);
        opacity: 0;
    }
    80% {
        transform: scale(0.8, 0.8);
    }
    85% {
        transform: scale(0.95, 0.95);
    }
    90% {
        transform: scale(1);
        opacity: 1;
    }
    95% {
        opacity: 1;
        transform: scale(0.95, 0.95);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes fadeInv2 {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    70% {
        transform: scale(0, 0);
        opacity: 0;
    }
    80% {
        transform: scale(0.8, 0.8);
    }
    85% {
        transform: scale(0.95, 0.95);
    }
    90% {
        transform: scale(1);
        opacity: 1;
    }
    95% {
        transform: scale(0.95, 0.95);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
.popup-download-invite-friend {
    .MuiPaper-root {
        overflow: hidden;
        &::-webkit-scrollbar-thumb {
            display: none;
        }
        &::-webkit-scrollbar-track {
            display: none;
        }
    }
}
.popup-download-multi-ws,
.popup-download-invite-friend {
    p {
        margin: 0;
    }
    .MuiTypography-root {
        padding: 0;
    }
    .MuiPaper-root {
        @media screen and (min-width: 1025px) {
            &::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.2);
            }
            &::-webkit-scrollbar-track {
                background-color: #fff;
                border-top-right-radius: 12px;
                border-bottom-right-radius: 12px;
                // border-color: #212121;
                border-top: 1px solid #212121;
                border-bottom: 1px solid #212121;
            }
        }
    }
    .MuiDialog-container {
        @media screen and (min-width: 1025px) {
            align-items: start;
            padding-top: 7vh;
        }
    }
    .MuiPaper-root {
        width: 100%;
        @media screen and (min-width: 1025px) {
            max-height: 80vh;
            height: fit-content;
            max-width: 590px;
            width: 590px;
            background-color: transparent;
            box-shadow: none;
            border-radius: 12px;
            border: 2px solid #212121;
            .MuiDialogContent-root {
                background-color: #fff;
            }
        }
        > div {
            @media screen and (max-width: 1024px) {
                max-height: 85vh;
                .content {
                    overflow: auto;
                    max-height: unset !important;
                    min-height: calc(100% - 26px);
                    height: calc(100% - 26px) !important;
                    ::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
        }
    }
    .header-popup {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        @media screen and (min-width: 1025px) {
            padding: 16px 0;
        }
        p {
            font-size: 16px;
            font-weight: 700;
        }
    }
}
.popup-download-multi-ws {
    .MuiDialogContent-root {
        padding: 0 32px;
        .claim-coins {
            width: 100%;
            padding: 16px 0;
            display: flex;
            flex-direction: column;
            gap: 16px;
            .claim-coins-close {
                @media screen and (min-width: 1025px) {
                    display: flex;
                    cursor: pointer;
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }
                path {
                    fill-opacity: 1;
                }
            }
            .claim-coins-detail {
                .claim-coins-price {
                    .c-price-item {
                        padding: 12px 0;
                    }
                    .c-totals {
                        .c-totals-coins {
                            .c-totals-coins-number {
                                font-size: 16px;
                                font-weight: 700;
                            }
                        }
                        .c-number-ws {
                            font-size: 12px;
                            font-weight: 600;
                        }
                    }
                }
            }
            .claim-download {
                padding-top: 0;
                .btn-save-to-device-text {
                    font-size: 14px;
                    font-weight: 600;
                }
            }
            .claim-coins-content {
                .claim-coins-content-text {
                    font-size: 14px;
                }
            }
            .claim-coins-content {
                padding-top: 8px;
            }
        }
    }
}
