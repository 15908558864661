.loading-dialog {
    .MuiPaper-root {
        background-color: transparent;
        overflow: hidden;
        box-shadow: none;
        .MuiCircularProgress-root {
            color: white;
        }
    }
}
.entry-save-collection {
    background: #ff2442;
    padding: 4px 13px;
    align-items: center;
    border-radius: 6px;
    line-height: 22px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    gap: 6px;

    svg {
        width: 16px;
        height: 16px;
    }
    // &.saved {
    //     padding: 4px 8px;
    //     color: #fff;
    //     background: #212121;
    // }
}
.delete-btn {
    margin: 5px 5px 0 0;
    cursor: pointer;
    svg {
        filter: drop-shadow(0px 0px 20px rgba(33, 33, 33, 0.08));
    }
}
.popup-save-to-collection {
    .MuiPaper-root {
        width: 591px;
        border-radius: 28px;
        @media screen and (max-width: 768px) {
            background-color: transparent;
            box-shadow: unset;
            width: unset;
            border-radius: 32px 32px 0px 0px;

            .close-button {
                color: unset;
                text-align: center;
            }
            .popup-content {
                background-color: #fff;
                border-radius: 32px 32px 0px 0px;
            }
        }
        p {
            margin: 0;
        }
        .title {
            padding: 32px 24px 16px;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            text-align: center;
            color: #272728;
            margin: 0;
            display: flex;
            justify-content: space-around;
            align-items: center;
            p {
                flex: 1;
            }

            .close-icon {
                cursor: pointer;
                width: 24px;
                height: 24px;
                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
        }

        .content {
            background-color: #f8f8f8;
            .header {
                display: flex;
                gap: 8px;
                padding: 16px 24px;
                align-items: center;

                .collection-breadcrumb {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    p {
                        margin: 0;
                        cursor: pointer;
                        max-width: 100px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1; /* number of lines to show */
                        line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                }
                .icon {
                    // transform: rotate(180deg);
                    cursor: pointer;
                }
            }
            .text {
                margin: 0;
                padding: 16px 24px;
                @media screen and (max-width: 768px) {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            .collections {
                overflow-y: auto;
                height: 410px;
                @media screen and (max-width: 768px) {
                    height: 280px;
                }
                .item {
                    padding: 4px 24px;
                    cursor: pointer;
                    &.selected {
                        background-color: #efefef;
                    }
                    &:hover {
                        background-color: #efefef;
                    }
                    .item-collection {
                        display: flex;
                        gap: 16px;
                        align-items: center;
                        padding: 8px 0px 8px 8px;

                        @media screen and (max-width: 768px) {
                            justify-content: space-between;
                        }

                        .item-collection-name {
                            width: 414px;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 26px;
                            letter-spacing: 0.02em;
                            color: #6e6e70;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; /* number of lines to show */
                            line-clamp: 2;
                            -webkit-box-orient: vertical;
                            @media screen and (max-width: 768px) {
                                width: fit-content;
                            }
                        }
                    }
                }
                .loading-more {
                    svg {
                        transform: rotate(90deg);
                        path {
                            fill: rgba(0, 0, 0, 0.54);
                        }
                    }
                    margin-top: 0;
                    transition: 0.2s all ease-in-out;
                    position: relative;

                    &.loading-active {
                        animation: loading 1s infinite;
                    }
                }
                .empty {
                    max-width: 80%;
                    margin: auto;
                    padding-top: 40px;
                    text-align: center;

                    p {
                        margin: 0;
                    }
                    .create-collection {
                        padding: 12px 36px;
                        background: rgba(var(--color), 1);
                        border-radius: 12px;
                        width: fit-content;
                        color: #fff;
                        margin: auto;
                        margin-top: 32px;
                        cursor: pointer;
                        transition: all 0.2s ease-in-out;
                        &:hover {
                            transform: translateY(-3px);
                        }
                    }
                }
            }

            .left {
            }
        }

        .btn-action-collection {
            display: flex;
            justify-content: space-between;
            gap: 8px;
            padding: 16px;

            .btn {
                cursor: pointer;
                padding: 12px;
                // flex: 1;
                // border: 1px solid rgba(33, 33, 33, 0.12);
                border-radius: 12px;
                text-align: center;
                &.disable {
                    cursor: not-allowed;
                    background-color: #c5c5c5;
                }
            }

            .save,
            .move {
                background: #ff2442;
                color: #fff;
                transition: all 0.2s ease-in-out;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 12px 36px;

                &:hover:not(.disable) {
                    background: #ff566d;
                }

                // &.saved {
                //   background: #212212;
                //   color: #fff;
                // }
            }

            .create {
                display: flex;
                align-items: center;
                gap: 8px;
                color: rgba(var(--color), 1);
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                &:hover {
                    text-decoration: underline;
                    // border: 1px solid rgba(33, 33, 33, 0.24);
                }
            }
        }

        .create-collection {
            padding: 16px 24px;

            .grid-suggest-create-collection {
                display: flex;
                flex-direction: column;
                gap: 24px;
                padding-top: 24px;

                .grid-item-suggest {
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    svg,
                    .btn-suggest {
                        flex: 1;
                    }
                    .grid-item-suggest-name {
                        flex: 10;

                        color: #272728;
                    }

                    .btn-suggest {
                        border: 1px solid rgba(33, 33, 33, 0.12);
                        padding: 4px 16px;
                        border-radius: 12px;
                        cursor: pointer;
                    }
                }
            }
        }

        .thumbnail-worksheets {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            @media screen and (max-width: 425px) {
                grid-template-columns: 1fr 1fr;
            }
            gap: 8px;
            justify-content: center;
            padding: 12px 24px;
            flex-wrap: wrap;
            .thumbnail-worksheet {
                width: 100%;
                overflow: hidden;
                border-radius: 12px;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 4px 10px 0px rgba(33, 33, 33, 0.182);
            }
        }
    }
}
@keyframes loading {
    0% {
        margin-top: 0;
    }
    50% {
        margin-top: 8px;
    }
    100% {
        margin-top: 0;
    }
}
