.highlighted-sentence {
    border: 0.5px solid rgba(33, 33, 33, 0.32);
    border-radius: 6px;
    padding: 8px;
    position: relative;
    border-radius: 4px;
    border: 1px solid transparent;

    .text-side {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-right: 10px;
    }

    &:hover {
        border-radius: 4px;
        border: 1px solid #3B82F6;
        box-shadow: 0px 0px 0px 3px rgba(59, 130, 246, 0.12);
    }

    .index {
        font-family: "Nunito";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        align-self: center;
        color: #212121;
    }

    .box-word {
        background: rgba(252, 207, 121, 0.24);
        border-radius: 2px;

        .highlighted-word {
            font-family: "Nunito";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color:rgba(33, 33, 33, 1);
            padding: 2px 4px;
            cursor: pointer;
        }



        .under-line {
            height: 1px;
            background: #000;
            margin: auto;
        }
    }

    .selected {
        background: #FCCF79;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .icon-hover {
        position: absolute;
        top: -8px;
        right: -8px;

        .clear-icon {
            position: absolute;
            top: 5px;
            right: 6px;
            width: 15px;
            height: 15px;
            cursor: pointer;
        }

        .pencil-icon {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;
        }
    }

    .under-text{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        // background: #3B82F6;
    }
}