.styled-section {
    border-radius: 8px 8px 0px 0px;
    background: #FFF;
    border: 1px solid rgba(33, 33, 33, 0.2);
    overflow: hidden;

    .btn-group {
        border-radius: 4px;
        background: #F3F4F6;
        display: flex;
        width: 100%;

        .btn {
            margin: 4px;
            background: transparent;
            color: #1F2937;
            font-family: Nunito;
            font-size: 14px;
            letter-spacing: 0.07px;
            text-transform: none;
            border: none;
            background-size: 200% 100%;
            border-radius: 6px;
            font-weight: 500;
            line-height: 20px;

            &:first-child {
                background-image: linear-gradient(to right, transparent 50%, #FFF 50%);
                margin-right: 0px;
                border-radius: 6px;

                &.active {
                    animation: leftToRight 0.2s ease-in-out;
                    background-position: right;
                }

                &.disable {
                    animation: rightToLeft 0.2s ease-in-out;
                    background-position: left;
                }

            }

            &:nth-child(2) {
                background-image: linear-gradient(to right, #FFF 50%, transparent 50%);
                margin-left: 0px;
                border-radius: 6px;

                &.active {
                    animation: rightToLeft 0.2s ease-in-out;
                    background-position: left;
                }

                &.disable {
                    animation: leftToRight 0.2s ease-in-out;
                    background-position: right;
                }
            }

            &:hover {
                color: rgba(var(--color), 1);

            }

            &.active {
                color: rgba(var(--color), 1);
                box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
            }
        }

        @keyframes leftToRight {
            from {
                background-position: left;
            }
    
            to {
                background-position: right;
            }
        }
    
        @keyframes rightToLeft {
            from {
                background-position: right;
            }
    
            to {
                background-position: left;
            }
        }
    }

    .main-section {
        padding: 8px;
        .display-style-container {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            padding-bottom: 8px;
            gap: 8px;
    
            .item {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 6px 2px 6px 2px;
                border-radius: 6px;
                background: #F9FAFB;
                cursor: pointer;
                transition: background 0.15s ease, border 0.15s ease;
                user-select: none;
    
                .check-icon {
                    padding: 2px;
                    margin-right: 4px;
                }
    
                .name {
                    color: #212121;
                    font-family: Nunito;
                    font-size: 12px;
                    font-weight: 500;
                }
    
                img {
                    width: 60px;
                    height: 36px;
                }
    
                &:hover {
                    background-color: rgba(240, 241, 242, 0.8);
                }
    
                &.active {
                    background-color: #f0f1f2;
                }
    
            }
            &.default {
                border-bottom: 1px solid #E4E4E4;
            }
    
            &.bonds {
                .item {
                    padding: 6px;
                    border: 1px solid transparent;
                    img {
                        margin-left: unset;
                        width: 50px;
                        height: 50px;
                    }
        
                    &.active {
                        border: 1px solid #3B82F6;
                    }
                }
            }
        }

        .dropdown-container {
            border-radius: 6px;
            background: #F9FAFB;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
            padding: 6px 6px 6px 12px;
            margin-top: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                color: #212121;
                font-size: 14px;
                font-weight: 500;
                text-transform: capitalize;
            }

            .select {
                background: #fff;
                .MuiSelect-select {
                    padding: 4px;
                    width: fit-content;
                    border: 4px;

                    .left {
                        min-width: 16px;
                        display: flex;
                        margin: 0 8px;

                        .tick-icon {
                            display: none;
                        }
                    }

                    .right {
                        min-width: 40px;
                        margin-left: 8px;
                        margin-right: 36px;
                        color: #212121;
                        font-feature-settings: 'clig' off, 'liga' off;
                        font-family: Nunito;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px; /* 200% */
                        text-transform: capitalize;
                        position: relative;

                        &::after {
                            content: "";
                            background-color: #D9D9D9;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: -8px;
                            width: 1px;
                            height: 60%;
                        }
                    }
                }
    
                .MuiOutlinedInput-notchedOutline {
                    border: 0;
                    border-radius: 6px;
                }
            }
        }
    }
}

.math-dropdown-item {
    padding: 0 !important;
    border-radius: 4px;
    margin-bottom: 4px !important;

    &:last-child {
        margin-bottom: 0 !important;
    }

    .left {
        min-width: 50px;
        display: flex;
        align-items: center;
        margin-right: 8px;

        .tick-icon {
            margin-right: 6px;
            margin-left: 6px;
            visibility: hidden;
        }

        img {
            min-width: 16px;
            width: fit-content;
            height: fit-content;
        }
    }

    .right {
        margin-left: 8px;
        color: #212121;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 200% */
        text-transform: capitalize;
        position: relative;

        &::after {
            content: "";
            background-color: #D9D9D9;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -8px;
            width: 1px;
            height: 60%;
        }
    }

    &:hover {
        background: #F9FAFB;
    }

    &.Mui-selected {
        background: rgba(59, 130, 246, 0.12);
        .left {
            .tick-icon {
                visibility: unset;
            }
        }
    }
}