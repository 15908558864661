.custom-text-container {
    position: absolute;
    width: 100%;

    * {
        font-family: inherit !important;
        font-size: inherit !important;
        line-height: inherit !important;
    }

    sup {
        font-size: 60% !important;
    }
}

.canvas-text math {
    display: inline-block !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.vertical-align-bottom {
    bottom: 0px;
}

.vertical-align-middle {
    top: 50%;
    transform: translateY(-50%);
}

.vertical-align-top {
    top: 0px;
}

.capitalize-first-letter:first-letter {
    text-transform: uppercase !important;
}

.mq-comma-space {
    margin-right: 5px !important;
}

.mq-end-char-of-word {
    margin-right: 15px !important;
}

.mq-editable-field.mq-math-mode {
    border: none !important;
}

.mq-math-mode .mq-overline {
    border-top: 2px solid black !important;
    line-height: 78% !important;
}

.mq-supsub {
    vertical-align: 0 !important;
}

.mq-root-block {
    overflow: visible !important;
}