.set-validator-name-action {
    padding: 2px;
    margin: auto;
    margin-left: 4px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;

    svg {
        width: 16px;
        height: 16px;
    }

    &:hover{
        background-color: rgba(33, 33, 33, 0.12);
        border-radius: 4px;
    }
}