.word-input-container {
    .auto-complete-input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        position: relative;

        .icon-search {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 6px;
            top: 3px;
            background: #f3f3f3;
            padding: 7px;
            border-radius: 6px;
            pointer-events: none;
            z-index: 3;
        }

        &:hover fieldset {
            // border: 1px solid rgba(var(--color), 1);
            box-shadow: rgba(59, 130, 246, 0.12) 0px 0px 0px 3px;
        }

        .MuiOutlinedInput-root {
            padding: unset;
        }

        input {
            padding: 0px 12px 0px 12px !important;
            font-family: Nunito;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;

        }

        .MuiInputBase-root {
            border-radius: 6px;
            // padding: 12px;
            height: 38px;
            background: #F3F3F3;
            // overflow: hidden;
        }

        .text-field {
            &:hover fieldset {
                border: 1px solid rgba(var(--color), 1);
            }

            fieldset {
                border: none;
            }
        }

        .text-field-warning {
            fieldset {
                border: 1px solid #FF2341;
            }
        }
    }

    .text-area-input {
        margin-top: 8px;
        padding-top: 8px;
        border-top: 1px solid #E4E4E4;

        .multi-text {
            width: 100%;
            background: #F9FAFB;

            .MuiInputBase-root {
                padding: 12px 4px 12px 12px;
            }

            .MuiInputBase-input, .MuiInputBase-input::placeholder {
                color: rgba(33, 33, 33, 0.64);
                font-family: Nunito;
                font-size: 12px;
                font-weight: 400;
                line-height: normal;
            }
            
            .MuiInputBase-input::placeholder{
                text-transform: capitalize;
            }

            .MuiInputBase-input {
                overflow: hidden;
                padding-right: 6px;

                /* width */
                &::-webkit-scrollbar {
                    width: 4px;
                }
                /* Track */
                &::-webkit-scrollbar-track {
                    background: #FAFAFA;
                }
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    border-radius: 16px;
                    background: #D9D9D9;
                }
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #D9D9D9;
                }

                &:hover {
                    overflow-y: scroll;
                }
            }

            fieldset {
                border-color: transparent;
            }

            &:hover fieldset {
                border: 1px solid rgba(33, 33, 33, 0.12);
            }
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border: 1px solid rgba(33, 33, 33, 0.12);
        }
    }
}