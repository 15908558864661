.tab-zones-page {
    .btns {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .zones-list {
        &__validators {
            &__title {
                font-weight: 700;
                font-size: 13px;
            }

            li {
                font-size: 11px;
            }
        }

        .delete-zone-icon {
            color: red;
            cursor: pointer;
            font-size: 1.5rem;
        }
    }
}
