.popup-edit-collection {
    p {
        margin: 0;
    }
    .MuiPaper-root {
        @media screen and (max-width: 768px) {
            box-shadow: unset;
            background-color: transparent;
            .close-button {
                text-align: center;
                color: unset;
            }
            .popup-content {
                background-color: #fff;
                border-radius: 32px 32px 0px 0px;
            }
        }
    }
    .title {
        text-align: center;
        margin: 0;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        @media screen and (max-width: 768px) {
            padding: 16px 0;
        }
        p {
            flex: 1;
        }

        .close-icon {
            cursor: pointer;
            height: 24px;
            width: 24px;
        }
    }
    .MuiDialog-paper {
        padding: 32px 24px !important;
        width: 591px;
        border-radius: 28px;
        .label {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            /* identical to box height, or 129% */

            color: rgba(33, 33, 33, 0.52);
        }
    }
    .content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-top: 16px;
        @media screen and (max-width: 768px) {
            margin: 0 24px;
        }
        .field {
            padding-top: 36px;
            @media screen and (max-width: 768px) {
                padding-top: 24px;
            }
            &:first-child {
                padding-top: 0px;
            }
            .label {
                color: rgba(33, 33, 33, 0.52);
            }
            .MuiInput-root {
                width: 100%;

                &:hover:not(.Mui-disabled):before {
                    border-bottom: 2px solid #2183df;
                }

                &::after {
                    border-bottom: 2px solid rgba(33, 33, 33, 0.5);
                }
            }

            &.status {
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media screen and (max-width: 768px) {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 26px;
                }
                .switch-form {
                    padding-top: 32px;

                    @media screen and (max-width: 768px) {
                        padding-top: 16px;
                    }
                }
            }
        }
    }
    .buttons {
        display: flex;
        justify-content: space-between;
        padding-top: 40px;
        gap: 8px;
        @media screen and (max-width: 768px) {
            margin: 0 24px;
            padding-bottom: 16px;
        }
        .button {
            width: 50%;
            text-align: center;
            padding: 12px 0;
            border-radius: 12px;
            border: 1px solid #e7e7e8;
            cursor: pointer;

            &.save {
                background: #ff2442;
                color: #fff;
            }
        }
    }
}
.switch-form {
    margin: 0 !important;
    .MuiSwitch-root {
        width: 90px !important;
        height: 36px !important;
        padding: 0 !important;
        margin: 0 !important;
        border-radius: 100px;
        background-color: #212121;
        transition: all 0.1s ease-in-out;
        &::before {
            content: "Public";
            color: #ffffff;
            position: absolute;
            width: 100%;
            height: 100%;
            font-size: 12px;
            font-weight: 600;
            left: 0;
            top: 0;
            background-repeat: no-repeat;
            background-position: center;
            transform: translate3d(40px, 10px, 0);

            @media screen and (max-width: 768px) {
                transform: translate3d(40px, 6px, 0);
            }
        }
        &.checked {
            background-color: rgba(33, 33, 33, 0.12);
            &::before {
                content: "Private";
                color: #212121;
                transform: translate3d(10px, 10px, 0);
                @media screen and (max-width: 768px) {
                    transform: translate3d(10px, 6px, 0);
                }
            }
        }

        .MuiSwitch-switchBase {
            margin: 0 !important;
            padding: 0 !important;
            transform: translateX(4px);
            color: #fff;
            top: 3.5px;

            &.Mui-checked {
                color: #212121;
                transform: translateX(56px);
                .MuiSwitch-thumb {
                    // &::before {
                    //     background-image: url("/images/lock-circle.png");
                    // }
                }
                .MuiSwitch-track {
                    background-color: rgba(33, 33, 33, 0.12);
                }
            }

            .MuiSwitch-thumb {
                width: 28px;
                height: 28px;

                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background-repeat: no-repeat;
                    // background-image: url("/images/global.png");
                    background-position: center;
                }
            }
            + .MuiSwitch-track {
                opacity: 1 !important;
                background-color: transparent !important;
            }
            .MuiSwitch-track {
                opacity: 1;
                background-color: #212121;
                border-radius: 20 / 2;
            }
        }
    }
}
