.prompt-interactive {
    padding: 8px;
    white-space: pre-line!important;
  }
  .prompt-interactive__text {
    color: gray;
    font-weight: bold;
  }
  .prompt-interactive__text--miss {
    color: red;
  }
  
  .prompt-interactive__text--completed {
    color: green;
  }
  
  .text-editable__textarea {
    outline: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: calc(100% - 200px);
  }
  .text-editable__input {
    outline: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 180px;
  }
  .text-editable__suggest-list {
    width: 180px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    max-height: 180px;
    overflow-y: auto;
    z-index: 9999;
  }
  .text-editable__suggest-item {
    padding: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  .text-editable__suggest-item:hover {
    background-color: #eee;
  }
  .text-editable__action-list {
    display: flex;
    // background-color: #fff;
    padding: 0 4px;
    z-index: 9999;
  }
  .text-editable__action-item {
    cursor: pointer;
    font-size: 12px;
    padding: 2px;
    margin: 0 2px;
    font-weight: bold;
    color: #fff;
    border-radius: 4px;
  }
  .text-editable__action-item.save {
    background-color: green;
  }
  .text-editable__action-item.cancel {
    background-color: orange;
  }

  .prompt-tooltip-text {
    z-index: 99999;
    background-color: gray;
    color: #fff;
    padding: 0 4px;
    font-weight: bold;
    border-radius: 4px;
  }