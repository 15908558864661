.content-line-interactive {
    .text-input {
        position: relative;
        outline: none;
        border: none;
        overflow: hidden;
        border: 2px solid transparent;
        background: transparent;
        border-radius: 8px;
    }

    .text-input:hover {
        border: 2px solid #000000;
        border-radius: 8px;
        background: transparent;
    }

    .box-input {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        input:focus {
            border: 2px solid #000000;
            border-radius: 8px;
            background: transparent;
        }

        .ssentence-text{
            resize: none;
        }

        .hide-important {
            opacity: 0 !important
        }
        
        .show-important {
            opacity: 1 !important
        }
    }

    .content-btn {
        position: absolute;
        width: 12px;
        height: 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 4px;
    }

    .button-move {
        padding: 2px;
        background-color: rgba(255, 255, 255, 0.933);
        box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.15);
        border-radius: 2px;
        display: flex;
        align-items: center;
        height: 10px;
        width: 10px;

        &:hover {
            transform: scale(1.5);
        }
    }

    .move-up {
        clip-path: polygon(100% 100%, 0 100%, 50% 0);
        background-color: #212121da;
        width: 10px;
        height: 6px;
    }

    .move-down {
        clip-path: polygon(50% 100%, 0 0, 100% 0);
        background-color: #212121ce;
        width: 10px;
        height: 6px;
    }
}