.prompt-interactive-modal {
    .modal-dialog {
        width: 800px;
        max-width: calc(100% - 40px) !important;
    }

    &.hide {
        .modal-dialog {
            display: none;
        }
    }
}