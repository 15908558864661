.word-list {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &>* {
        font-family: "Poppins";
    }

    .title {
        color: #1F2937;
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .MuiOutlinedInput-root {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        border-radius: 8px 8px 0 0;
    }



    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: 1px solid rgba(var(--color), 1);
        box-shadow: rgba(59, 130, 246, 0.12) 0px 0px 0px 3px;
    }

    .MuiInputBase-root {
        padding: 12px;
    }

    .multi-text {

        // margin: 3px;
        &:hover fieldset {
            border: 1px solid rgba(var(--color), 1);
            box-shadow: rgba(59, 130, 246, 0.12) 0px 0px 0px 3px;
        }
    }

    .auto-complete {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        position: relative;


        .icon-search {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 6px;
            top: 3px;
            background: #f3f3f3;
            padding: 7px;
            border-radius: 6px;
            pointer-events: none;
            z-index: 3;
        }

        &:hover fieldset {
            // border: 1px solid rgba(var(--color), 1);
            box-shadow: rgba(59, 130, 246, 0.12) 0px 0px 0px 3px;
        }

        .MuiOutlinedInput-root {
            padding: unset;
        }

        input {
            // padding-top: 20px;
            height: auto;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            padding: 0px 12px 0px 12px !important;
        }

        .MuiInputBase-root {
            border-radius: 6px;
            // padding: 12px;
            height: 38px;
            background: #F3F3F3;
            // overflow: hidden;
        }

        .text-field {
            &:hover fieldset {
                border: 1px solid rgba(var(--color), 1);
            }

            fieldset {
                border: none;
            }
        }

        .text-field-warning {
            fieldset {
                border: 1px solid #FF2341;
            }
        }
    }
}

.popup-confirm-delete {
    .MuiPaper-root {
        background: #ffffff;
        border-radius: 20px !important;
        text-align: center;
        padding: 8px;

        // width: 600px;
        h2 {
            padding: 0;
            padding-top: 32px;
        }

        .close-icon-delete-ws {
            position: absolute;
            top: 18px;
            right: 14px;

            &:hover {
                cursor: pointer;
            }
        }

        .MuiDialogContent-root {
            padding: 24px 120px;

            @media screen and (max-width: 555px) {
                padding: 24px 60px;
            }
        }

        .MuiDialogActions-root {
            padding: 8px;

            .action {
                display: flex;
                gap: 8px;
                justify-content: space-around;
                width: 100%;

                >div {
                    width: 50%;
                    padding: 12px;
                    border: 1px solid #e7e7e8;
                    border-radius: 12px;
                    cursor: pointer;
                }

                .confirm {
                    background: rgba(var(--color), 1);
                    border-radius: 12px;
                    color: #fff;
                }
            }
        }
    }
}