.rotate-container {
    display: flex;
    padding: 28px;
    position: absolute;
}
.rotate-left {
    left : 100%
}
.rotate-top {
    top: 100%;
}
.popup-rotate{
    position: absolute;
    top:0;
    left: 0;
    display: flex;
    width: fit-content;
    padding: 2px;
    border: 1px solid #acacac;
    border-radius: 4px;
    background: #212121;
    left: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 12px;
    z-index: 100;
}
