.undo-redo-container {
    display: flex;
    gap: 8px;
    margin-right: 8px;

    .btn-function {
        padding: 8px 10px;
        border-radius: 6px;
        background: rgba(33, 33, 33, 0.04);
        opacity: 0.4;

        display: flex;
        justify-content: center;
        cursor: not-allowed;
        
        &.active {
            cursor: pointer;
            opacity: 1;
        }
    }
}