.popup-feedback-container {
    right: 7px;
    z-index: 1000;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;

    &.disabled {
        display: none;
    }

    .button-feedback {
        display: flex;
        width: 48px;
        height: 48px;
        border-radius: 50px;
        background-color: #D46134;
        justify-content: center;
        align-items: center;
        position: relative;

        .icon-feedback {
            width: 28.5px;
            height: 28.5px;
        }

        .tooltip-container {
            display: none;
            border-radius: 4px;
            background: #2E3B4B;
            text-align: center;
            padding: 4px 8px;
            position: absolute;
            z-index: 1200;
            bottom: 121%;
            left: 50%;
            margin-left: -59px;
            color: #FFF;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .top-break {
            right: 21px;
            display: none;
            position: absolute;
            top: -12px;
        }

        &:hover .tooltip-container {
            display: block;
        }

        &:hover .top-break {
            display: block;
        }
    }
}

.drawer-feedback {
    width: 100%;

    .MuiPaper-root {
        overflow-y: hidden;

        @media screen and (max-width: 768px) {

            background-color: transparent;
            // text-align: center;

            box-shadow: none;
        }
    }

    .close-button {
        text-align: center;
    }
}

.popup-detail-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    // box-sizing: border-box;
    height: 100vh;

    @media screen and (max-width: 768px) {
        height: 85vh;


    }

    .upper-section {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        .header-upper {
            display: flex;
            justify-content: space-between;
            padding: 16px;
            background-color: #F9FAFB;
            color: #1F2937;
            font-family: Nunito;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.09px;
            box-sizing: border-box;
            position: sticky;
            top: 0;

            .close-drawer-filter {
                display: flex;
                padding: 2px;
                cursor: pointer;
            }

            @media screen and (max-width: 768px) {
                background-color: #FFF;
                justify-content: center;
                padding: 32px 24px 16px;
                border-top-right-radius: 32px;
                border-top-left-radius: 32px;
                font-weight: 600;

            }
        }

        .body-upper {
            border-top: 1px solid #E5E7EB;
            border-bottom: 1px solid #E5E7EB;

            background: #FFF;
            padding: 20px 20px 100px 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            flex-grow: 1;
            overflow-y: auto;

            .describe {
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 100%;

                .label {
                    color: #212121;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.07px;

                    &.disabled {
                        color: rgba(33, 33, 33, 0.24);
                    }
                }

                .input-feedback {
                    width: 100%;

                    textarea {
                        border: none;
                        width: 100%;
                        height: 128px;
                        line-height: 18px;
                        resize: none;
                        overflow: auto;
                        background-color: #F9FAFB;
                        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
                        color: #212121;
                        font-family: Nunito;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 0.07px;
                        padding: 14px 16px;
                        border-radius: 6px;
                        box-sizing: border-box;

                        &.active {
                            outline: 1px solid #3B82F6;
                            box-shadow: 0px 0px 0px 4px rgba(59, 130, 246, 0.25);
                        }

                        &.disabled {
                            pointer-events: none;
                            color: rgba(33, 33, 33, 0.24)
                        }
                    }

                    textarea::placeholder {
                        font-size: 14px;
                        color: #6B7280;
                    }

                    textarea:focus {
                        border: none;
                        outline: 1px solid #3B82F6;
                        box-shadow: 0px 0px 0px 4px rgba(59, 130, 246, 0.25);
                    }
                }

                .warning {
                    // width: 365px;
                    color: #9CA3AF;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.06px;
                }
            }

            .upload-screenshot-container {
                display: flex;
                flex-direction: column;
                gap: 16px;
                height: 100%;

                padding-bottom: 5px;
                box-sizing: border-box;

                .label {
                    max-width: 331px;
                    color: #6B7280;
                    font-family: Nunito;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px;
                    letter-spacing: 0.08px;
                }

                .upload-section {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .btn-upload {
                        width: 297px;
                        display: flex;
                        padding: 8px 16px;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        border-radius: 6px;
                        outline: 1px solid #E5E7EB;
                        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
                        cursor: pointer;
                        box-sizing: unset;

                        @media screen and (max-width: 768px) {
                            width: auto;
                        }

                        &.hidden {
                            display: none;

                        }

                        p {
                            margin: 0;
                            color: rgba(var(--color), 1);
                            text-align: center;
                            font-family: Nunito;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: 0.07px;
                        }

                        .file-input {
                            display: none;
                        }

                        &.disabled {
                            display: none;
                        }
                    }

                    .label-screenshots {
                        padding-top: 44px;
                        width: 100%;
                        color: #212121;
                        font-family: Nunito;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 0.07px;
                    }

                    .screenshots {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        // padding: 16px 16px 80px 4px;
                        padding-bottom: 80px;
                        align-items: center;
                        box-sizing: border-box;

                        .images {
                            display: flex;
                            width: 331px;
                            min-height: 169px;
                            max-height: 169px;
                            flex-direction: column;
                            align-items: flex-start;
                            border-radius: 6px;
                            position: relative;

                            .delete-image {
                                background-color: #FFF;
                                border-radius: 32px;
                                display: flex;
                                position: absolute;
                                top: -15px;
                                right: -15px;
                                padding: 6px;
                                box-shadow: 0px 2px 10px rgba(33, 33, 33, 0.12);
                                cursor: pointer;

                                &.disabled {
                                    display: none;
                                }
                            }

                            img {
                                object-fit: cover;
                                border-radius: 6px;
                                width: 100%;
                                height: 100%;
                            }

                            &:hover {
                                outline: 1px solid rgba(var(--color), 1);
                                box-shadow: 0px 0px 0px 4px rgba(59, 130, 246, 0.25);
                            }

                            &.disabled {
                                pointer-events: none;
                            }
                        }
                    }
                }
            }

            ;
        }
    }

    .lower-section {
        display: flex;
        padding: 16px;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        background-color: #F9FAFB;
        position: sticky;
        bottom: 0;

        // position: relative;
        @media screen and (width: "768px") {}

        .btn-send {
            display: flex;
            padding: 8px 28px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            color: #FFF;
            border-radius: 6px;
            border: 1px solid #E5E7EB;
            background: rgba(var(--color), 1);
            cursor: pointer;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
            text-align: center;
            font-family: Nunito;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.08px;


            &.disabled {
                padding: 0;
                width: 94px;
                height: 38px;
                pointer-events: none;
            }

            &.empty {
                color: rgba(33, 33, 33, 0.24);
                background-color: #E3E2E3;
                pointer-events: none;

            }
        }



    }
}