.text-style-bar-container {
    height: 32px;
    background: linear-gradient(0deg, #fff 0%, #fff 100%),
        lightgray 50% / cover no-repeat;
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    padding: 0 12px;
    color: rgba(33, 33, 33, 1);

    .text-align-panel {
        display: flex;
        position: absolute;
        border-radius: 6px;
        background: #212121;
        top: 36px;
        padding: 4px;
        z-index: 2;
    }

    .function-button {
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #e2e2e2;
        border-radius: 4px;
        margin-right: 4px;
        box-sizing: border-box;
        position: relative;
        color: rgba(33, 33, 33, 1);
        cursor: pointer;

        .text-italic{
            position: absolute;
            opacity: 0;
            font-style: italic;
            font-weight: bold;
        }

        &:hover {
            .tooltip-content {
                visibility: visible;
            }
        }

        .tooltip-content {
            position: absolute;
            z-index: 100;
            visibility: hidden;
            background-color: black;
            width: fit-content;
            color: #fff;
            text-align: center;
            border-radius: 6px;
            padding: 2px 8px 2px 8px;
            transform: translate(-0px, 35px);
            clear: both;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;

            .arrow {
                position: absolute;
                top: -10px;
                left: 42.5px;
                width: 24px;
                height: 15px;
                clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                background: #0b0b0b;
            }

            .text-tooltip {
                font-size: 12px;
                font-weight: 500;
            }
        }
    }

    .function-button.active {
        background: rgba(33, 33, 33, 1);
        color: white;
    }

    .alignment-button {
        background: #212121;

        &.active {
            background: rgba(255, 255, 255, 0.24);
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.08);
        }
    }

    .text-color {
        margin: 0 6px 0 8px;
        display: flex;
        position: relative;
        cursor: pointer;
        background: none;
        

        .colors {
            position: absolute;
            bottom: 0;
            border-radius: 5px;
            height: 5px;
            width: 100%;
            display: flex;
            border-radius: 5px;
            overflow: hidden;

            .color-span {
                background-size: contain;
                background-position: center center;
                width: 100%;
            }

            .clor-item {
                height: 100px;
            }
        }
    }
}