.check-box-list {
    display: flex;
    flex-direction: column;
    gap: 8px;


    .title-box {
        display: flex;
        background: #FFF;
        padding: 8px 8px 8px 0px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border: 1px solid rgba(33, 33, 33, 0.08);
        border-bottom-width: 0;
        justify-content: space-between;
        align-items: center;
        color: #1F2937;

        .value {
            font-size: 12px;
            color: #212121;
            text-align: center;
            font-family: Nunito;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .line-spacing-box {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border: 1px solid rgba(33, 33, 33, 0.08);
        border-top-width: 0;
        padding: 8px 8px 8px 12px;

        .slider-box {
            display: flex;
            width: auto;
        }

        .slider-line-spacing {
            .MuiSlider-thumb {
                height: 16px;
                width: 16px;
                &:focus,&:hover,&.Mui-active,&.Mui-focusVisible{
                    box-shadow:0 0 0 4px rgba(58, 133, 137, 0.16)
                }
            }
        }
    }

    .title {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #1F2937
    }

    .entry-check-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        border: 1px solid rgba(33, 33, 33, 0.08);
        background: #FFF;
        padding: 8px 8px 8px 12px;

        .MuiButtonBase-root,
        .MuiCheckbox-root {
            padding: 4px;
        }



        .list-icon-style {
            display: flex;
            gap: 11px;

            .style-icon {
                width: 24px;
                height: 24px;
                border-radius: 4px;
                background: rgba(33, 33, 33, 0.04);
                border: 1px solid rgba(33, 33, 33, 0.12);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                box-sizing: border-box;

                &:hover {
                    border: 1px solid #0c9eff;
                }

                &.active {
                    border: 1px solid #0c9eff;
                }

                .none-style {
                    font-family: Nunito;
                    font-size: 7px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    color: #212121;
                }
            }
        }

        .value {
            color: #212121;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .icon-check{
        width: 18px;
        height: 18px;
        border: 1px solid rgba(33, 33, 33, 0.24);
        border-radius: 4px;
        transform: scale(0.8);
    }
}