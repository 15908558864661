.slider-input{
    .text-border {
        font-size: 12px;
        font-weight: 600;
        color: #6a6a6a;
    }
    .input-count {
        width: 32px;
        height: 20px;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        text-align: center;
    }
    .input-count:focus {
        outline: none !important;
        border: 1px solid #000000;
        border-radius: 4px;
    }
}