.word-scramble-container {
    .ws-container {
        border-radius: 6px;
        border: 1px solid rgba(33, 33, 33, 0.08);
        background: #FFF;
        padding: 8px;
        padding-left: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            color: #1F2937;
            font-family: Nunito;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        user-select: none;
    }
}