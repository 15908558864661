.list-group-activities {
    display: flex;
    flex-direction: column;

    .group-activities {
        display: flex;
        flex-direction: column;

        .header-group {
            display: flex;
            align-items: center;
            padding: 10px 12px;
            position: relative;
            cursor: pointer;
            &:hover {
                background: #f8f8f8;
            }
            .expand-icon {
                display: flex;
                align-items: center;
                position: absolute;
                right: 16px;
            }
            p {
                margin: 0;
                color: #212121;
                font-size: 16px;
                font-weight: 700;
                text-transform: capitalize;
            }
        }

        .list-activities {
            display: flex;
            flex-direction: column;
            padding: 8px 12px;
            gap: 8px;
            // animation: dropDown 0.3s ease-in-out;
            // z-index: -1;
            // @keyframes dropDown {
            //     from {
            //         transform: translateY(-100%);
            //     }
            //     to {
            //         transform: translateY(0);
            //     }
            // }
            .activity {
                display: flex;
                align-items: center;
                padding: 6px;
                gap: 4px;
                height: 32px;
                border-radius: 6px;
                cursor: pointer;
                position: relative;

                p {
                    margin: 0;
                    color: #212121;
                    font-size: 14px;
                    font-weight: 500;
                    text-transform: capitalize;
                }
                .arrow-right {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    right: 0px;
                    visibility: hidden;
                }
                &:hover {
                    background: #f8f8f8;
                    .arrow-right {
                        visibility: visible;
                    }
                }
                &.active {
                    p {
                        color: #3b82f6;
                    }
                    background: rgba(59, 130, 246, 0.08);
                }
            }
        }
    }
}
