// .tool-tip-lib {
//     --distanceTooltip: 10px;
//     --colorToolTip: rgba(97, 97, 97, 0.92);
// }
#common-tooltip {
    .tooltip-customize-root {
        opacity: 1;
        visibility: visible;
    }
}
