.container-tabshapes{
    position: relative;
    height: 100%;
    // padding: 0 24px;
    .list-box-interactive{
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 16px;
        column-gap: 16px;
        padding-top: 8px;

        .box-interactive{
            background: #21212114;
            // border: 1px solid rgba(var(--color),.32);
            border-radius: 8px;
            display: flex;
            align-items: center;
            width: 100%;
            // padding: 8px;
            // padding-top: 16px;
            // padding-bottom: 16px;
            cursor: pointer;
            .box-img{
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 8px 8px 8px 12px;
                .text-answer{
                    position: absolute;
                    color: rgba(33, 33, 33, 0.24);
                    font-size: 10px;
                    font-weight: 500;
                }
            }

            span{
                font-size: 14px;
                font-weight: 600;
                cursor: pointer;
                color: #212121;
            }
        }

    }

    .title{
        color: #212121;
        font-weight: 700;
        font-size: 16px;
        line-height: 21.82px;
        padding-top: 16px;
    }

    .text-note{
        font-size: 14px;
        font-style: italic;
        color: rgba(33, 33, 33, 0.52);
        padding-top: 24px;
        font-weight: 300;
    }
    

    .list-resource-item-shape {
        grid-gap: 12px;
        display: grid;
        gap: 12px;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: 12px;
        padding: 0 24px 0 24px;

        .resource-item {
            .img-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 95%;
                height: 95%;

                img {
                    height: 64px;
                    width: auto;
                    max-width: 100%;
                    object-fit: contain;
                }

                div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 64px;
                    height: 64px;
                }
            }
        }

        .list-item {
            .img-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50px;
                height: 50px;
                padding: 8px;
                border-radius: 8px;

                img {
                    height: 50px;
                    width: 100%;
                    max-width: 100%;
                }
            }

        }
    }

    .list-resource-item-line {
        grid-gap: 16px;
        display: grid;
        gap: 16px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: 0 24px 0 24px;
        z-index: 1;

        .resource-item {
            z-index: 2;

            .img-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 95%;
                height: 95%;

                img {
                    height: 24px;
                    width: 100%;
                }
            }
        }
    }

    .text {
        font-family: "Nunito";
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        padding: 16px 0;
        color: #212121;
    }

    .column {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        height: 45%;
    }

    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .main-left-content {
        // width: 100%;
        height: 100%;

    }
}