.popup-assign-digital {
    z-index: 1300 !important;
    .MuiPaper-root {
        border-radius: 28px;
        width: 591px;
        // display: flex;
        // flex-direction: column;
        @media screen and (max-width: 768px) {
            background-color: transparent;
            box-shadow: unset;
            width: unset;
            border-radius: 32px 32px 0px 0px;

            .close-button {
                color: unset;
                text-align: center;
            }
        }
    }
}
.popup-assign-digital-content {
    @media screen and (max-width: 768px) {
        background-color: #fff;
        border-radius: 32px 32px 0px 0px;
    }
    .header {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: #272728;

        padding: 16px 24px 0 34px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        p {
            flex: 1;
        }

        .close-icon {
            &:hover {
                cursor: pointer;
            }
        }
    }
    .content {
        height: 250px;
        padding-top: 16px;
        background: #f8f8f8;
        display: flex;
        flex-direction: column;
        gap: 12px;
        flex-grow: 1;
        overflow-y: scroll;

        .title-content {
            padding-left: 24px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: #272728;
        }
        .checkbox-container {
            &:hover {
                cursor: pointer;
                background: #ececec;
            }
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 16px 34px;
            transition: all 0.2s ease-in-out;

            img {
                display: flex;
                align-items: center;
            }

            .name {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;

                letter-spacing: 0.02em;

                color: rgba(33, 33, 33, 0.52);
            }
        }
    }
    .footer {
        display: flex;
        justify-content: space-between;
        padding: 16px;

        .new-group {
            display: flex;
            align-items: center;
            gap: 8px;
            &:hover {
                cursor: pointer;
            }

            .icon {
                display: flex;
                align-items: center;
            }
            .label-new-group {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: 0.02em;
                color: rgba(var(--color), 1);
                padding-top: 2px;
                @media screen and (max-width: 768px) {
                    padding-top: 0;
                }
            }
        }
        .save-button {
            &:hover {
                cursor: pointer;
            }
            display: flex;
            align-items: center;
        }
    }
}
