._0xkaeQ {
    pointer-events: auto;
}

.DF_utQ {
    cursor: auto;
    outline: none;
    position: absolute;
}

.Fe_H_Q {
    display: block;
    // min-height: 1px;
    // min-width: 1px;
    overflow: visible;
    // position: absolute;
}

.Fe_H_Q_START {
    display: block;
    min-height: 1px;
    min-width: 1px;
    overflow: visible;
    position: absolute;
}

.Fe_H_Q,
.Fe_H_Q_START,
.V7MmMA {
    height: 100%;
    width: 100%;
}

._682gpw {
    -webkit-touch-callout: none;
    -webkit-user-drag: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.LpqEwQ {
    position: absolute;
}