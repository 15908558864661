.crossword_gid_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    #crossword_grid_table {
        border-collapse: collapse;
        align-self: center;

        .cell_value{
            border: 1px solid #292929;
            background-color: #ffffff;
            box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.18);

            .cell_char {
                position: relative;
                transition: all 0.1s ease;

                .index {
                    z-index: 0;
                    cursor: pointer;
                    position: absolute;
                    top: 0px;
                    left: 2px;
                }
    
                input {
                    z-index: 1;
                    cursor: pointer;
                    border: none;
                    outline: none;
                    width: inherit;
                    height: inherit;
                    padding: 0;
                    margin: 0;
                    font-size: inherit;
                    text-align: center;
                    background-color: transparent;
                    caret-color: transparent;
                }

                .crossword_value {
                    cursor: pointer;
                    width: 100%;
                    height: 100%;
                    position: relative;
                    & > div {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            &.word_selected {
                background-color: #FFF6DB;
                border: 1px solid #000000;
            }

            &.focus {
                background-color: #ffd54d;
            }

            &:hover {
                background-color: rgba(255, 213, 77, 0.8);
            }
        }
    }

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}