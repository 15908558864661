.reset-design-options-modal {
    h2 {
        font-size: 20px;
    }

    .form-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        .select-all-btn {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}