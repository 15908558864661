.more-action-container {
    display: flex;
    left: 0;
    position: absolute;
    top: 0;
    pointer-events: auto;
    height: 50px;
    // z-index: 6;
    .layer {
        background-color: #fff;
        box-shadow: 0 0 0 1px rgba(64, 87, 109, 0.07),
            0 2px 12px rgba(53, 71, 90, 0.2);
        grid-gap: 4px;
        // contain: layout;
        display: grid;
        grid-auto-flow: column;
        // overflow: hidden;
        border-radius: 4px;
        padding: 2px 0px;
    }
    .list-action {
        display: grid;
        align-items: center;
        grid-auto-flow: column;
        gap: 2px;
        cursor: pointer;
        padding: 2px 4px;
        grid-gap: 4px;
        .action {
            display: flex;
            width: 24px;
            height: 24px;
            align-items: center;
            justify-content: center;
            transition: background-color 0.1s linear;

            border-radius: 4px;
            position: relative;
            .tooltip {
                position: absolute;
                z-index: 1;
                visibility: hidden;
                background-color: black;
                width: fit-content;
                color: #fff;
                text-align: center;
                border-radius: 2px;
                padding: 2px 12px;
                transform: translate(10px, -30px);
                margin-bottom: 12px;
                // clear: both;
                // display: inline-block;
                // overflow: hidden;
                // white-space: nowrap;
            }
        }
        .enable {
            background-color: rgba(33, 33, 33, 0.04);
            &:hover {
                background-color: rgba(33, 33, 33, 0.12);
                .tooltip {
                    visibility: visible;
                }
            }
        }
        .disable {
            svg {
                path {
                    fill-opacity: 0.24;
                }
            }
        }
    }

    .text-nb-words {
        font-size: 12px;
        line-height: 30px;
        border-left: 1px solid lightgray;
        padding-left: 10px;
        padding-right: 10px;
    }

    .lock-status {
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
    }
}
.more-option-panel {
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 8px;
    border-radius: 8px;
    background-color: #FFF;

    .option {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;

        .option-icon svg {
            width: 20px;
        }

        .option-name {
            align-self: flex-start;
        }

        cursor: pointer;
        .option-name {
            color: 212121;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.28px;
        }
    }
}