.calendar-container {
    margin-left: auto;
    margin-right: 152px;
    position: relative;
    @media screen and (max-width: 768px) {
        margin: 0;
    }
    .calendar {
        cursor: pointer;
        .calendar-item {
            display: flex;
            gap: 12px;
            align-items: center;
            padding: 14px 16px;
            background: #ffffff;
            box-sizing: border-box;
            border: 1px solid #e5e7eb;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
            border-radius: 8px;
            cursor: pointer;
            @media screen and (max-width: 768px) {
                padding: 8px;
            }
            .img {
                width: 16px;
                height: 16px;
                img {
                    width: 100%;
                    height: 100%;
                }
                &.active {
                    transform: scaleY(-1);
                }
            }

            .current-date {
                min-width: 130px;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 22px;
                color: #6b7280;
                margin: 0;
                @media screen and (max-width: 768px) {
                    font-size: 12px;
                    line-height: 16px;
                    min-width: 100px;
                }
            }
        }
    }

    .dropdown-list {
        display: none;
        position: absolute;
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(33, 33, 33, 0.12);
        border-radius: 8px;
        margin-top: 8px;
        padding: 8px;
        gap: 8px;
        width: 202px;
        z-index: 5;
        cursor: pointer;
        &.active {
            display: block;
        }
        @media screen and (max-width: 768px) {
            width: 156px;
        }

        .list-created-date {
            font-weight: 400;
            font-size: 12px;
            line-height: 26px;
            color: #212121;
            @media screen and (max-width: 768px) {
                font-size: 10px;
                line-height: 18px;
            }

            .created-date-all {
                border-bottom: 1px solid rgba(33, 33, 33, 0.08);
                .date-all {
                    padding: 4px 8px;
                    margin-bottom: 8px;
                    &:hover {
                        background: rgba(33, 33, 33, 0.04);
                        border-radius: 4px;
                    }
                }
            }
            .list-date {
                overflow: scroll;
                width: 208px;
                max-height: 240px;
                @media screen and (max-width: 768px) {
                    width: 162px;
                }
            }
            .date-item {
                padding: 4px 8px;
                margin-top: 8px;
                &:hover {
                    background: rgba(33, 33, 33, 0.04);
                    border-radius: 4px;
                }
            }
            .item-selected {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 8px;
            }

            .selected {
                color: rgba(var(--color), 1);
                display: flex;
                align-items: center;
                padding: 0;
            }
        }
    }
}
.main-left-content {
    .calendar-container {
        margin: unset;
        // margin-left: auto;
        width: fit-content;

        .calendar {
            .calendar-item {
                padding: 8px;
                gap: 4px;

                .current-date {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                    min-width: 100px;
                }
            }
            .dropdown-list {
                width: fit-content;
                .list-created-date {
                    .list-date {
                        width: 150px;
                    }
                }
            }
        }
    }
}
