.popup-download-invite-friend {
    .MuiDialogContent-root {
        padding: 0 0 16px 0 !important;
        @media screen and (min-width: 769px) {
            padding: 12px 32px !important;
        }
    }
    .MuiPaper-root {
        .content {
            overflow: auto;
            @media screen and (max-width: 768px) {
                border-radius: 12px 12px 0px 0px;
            }
        }
        .close-button {
            @media screen and (max-width: 768px) {
                background-color: transparent;
            }
        }
    }
    .header-popup {
        position: relative;
        .back {
            cursor: pointer;
            svg {
                path {
                    width: 7px;
                    height: 11px;
                }
            }
        }
        .close {
            position: absolute;
            top: -4px;
            right: -22px;
            cursor: pointer;
            svg {
                width: 16px;
                height: 16px;
                path {
                    fill-opacity: 1;
                }
            }
        }
    }
    .main-popup-download-invite-friend {
        flex-direction: column;
        display: flex;
        gap: 16px;
        .invite-friend-container {
            flex-direction: column;
            display: flex;
            gap: 16px;
            .invite-content-container {
                // border: 1px solid #e5e7eb;
                justify-content: space-between;
                display: flex;
                border-radius: 6px;
                .link-refer {
                    width: 100%;
                    .text-url {
                        p {
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 1; /* number of lines to show */
                            line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
            .invite-content {
                padding: 0;
                display: flex;
                flex-direction: column;
                border-bottom: none;
                gap: 8px;
                .button-send-refer {
                    padding: 12px;
                    // border-radius: 0 6px 6px 0;
                    border: 1px solid #e5e7eb;
                    // border-left: 1px solid #e5e7eb;
                    align-items: center;
                    .img-icon {
                        width: 16px;
                        height: 16px;
                    }
                    @media screen and (min-width: 1025px) {
                        padding: 14px 12px;
                        height: unset;
                    }
                }
                .email-friend-container {
                    padding: 2.5px;
                }
                .new {
                    width: 100%;
                    // border-width: 0;
                    border-radius: 6px;
                    border: 1px solid #e5e7eb;
                    @media screen and (min-width: 1025px) {
                        padding: 14px 16px;
                    }
                    input {
                        padding: 0;
                        // border-radius: 6px;
                        // border: 1px solid #e5e7eb;
                        width: 100% !important;
                    }
                }
                .text-content-left {
                    text-align: left;
                }
            }
        }
        .form-refer-container {
            .refer-content-table {
                max-height: 350px;
            }
            *::-webkit-scrollbar-thumb {
                background-color: rgba(97, 97, 97, 0.92);
            }
            *::-webkit-scrollbar-track {
                background-color: #fff;
            }
            .text-item-user {
                &.email {
                    text-align: start;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
}
