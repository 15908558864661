.tab-view {
    box-shadow: 0px 4px 9px 0px rgba(225, 246, 255, 0.2);
    height: fit-content;

    p {
        margin: 0;
    }



    &__content {

        &.disable {
            opacity: 0;
            position: absolute;
        }

        &.show {
            &:not(.safari) {
                animation: 0.4s ease 0s 1 normal forwards running showView;
            }

            &.safari {
                opacity: 1;
                height: var(--heightContentView);
            }
        }

        &.hide {
            &:not(.safari) {
                animation: 0.4s ease 0s 1 normal forwards running hideView;
            }

            &.safari {
                height: 0;
                opacity: 0;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
}