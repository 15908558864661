.MuiAlert-action {
    align-items: center !important;
    padding-top: 0;
}

.MuiAlert-icon {
    align-items: center;
}

.alert-go-to-collection {
    max-width: 444px;
    position: fixed;
    bottom: 24px;
    right: 56px;
    @media screen and (max-width: 768px) {
        right: 8px !important;
        max-width: unset !important;
        bottom: 66px !important;
    }
    .MuiAlert-icon {
        display: none;
    }
    .MuiAlert-action {
        position: absolute;
        top: 10px;
        right: 10px;
        margin: 0;
        padding: 0;
        .MuiButtonBase-root {
            padding: 0;
            width: 16px;
        }
        svg {
            path {
                fill: #14b8a6;
            }
        }
    }
    .MuiPaper-root {
        // background-color: #fff;
        background: #f0fdfa;
        box-shadow: 0px 4px 4px 0px #00000040;

        color: #212121;

        // height: 90px;
        padding: 16px;
        font-weight: 400;
        font-size: 14px;
        box-shadow: 0px 4px 10px rgba(33, 33, 33, 0.12);
        line-height: 17px;
        border-radius: 12px;

        .MuiAlert-message {
            padding: 0;
            display: flex;
            align-items: center;
            gap: 16px;
            overflow: hidden;
            padding-right: 40px;
            .image-alert {
                width: 50px;
                height: 63px;
                border: 1px solid #2dd4bf;
                border-radius: 4px;
            }
            a {
                color: #115e59;
                text-decoration: underline;
            }
            div {
                p {
                    margin: 0;
                    color: #115e59;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 19px;
                }
                .see-it-now {
                    padding-top: 10px;
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    text-decoration: none;
                    font-weight: 600;
                    color: #115e59;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 22px;

                    svg {
                        transform: rotate(90deg);
                        path {
                            stroke: #115e59;
                        }
                    }
                }
                .message-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                    .message {
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 22px;
                        color: #115e59;
                    }
                }
                .error {
                    color: #991b1b;
                    a {
                        color: #991b1b;
                    }
                }
            }
            .image.success {
            }
        }
        &.error {
            background: #fef2f2;
            .MuiAlert-message div .message-container .message {
                color: #991b1b;
            }
            .MuiAlert-action {
                svg {
                    path {
                        fill: #991b1b;
                    }
                }
            }
            .image-alert {
                border: 1px solid #991b1b;
            }
        }
    }
}
