.word-bank-scramble-container {
    border-radius: 6px;
    border: 1px solid #000;
    padding: 12px;

    div {
        text-align: var(--align-wb);
        text-decoration: var(--underline-wb);
        font-family: var(--font-family-wb) !important;
        width: unset !important;
        white-space: no-wrap;
    }
}