.tab-create-pixel-template {
    .info div {
        text-align: right;
    }

    h2 {
        font-size: 18px;
        display: flex;
        justify-content: space-between;
    }

    .background-color {
        display: flex;
        align-items: flex-end;

        .color-block {
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }

    .btns {
        display: flex;
        justify-content: space-around;
    }

    .demo-image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;

        button {
            margin-top: 20px;
        }
    }
}

.pixel-image-modal {
    img {
        width: 100%;
    }
}

.pixel-template-colors {
    margin-bottom: 20px;

    & > div {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div {
            display: flex;
            align-items: center;
        }

        .color-block {
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-right: 5px;
        }

        .color-code {
            font-size: 12px;
        }

        .form-control {
            width: 90px;
            font-size: 12px;
        }
    }
}