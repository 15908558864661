.template-tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;

    &>span {
        border: 1px solid lightgray;
        border-radius: 20px;
        padding: 5px;
        font-size: 13px;
        position: relative;
        margin: 5px;

        .delete-btn {
            position: absolute;
            background-color: red;
            top: -8px;
            right: -10px;
            display: inline-block;
            color: white;
            padding: 0;
            height: 10px;
            line-height: 10px;
            width: 10px;
            border-radius: 100%;
            font-size: 10px;
            text-align: center;
            cursor: pointer;
        }

        &:hover .delete-btn {
            display: inline-block;
        }
    }

    button {
        margin-left: 5px;
    }
}