.button-scramble {
    cursor: pointer;
    background: rgba(var(--color), 1);

    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 12px;
    margin-top: 12px;

    .text-scramble {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 21px;
        color: #ffffff;
        font-weight: bold;
    }
}