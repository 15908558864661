.list-tag-container {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    margin-top: 20px;
    .list-tag {
        display: flex;
        flex-flow: row nowrap;
        width: fit-content;
        overflow-x: auto;
        overflow-y: hidden;
        z-index: 0;
        align-items: center;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        .tag-item {
            background: #212121;
            flex: none;
            border-radius: 6px;
            width: fit-content;
            text-align: center;
            padding: 4px 8px;
            margin-right: 8px;
            cursor: pointer;
            // display: flex;
            .tag-name {
                font-weight: 600;
                font-size: 12px;
                line-height: 24px;
                letter-spacing: 0.04em;
                color: #ffffff;
                &::first-letter {
                    text-transform: uppercase;
                }
            }
        }
    }
    .arrow-container {
        // display: none;
        .circle-arrow {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            font-size: 0;
            line-height: 0;
            transform: translate(0, -50%);
            cursor: pointer;
            background: rgba(255, 255, 255, 0.8);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
            display: flex !important;
            align-items: center;
            justify-self: center;
            img {
                margin: auto;
                z-index: 2;
            }
        }
        .test-arrow {
            position: absolute;
            width: auto;
            height: 32px;
            min-width: 60px;
        }
    }
    .next-arrow {
        .circle-arrow {
            right: 10px;
        }
        .test-arrow {
            background: linear-gradient(
                270deg,
                #ffffff 0%,
                #ffffff 15.62%,
                rgba(255, 255, 255, 0) 100%
            );
            right: -8px;
        }
    }
    .prev-arrow {
        .circle-arrow {
            left: 4px;
        }
        .test-arrow {
            background: linear-gradient(
                270deg,
                #ffffff 0%,
                #ffffff 15.62%,
                rgba(255, 255, 255, 0) 100%
            );
            transform: matrix(-1, 0, 0, 1, 0, 0);
            left: -8px;
        }
    }
}
