.popup-create {
    .MuiPaper-root {
        width: 591px;
        display: flex;
        flex-direction: column;
        border-radius: 28px;

        @media screen and (max-width: 768px) {
            box-shadow: unset;
            background-color: transparent;
            border-radius: 32px 32px 0px 0px;
            width: unset;

            .close-button {
                text-align: center;
                color: unset;
            }
            .popup-create {
                background-color: #fff;
                border-radius: 32px 32px 0px 0px;
            }
        }
        .header {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            text-align: center;
            color: #272728;

            padding: 16px 24px 0 34px;
            display: flex;
            align-items: center;
            justify-content: space-around;

            p {
                flex: 1;
            }

            .close-icon {
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .content {
            height: 237px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding-top: 16px;
            padding-left: 24px;
            padding-right: 24px;
            background-color: #fff;
            .field {
                padding-top: 36px;
                &:first-child {
                    padding-top: 0px;
                }
                .label {
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: rgba(33, 33, 33, 0.52);
                }
                .MuiInput-root {
                    width: 100%;

                    &:hover:not(.Mui-disabled):before {
                        border-bottom: 2px solid #2183df;
                    }

                    &::after {
                        border-bottom: 2px solid rgba(33, 33, 33, 0.5);
                    }
                }
            }
        }
        .buttons {
            display: flex;
            justify-content: space-between;
            gap: 8px;
            padding: 24px;
            // padding-left: 24px;
            // padding-right: 16px;
            // padding-top: 52px;
            .button {
                width: 50%;
                text-align: center;
                padding: 12px 0;
                border-radius: 12px;
                border: 1px solid #e7e7e8;
                cursor: pointer;

                &.save {
                    background: #ff2442;
                    color: #fff;
                }
            }
        }
    }
}
